import React from 'react';
import { EmployerBenefits } from '../index';
import styled from 'styled-components';
import { Button, Spinner, Icon } from '../../atoms';
import { useEmployerBenefits, useApproveBenefits } from '../../hooks';

const EmployerBenefitsWithApproval = () => {
    const { areBenefitsLoading, benefits, saveBenefits, employer } = useEmployerBenefits();
    const { canApproveBenefits, isApprovalLoading, isApprovalSuccessful, approveThenRefreshBenefits } = useApproveBenefits();

    return (
        <div>
            <EmployerBenefits key="employerBenefits" isLoading={areBenefitsLoading} data={benefits} saveBenefits={saveBenefits} employer={employer} />
            <ButtonContainer>
                <Button onClick={approveThenRefreshBenefits} disabled={!canApproveBenefits} theme="secondary">
                    {isApprovalLoading && <Spinner />}
                    {isApprovalSuccessful && <Icon icon="Check" />}
                    <span>Approve Selected</span>
                </Button>
            </ButtonContainer>
        </div>
    );
};

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    span {
        margin: 0 10px;
    }
`;

export default EmployerBenefitsWithApproval;
