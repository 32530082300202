import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { usePathForRegion } from '../../hooks';
import { propTypes } from 'react-json-pretty';

const Td = ({ to, onClick, primary, children, style, isExternal, ...rest }) => {
    const isInteractive = !!to || !!onClick;
    const pathWithRegion = usePathForRegion(to || '/');

    if (!to) {
        return (
            <Cell {...rest}>
                <Wrapper primary={primary} style={style} isInteractive={isInteractive} onClick={onClick}>
                    <Inner>{children}</Inner>
                </Wrapper>
            </Cell>
        );
    }

    const target = isExternal ? to : pathWithRegion;
    return (
        <Cell>
            <WrapperWithLink to={target} primary={primary} isInteractive={isInteractive} {...rest}>
                <Inner>{children}</Inner>
            </WrapperWithLink>
        </Cell>
    );
};

const defaultStyles = css`
    font-size: 0.75rem;
    font-weight: 500;
    color: #999999;
`;

const primaryStyles = css`
    font-size: 0.875rem;
    font-weight: 600;
    color: #000000;
    min-width: 8rem;
`;

const cellStyles = css`
    display: flex;
    align-items: center;
    min-height: 4rem;
    padding: 0.5rem 1rem;
    line-height: 1.4;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    position: relative;

    @media screen and (-ms-high-contrast: none) {
        /* Targets ie11 */
        min-height: auto;
    }

    ${props => props.isInteractive && 'cursor: pointer'};
    ${props => (props.primary ? primaryStyles : defaultStyles)};

    &:focus {
        outline: none;

        span {
            border-color: #ffc845;
        }
    }
`;

const Cell = styled.td`
    padding: 0;
    border-bottom: 1px solid #f1f1f1;
    height: 4rem;
`;

const Wrapper = styled.div`
    ${cellStyles};
`;

const WrapperWithLink = styled(Link)`
    ${cellStyles};
`;

const Inner = styled.span`
    border-bottom: 0.0625rem solid transparent;
`;

Td.defaultProps = {
    isExternal: false
};

Td.propTypes = {
    to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            pathname: PropTypes.string,
            search: PropTypes.string,
            state: PropTypes.object
        })
    ]),
    children: PropTypes.node,
    isExternal: PropTypes.bool,
    onClick: PropTypes.func,
    primary: PropTypes.any,
    style: propTypes.any
};

export default Td;
