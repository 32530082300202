import React from 'react';
import { Icon } from '../../atoms';
import styled from 'styled-components';
import { Errors } from '../../types/types';

interface ErrorProps {
    errors?: Errors;
    forwardRef: any;
}

const ErrorView: React.FC<ErrorProps> = ({ errors, forwardRef }) => {
    return (
        <LiveRegion aria-live="assertive" aria-relevant="additions removals">
            {!!errors && !!errors.message && (
                <ErrorMsg innerRef={forwardRef} tabIndex={0}>
                    <Icon icon="ExclamationCircle" />
                    <p>{errors.message}</p>
                </ErrorMsg>
            )}
        </LiveRegion>
    );
};

const LiveRegion = styled.div.attrs({
    role: 'region'
})`
    width: 100%;
    height: 2rem;
`;

const ErrorMsg = styled.div`
    display: flex;
    align-items: center;
    padding: 0.875rem 1rem;
    margin: 10px 0 10px 0;
    background-color: #333333;
    color: #ffffff;
    position: absolute;
    top: -10px;
    left: 280px;
    width: 100%;

    @media (min-width: 48rem) {
        padding: 0.875rem 2rem;
    }

    &:focus {
        outline: none;
        p {
            border-bottom: 1px solid #ffffff;
        }
    }

    svg {
        margin-right: 0.5rem;
        font-size: 1.5rem;
    }

    p {
        margin: 0;
    }
`;

export default ErrorView;
