import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { NavItem } from '../../atoms';
import { resetUl } from '../../styles';

const Navigation = ({ menu, region }) => (
    <Nav>
        <Menu>
            {menu.map((item, a) => {
                const navGroupIsActive = window.location.pathname.includes(item.href);

                return (
                    <li key={a}>
                        {item.href.startsWith('http') && <NavLink href={item.href}>{item.label}</NavLink>}
                        {!item.href.startsWith('http') && <NavItem to={`/${region}${item.href}`}>{item.label}</NavItem>}
                        {item.children && navGroupIsActive && (
                            <Menu>
                                {item.children.map((child, b) => (
                                    <li key={b}>
                                        <NavItem to={`/${region}${child.href}`} isChild={true}>
                                            {child.label}
                                        </NavItem>
                                    </li>
                                ))}
                            </Menu>
                        )}
                    </li>
                );
            })}
        </Menu>
    </Nav>
);

const Nav = styled.nav`
    font-family: 'Montserrat', sans-serif;
    flex-grow: 1;
`;

const Menu = styled.ul`
    ${resetUl};
    display: flex;
    flex-direction: column;
`;

const activeStyle = css`
    background-color: #e9e9e9;
    font-weight: 600;

    &::after {
        content: '';
        width: 0.25rem;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: #e31c79;
    }
`;

const navItemStyles = css`
    position: relative;
    font-size: 0.875rem;
    font-weight: 500;
    text-decoration: none;
    color: #000000;
    padding: 1rem;
    display: flex;

    &[aria-current] {
        ${activeStyle};
    }

    &:focus {
        background: #e1e1e1;
        outline: none;
    }
`;

const NavLink = styled.a`
    ${navItemStyles};
    text-transform: uppercase;
`;

Navigation.propTypes = {
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            href: PropTypes.string
        })
    ).isRequired,
    region: PropTypes.string.isRequired
};

export default Navigation;
