import 'url-search-params-polyfill';
import React, { Fragment, Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { history as historyPropTypes } from 'history-prop-types';
import { Form } from 'informed';
import { throttle } from 'throttle-debounce';
import moment from 'moment';
import queryString from 'query-string';

import { Button, DateRangePicker, ErrorMessage, Label as FormLabel, SelectInput, TextInput } from '../../atoms';
import { ApiClientContext } from '../../services';

class SearchField extends Component {
    state = { employers: [] };

    static contextType = ApiClientContext;

    handleEmployerSearch = throttle(1000, value => {
        this.context.apiClient.getAllEmployers(1, `&search=${value}`).then(({ data }) => {
            if (data && data.results) {
                this.setState({ employers: data.results.map(e => ({ id: e.slug, name: e.name })) });
            }
        });
    });

    handleReset = () => {
        const { location } = this.props;
        window.location = location.pathname;
    };

    handleExpired = () => {
        const { location } = this.props;
        const isShowingExpired = location.search && location.search.indexOf('expired=true') !== -1;
        let queryStr = queryString.parse(location.search);
        if (isShowingExpired) {
            queryStr.expired = undefined;
            window.location = location.pathname + '?' + queryString.stringify(queryStr);
        } else {
            queryStr.expired = true;
            window.location = location.pathname + '?' + queryString.stringify(queryStr);
        }
    };

    handleSubmit = values => {
        const { location } = this.props;
        let queryStr = '';
        for (let value in values) {
            if (values[value]) {
                if (value === 'dateRange') {
                    const startDate = values[value][0];
                    const endDate = values[value][1];
                    const startDateFormatted = moment(startDate).format('YYYY-MM-DD');
                    const endDateFormatted = moment(endDate).format('YYYY-MM-DD');
                    if (startDate && endDate) {
                        queryStr += (queryStr === '' ? '?' : '&') + `startDate=${startDateFormatted}&endDate=${endDateFormatted}`;
                    }
                } else {
                    queryStr += (queryStr === '' ? '?' : '&') + `${value}=${values[value]}`;
                }
            }
        }
        window.location = location.pathname + queryStr;
    };

    validateDateRange = values => {
        if (values && values.length > 0) {
            if (values.length === 2 && values[0] === null && values[1] === null) {
                // No error because no date range has been selected.
                return;
            }

            if (!moment.isMoment(values[0]) || !values[0].isValid()) {
                return 'Please enter a valid start date';
            }

            if (values.length < 2 || !moment.isMoment(values[1]) || !values[1].isValid()) {
                return 'Please enter a valid end date';
            }
        }
    };

    render() {
        const { employers } = this.state;
        const { location, includeKeywordSearch, keywordLabelAs, includeEmployerSearch, includeDateRange, includeSecondarySearch, SecondaryLabelAs, ...rest } = this.props;
        const params = new URLSearchParams(location.search);
        const initialDateRange = [];
        let includeSecondarySearchOrDateRange = !!(includeSecondarySearch || includeDateRange);

        if (includeDateRange) {
            const startDate = params.get('startDate');
            if (startDate && moment(startDate).isValid()) {
                initialDateRange.push(moment(startDate));
            }
            const endDate = params.get('endDate');
            if (endDate && moment(endDate).isValid()) {
                initialDateRange.push(moment(endDate));
            }
        }
        const isShowingExpired = location.search && location.search.indexOf('expired=true') !== -1;

        return (
            <StyledForm onSubmit={this.handleSubmit} {...rest}>
                {({ formState }) => (
                    <Fragment>
                        <FormRow>
                            {includeKeywordSearch && (
                                <Label label={keywordLabelAs} hasValue={!!formState.values.search} markAsOptional={false}>
                                    <TextInput name="search" initialValue={params.get('search')} />
                                </Label>
                            )}
                            {includeEmployerSearch && (
                                <Label label="Employer" hasValue markAsOptional={false}>
                                    <SelectInput
                                        name="employer"
                                        label="Employer"
                                        initialValue={params.get('employer')}
                                        options={employers}
                                        onSearch={this.handleEmployerSearch}
                                        searchPlaceholder="Search for an employer"
                                    />
                                </Label>
                            )}
                            <ButtonGroup>
                                <SearchBtn aria-label="Search" />
                                {!!params.toString() && <ClearBtn onClick={this.handleReset} aria-label="Clear fields" />}
                            </ButtonGroup>
                        </FormRow>
                        {includeSecondarySearchOrDateRange && (
                            <FormRow>
                                {includeSecondarySearch && (
                                    <Label label={SecondaryLabelAs} hasValue={!!formState.values.secondarySearch} markAsOptional={false}>
                                        <TextInput name="secondarySearch" initialValue={params.get('secondarySearch')} />
                                    </Label>
                                )}
                                {includeDateRange && (
                                    <Label label="Date range" hasValue={!!formState.values.dateRange} markAsOptional={false} fixed>
                                        <DateRangePicker
                                            field="dateRange"
                                            validate={this.validateDateRange}
                                            isOutsideRange={day => {
                                                return day.isAfter(moment(), 'day');
                                            }}
                                            initialVisibleMonth={() => moment().subtract(1, 'month')}
                                            initialValue={initialDateRange}
                                        />
                                        {formState.errors.dateRange && <ErrorMessage errors={[formState.errors.dateRange]} />}
                                    </Label>
                                )}
                            </FormRow>
                        )}
                        <FormRow>
                            <Button theme="link" onClick={this.handleExpired} type="button">
                                {isShowingExpired ? 'View active jobs' : 'View expired jobs'}
                            </Button>
                        </FormRow>
                    </Fragment>
                )}
            </StyledForm>
        );
    }
}

const placeholderStyles = css`
    text-transform: unset;
    opacity: 0.375;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    transform: translateY(2rem);
    transition: 0.3s ease;
`;

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 30rem) {
        align-items: flex-end;
    }
`;

const FormRow = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    align-items: flex-end;
`;

const Label = styled(FormLabel)`
    align-items: stretch;
    flex-direction: column;

    @media (min-width: 30rem) {
        margin-right: 1rem;
        max-width: 20rem;
    }

    &:not(:focus-within) span {
        ${props => !props.hasValue && !props.fixed && placeholderStyles};
    }

    > span {
        width: auto !important;
        min-height: auto;
        padding: 0;
        transition: 0.3s ease;
        font-size: 0.75rem;
        line-height: 1.375rem;
        font-family: 'Montserrat', sans-serif;
    }

    > input {
        border-top: none;
        border-left: none;
        border-right: none;
        padding: 0;
    }
`;

const ButtonGroup = styled.div`
    display: flex;

    button {
        @media (max-width: 30rem) {
            margin: 0.5rem 0 1rem;
        }

        @media (min-width: 30rem) {
            margin-bottom: 0.25rem;
        }
    }
`;

const ClearBtn = styled(Button).attrs({
    type: 'button',
    filled: 'invisible',
    icon: 'Times'
})`
    margin-left: 0.25rem;
`;

const SearchBtn = styled(Button).attrs({
    type: 'submit',
    filled: 'border',
    icon: 'Search'
})`
    flex-grow: 1;
    align-self: flex-end;
`;

SearchField.defaultProps = {
    includeKeywordSearch: true,
    keywordLabelAs: 'Search',
    includeEmployerSearch: false
};

SearchField.propTypes = {
    history: PropTypes.shape(historyPropTypes).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string
    }).isRequired,
    includeKeywordSearch: PropTypes.bool,
    keywordLabelAs: PropTypes.string,
    includeEmployerSearch: PropTypes.bool,
    includeDateRange: PropTypes.bool,
    includeSecondarySearch: PropTypes.bool,
    SecondaryLabelAs: PropTypes.string
};

export default SearchField;
