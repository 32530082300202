import React from 'react';
import { useScriptSnippet, useScriptSrc } from '../../hooks/useScript';
import styled, { css } from 'styled-components';
import LogoImage from './logo.svg';

const SelfServiceContainer = ({ children, headerContent, contentWidth }) => {
    useScriptSrc('//js.hs-scripts.com/1888283.js');

    const hotjarTracking = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:1726467,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

    useScriptSnippet(hotjarTracking);

    const year = new Date().getFullYear();
    return (
        <Container>
            <Header>
                <Link>
                    <Logo src={LogoImage} />
                </Link>
                <HeaderContent>{headerContent}</HeaderContent>
            </Header>
            <Body>
                <Content contentWidth={contentWidth}>{children}</Content>
            </Body>
            <Footer>
                <Copyright>©{year} WORK180</Copyright>
            </Footer>
        </Container>
    );
};

const HEADER_HEIGHT = '3.75rem';
const FOOTER_HEIGHT = '6rem';

const Container = styled.div`
    position: relative;
    min-height: 100vh;
`;

const Body = styled.div`
    padding-top: ${HEADER_HEIGHT};
    padding-bottom: ${FOOTER_HEIGHT};
`;

const Footer = styled.div`
    position: absolute;
    height: ${FOOTER_HEIGHT};
    bottom: 0;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
`;

const Header = styled.div`
    position: fixed;
    height: ${HEADER_HEIGHT};
    top: 0;
    left: 0;
    width: 100%;
    background-color: blue;
    padding: 0 1rem;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    border-bottom: 0.0625rem solid #ededed;
    box-sizing: border-box;
`;

const centeredStyles = css`
    max-width: 58rem;
    box-sizing: border-box;
    margin: 0px auto;
    padding: 2rem 0px;

    @media (max-width: 60rem) {
        margin-left: 1rem;
        margin-right: 1rem;
    }
`;

const Content = styled.div`
    ${centeredStyles}
    max-width: ${props => (props.contentWidth ? props.contentWidth : '58rem')}
`;

const Link = styled.a`
    line-height: 1;
    align-self: center;
    margin-right: 0.5rem;
    position: absolute;

    &:focus {
        outline: 0.0625rem solid #000000;
        outline-offset: 0.1875rem;
    }
`;

const Logo = styled.img`
    /* IE needs the width attr because SVG */
    width: 8.75rem;
    height: 2.25rem;
`;

const HeaderContent = styled.div`
    ${centeredStyles}
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

const Copyright = styled.div`
    color: white;
    margin: 20px;
    font-size: 0.875rem;
    font-family: 'Open Sans', sans-serif;
`;
export default SelfServiceContainer;
