import React, { Fragment, useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Form } from 'informed';
import { Tbody } from '../../molecules';
import { ApiClientContext } from '../../services';
import { Button, Date, Field, Label, Td, Th, Icon } from '../../atoms';
import CvCaptureApplication from './CvCaptureApplication';

const ApplicationsTable = ({ results, handleSort, currentSortConfig, ...rest }) => {
    const [expanded, setExpanded] = useState(null);
    const { apiClient } = useContext(ApiClientContext);

    const toggleRow = applicationId => {
        setExpanded(expanded => (expanded === applicationId ? null : applicationId));
    };

    const downloadFile = (fileId, fileName) => {
        apiClient.getFile(fileId, fileName);
    };

    return (
        <Container {...rest}>
            <Table>
                <thead>
                    <tr>
                        <Th>Employer</Th>
                        <SortingHeader
                            headerText="Applicant"
                            apiSortingKey="firstName"
                            isDescendingInitially={false}
                            currentSortConfig={currentSortConfig}
                            handleSort={handleSort}
                            headerStyle={{ minWidth: '12rem' }}
                        />
                        <SortingHeader
                            headerText="Job"
                            apiSortingKey="jobTitle"
                            isDescendingInitially={false}
                            currentSortConfig={currentSortConfig}
                            handleSort={handleSort}
                        />
                        <SortingHeader
                            headerText="Applied"
                            apiSortingKey="added"
                            isDescendingInitially={true}
                            currentSortConfig={currentSortConfig}
                            handleSort={handleSort}
                            headerStyle={{ minWidth: '5rem' }}
                        />
                        <Th />
                    </tr>
                </thead>
                <Tbody>
                    {results.map(application => {
                        return (
                            <Fragment key={application.id}>
                                <Row expanded={application.id === expanded}>
                                    <Td onClick={() => toggleRow(application.id)}>
                                        {!!application.employer && !!application.employer.logo && (
                                            <Logo>
                                                <img src={application.employer.logo.split(' ').join('%20')} alt={`${application.employer.name} logo`} />
                                            </Logo>
                                        )}
                                    </Td>
                                    <NameCell primary="true" onClick={() => toggleRow(application.id)} expanded={application.id === expanded}>
                                        {`${application.firstName} ${application.lastName}`}
                                    </NameCell>
                                    <Td onClick={() => toggleRow(application.id)}>{!!application.job && application.job.title}</Td>
                                    <Td onClick={() => toggleRow(application.id)}>
                                        <Date date={application.added} />
                                    </Td>
                                    <BtnCell>
                                        <Button
                                            onClick={() => toggleRow(application.id)}
                                            filled="invisible"
                                            icon={application.id === expanded ? 'ChevronUp' : 'ChevronDown'}
                                            aria-label="Expand"
                                        />
                                    </BtnCell>
                                </Row>
                                <tr>
                                    <td colSpan="5">
                                        <div role="region" aria-live="assertive" aria-relevant="additions removals">
                                            {application.id === expanded && (
                                                <Fragment>
                                                    <Form allowEmptyStrings>
                                                        <Details>
                                                            <Label label="Email" theme="extended">
                                                                {application.email}
                                                            </Label>
                                                            {application.phone && (
                                                                <Label label="Phone" theme="extended">
                                                                    {application.phone}
                                                                </Label>
                                                            )}
                                                            {application.rightToWork && (
                                                                <Label label="Right to Work" theme="extended">
                                                                    {application.rightToWork ? 'Yes' : 'No'}
                                                                </Label>
                                                            )}
                                                            <Label label="Cover Letter" theme="extended">
                                                                {application.coverLetterFileName ? (
                                                                    <Button onClick={() => downloadFile(application.coverLetterFileId, application.coverLetterFileName)}>
                                                                        Download
                                                                    </Button>
                                                                ) : (
                                                                    'Not supplied'
                                                                )}
                                                            </Label>
                                                            <Label label="CV" theme="extended">
                                                                {application.curriculumVitaeFileName ? (
                                                                    <Button
                                                                        onClick={() =>
                                                                            downloadFile(application.curriculumVitaeFileId, application.curriculumVitaeFileName)
                                                                        }
                                                                    >
                                                                        Download
                                                                    </Button>
                                                                ) : (
                                                                    'Not supplied'
                                                                )}
                                                            </Label>
                                                            <Field
                                                                label="Notes"
                                                                name="notes"
                                                                initialRenderType="value"
                                                                isTogglingEnabled={true}
                                                                theme="extended"
                                                                rows={2}
                                                            />
                                                        </Details>
                                                    </Form>
                                                    {application.isCVCapture && <CvCaptureApplication id={application.id} />}
                                                </Fragment>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            </Fragment>
                        );
                    })}
                </Tbody>
            </Table>
        </Container>
    );
};

const SortingHeader = ({ headerText, apiSortingKey, isDescendingInitially, currentSortConfig: { sortByKey, sortIsDescending }, handleSort, headerStyle, className }) => {
    return (
        <Th style={headerStyle} className={className}>
            <HeaderButton
                onClick={() => {
                    handleSort({ key: apiSortingKey, isDescendingInitially });
                }}
            >
                {headerText}
                {sortByKey === apiSortingKey && <Icon icon={sortIsDescending ? 'ChevronUp' : 'ChevronDown'} style={{ marginLeft: '2px' }} />}
            </HeaderButton>
        </Th>
    );
};

const Container = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const Row = styled.tr`
    td {
        ${// eslint-disable-next-line
        props =>
            props.expanded &&
            `
            border-bottom: none;
            > * {
                color: #000000;
            }
        `};
    }
`;

const Logo = styled.div`
    width: 4rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 4rem;
        max-height: 3rem;
    }
`;

const nameCellExpandedStyles = css`
    position: relative;
    &:before {
        content: '';
        position: absolute;
        height: 0.125rem;
        left: 0;
        right: 0;
        bottom: -0.125rem;
        background: #e1e1e1;
    }
`;

const NameCell = styled(Td)`
    ${props => props.expanded && nameCellExpandedStyles};
`;

const BtnCell = styled(Td)`
    width: 4rem;

    > * {
        padding: 0 1rem;
    }
`;

const Details = styled.div`
    padding: 1.5rem 1rem 1.5rem 7.5rem;
    border-bottom: 1px solid #f1f1f1;
`;

const HeaderButton = styled.button`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-transform: inherit;

    &:hover {
        color: #e31c79;
    }
`;

ApplicationsTable.propTypes = {
    results: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            email: PropTypes.string,
            coverLetterFileName: PropTypes.string,
            coverLetterFileId: PropTypes.string,
            curriculumVitaeFileName: PropTypes.string,
            curriculumVitaeFileId: PropTypes.string,
            added: PropTypes.date,
            job: PropTypes.shape({
                title: PropTypes.string
            }),
            employer: PropTypes.shape({
                name: PropTypes.string,
                logo: PropTypes.string
            })
        })
    ).isRequired,
    handleSort: PropTypes.func,
    currentSortConfig: PropTypes.any
};

export default ApplicationsTable;
