import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Tbody = ({ children, ...rest }) => {
    return <TableBody {...rest}>{children}</TableBody>;
};

const TableBody = styled.tbody`
    tr:hover td > * {
        color: #000000;
    }
`;

Tbody.propTypes = {
    children: PropTypes.node.isRequired
};

export default Tbody;
