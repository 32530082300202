import { injectGlobal } from 'styled-components';

// Override for react-dates styles
injectGlobal`
    .CalendarDay__selected_span {
        background: #FFCF5D;
        color: #FFFFFF;
        border: 1px solid #FFC845;
    }

    .CalendarDay__selected {
        background: #FFBB1A;
        border: #FFBB1A;
        color: #FFFFFF;
    }

    .CalendarDay__selected_span:hover,
    .CalendarDay__selected:hover {
        background: #FFBB1A;
        border: #FFBB1A;
        color: #FFFFFF;
    }

    .CalendarDay__hovered_span:hover,
    .CalendarDay__hovered_span {
        background: #FFE9B5;
        border: #FFCF5D;
        color: #000000;
    }

    .DateInput_input__focused {
        border-bottom: 2px solid #FFC845;
    }

    input.DateInput_input {
        padding: 10px 11px 8px !important;
        box-sizing: border-box;
        font-family: 'Open Sans',sans-serif;
        font-size: 0.875rem;
        line-height: 20px;
        font-weight: 500;
        color: #000000;
    }

    input.DateInput_input::placeholder {
        opacity: 0.375;
        font-size: 1rem;
        color: #000000;
    }

    .DateRangePickerInput_clearDates_svg {
        width: 18px;
    }
`;
