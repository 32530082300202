import React, { useState } from 'react';
import styled from 'styled-components';

const TabList = ({ children }) => <StyledTabList>{children}</StyledTabList>;

function useTabList(list, initialTab) {
    let initialIndex = 0;
    if (initialTab) {
        list.forEach((item, itemIndex) => {
            if (item.key === initialTab) {
                initialIndex = itemIndex;
            }
        });
    }
    const [selectedTabIndex, setSelectedTabIndex] = useState(initialIndex);
    const [tabs, setTabs] = useState(list);

    return {
        selectedTabIndex,
        setSelectedTabIndex,
        tabs,
        setTabs
    };
}

const StyledTabList = styled.ul`
    padding: 16px 0;
`;

TabList.useTabList = useTabList;
export default TabList;
