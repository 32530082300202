import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Icon, Spinner } from '../../atoms';
import { triggerClickOnEnter } from '../../helpers';

const FileUpload = ({ value, errors, name, accept, handleFileUpload }) => {
    const fileInput = useRef(null);
    const [isUploading, setIsUploading] = useState(false);

    const handleFileChosen = file => {
        if (!file) return;
        setIsUploading(true);
        handleFileUpload(file).then(() => setIsUploading(false));
    };

    return (
        <>
            <label>
                <input
                    type="file"
                    ref={fileInput}
                    style={{ display: 'none' }}
                    name={name}
                    accept={accept}
                    onChange={() => handleFileChosen(fileInput.current.files[0])}
                />
                <Upload tabIndex="0" onKeyDown={e => triggerClickOnEnter(e)}>
                    Choose a file
                    {isUploading && <Spinner style={{ marginLeft: '0.5rem' }} />}
                </Upload>
                {value}
            </label>
            {errors && errors.length > 0 && (
                <ErrorMessage>
                    <Icon icon="ExclamationCircle" style={{ marginRight: '0.5rem' }} />
                    {errors}
                </ErrorMessage>
            )}
        </>
    );
};

const Upload = styled.span`
    border: 0.125rem solid #e31c79;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: #000000;
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    vertical-align: middle;
    transition: 0.15s ease-in;
    box-sizing: border-box;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    height: 2rem;
    cursor: pointer;

    &:hover,
    &:focus {
        background-color: #e31c79;
        color: #ffffff;
        transition: 0.15s ease-in;
        outline: none;
    }
`;

const ErrorMessage = styled.span`
    font-size: 0.75rem;
    font-weight: 500;
    margin: 5px 0;
    display: block;
    width: 100%;
    text-align: right;
    color: #eb0000;
`;

FileUpload.propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    accept: PropTypes.string,
    handleFileUpload: PropTypes.func
};

export default FileUpload;
