import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { RadioGroup, Radio } from 'informed';

class RadioInputGroup extends Component {
    render() {
        const { name, options, initialValue, ...rest } = this.props;

        return (
            <Container>
                <RadioGroup initialValue={initialValue} field={name} {...rest}>
                    {options.map(option => {
                        return (
                            <Label key={option.id} disabled={rest.disabled} htmlFor={`${name}-${option.id}`}>
                                <HiddenInput value={option.id} disabled={rest.disabled} id={`${name}-${option.id}`} />
                                <StyledInput disabled={rest.disabled} />
                                {option.name}
                            </Label>
                        );
                    })}
                </RadioGroup>
            </Container>
        );
    }
}

const Container = styled.div`
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
`;

const Label = styled.label`
    font-family: 'Open Sans';
    height: 2.5rem;
    font-size: 0.875rem;
    display: flex;
    align-items: start;
    margin-right: 1rem;
    position: relative;
    color: ${props => (props.disabled ? '#bcbcbc' : '#000000')};

    input[type='radio'] {
        &:focus + div::before {
            content: '';
        }

        &:checked + div::after {
            content: '';
        }
    }
`;

const StyledInput = styled.div`
    width: 1.25rem;
    height: 1.25rem;
    border: 0.0625rem solid #e1e1e1;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    margin-right: 0.5rem;

    &::before {
        position: absolute;
        top: -0.0625rem;
        left: -0.0625rem;
        bottom: -0.0625rem;
        right: -0.0625rem;
        border: 0.125rem solid #0890a8;
        border-radius: 50%;
    }

    &::after {
        position: absolute;
        top: 0.1875rem;
        left: 0.1875rem;
        bottom: 0.1875rem;
        right: 0.1875rem;
        background-color: ${props => (props.disabled ? '#e1e1e1' : '#0890A8')};
        border-radius: 50%;
    }
`;

const HiddenInput = styled(Radio)`
    position: absolute;
    left: -1rem;
    opacity: 0;
`;

RadioInputGroup.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.string
        })
    ).isRequired,
    initialValue: PropTypes.string
};

export default RadioInputGroup;
