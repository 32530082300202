import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { resetButton, resetUl } from '../../styles';
import { REGION_PARAM_WHITELIST, REGIONS } from '../../constants';
import { getRegion } from '../../helpers';
import { useAuthorization } from '../../auth';

const RegionMenu = props => {
    const [regionList, setRegionList] = useState([]);
    const { allowedRegions } = useAuthorization();

    useEffect(() => {
        const regionObjectList = [];
        allowedRegions.forEach(allowedRegion => {
            regionObjectList.push(REGIONS.find(x => x.id === allowedRegion));
        });
        setRegionList(regionObjectList);
    }, [allowedRegions]);

    const handleClick = id => {
        const { history, computedMatch } = props;
        const currentPathWithRegionRegex = computedMatch.path;
        let newPathWithNewRegion = currentPathWithRegionRegex.replace(REGION_PARAM_WHITELIST, id);
        const {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            params: { region, ...paramsNoRegion }
        } = computedMatch;
        Object.entries(paramsNoRegion).forEach(([key, value]) => (newPathWithNewRegion = newPathWithNewRegion.replace(`:${key}`, value)));
        history.push(newPathWithNewRegion);
        window.location.reload();
    };

    return (
        <Nav>
            <Menu>
                {regionList.map((region, i) => (
                    <li key={i}>
                        <RegionToggle isSelected={region.id === getRegion()} onClick={() => handleClick(region.id)}>
                            {region.id}
                        </RegionToggle>
                    </li>
                ))}
            </Menu>
        </Nav>
    );
};

const Nav = styled.nav`
    display: flex;
`;

const Menu = styled.ul`
    ${resetUl};
    display: flex;
    align-items: center;

    li {
        line-height: 1;
    }
`;

const regionStyles = css`
    text-transform: uppercase;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.75rem;
    line-height: 1;
    padding: 0.5rem;
    display: flex;
    border-bottom: 0.125rem solid transparent;
`;

const RegionToggle = styled.button`
    ${resetButton};
    ${regionStyles};

    ${props => props.isSelected && 'border-color: #ffffff'};

    &:focus {
        color: #ffc845;
        border-color: #ffc845;
        outline: none;
    }
`;

RegionMenu.propTypes = {
    region: PropTypes.string.isRequired,
    history: PropTypes.object,
    computedMatch: PropTypes.object,
    isClient: PropTypes.bool
};

export default RegionMenu;
