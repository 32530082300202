/* eslint-disable indent,no-console */
import React, { useContext, useEffect, useState } from 'react';

import { Loading } from '../../atoms';
import { ErrorView } from '../../components';
import { ApiClientContext } from '../../services';

const withData = WrappedComponent => ({ domain }) => {
    const WrappedViewEditAdd = props => {
        const [data, setData] = useState({});
        const [errors, setErrors] = useState({});
        const [files, setFiles] = useState({});
        const [isExisting, setIsExisting] = useState(false);
        const [isLoading, setIsLoading] = useState(true);
        const [isSubmitting, setIsSubmitting] = useState(false);
        const { apiClient } = useContext(ApiClientContext);

        const api = {
            get: {
                Employer: apiClient.getEmployer,
                EmployerPage: apiClient.getEmployerPage,
                EmployerPageContentBlock: apiClient.getEmployerPageContentBlock,
                EmployerFeature: apiClient.getEmployerFeature,
                Job: apiClient.getJob,
                User: apiClient.getUser,
                ContentPage: apiClient.getContentPage,
                EventPage: apiClient.getEventPage,
                PartnerPage: apiClient.getPartnerPage,
                Widget: apiClient.getWidget
            },
            add: {
                Employer: apiClient.addEmployer,
                EmployerPage: apiClient.addEmployerPage,
                EmployerPageContentBlock: apiClient.addEmployerPageContentBlock,
                Job: apiClient.addJob,
                User: apiClient.addUser,
                ContentPage: apiClient.addContentPage,
                EventPage: apiClient.addEventPage,
                PartnerPage: apiClient.addPartnerPage,
                Widget: apiClient.addWidget
            },
            update: {
                Employer: apiClient.updateEmployer,
                EmployerPage: apiClient.updateEmployerPage,
                EmployerPageContentBlock: apiClient.updateEmployerPageContentBlock,
                EmployerFeature: apiClient.updateEmployerFeature,
                Job: apiClient.updateJob,
                User: apiClient.updateUser,
                ContentPage: apiClient.updateContentPage,
                EventPage: apiClient.updateEventPage,
                PartnerPage: apiClient.updatePartnerPage,
                Widget: apiClient.updateWidget
            },
            del: {
                EmployerPageContentBlock: apiClient.deleteEmployerPageContentBlock,
                Job: apiClient.deleteJob,
                User: apiClient.deleteUser,
                Widget: apiClient.deleteWidget
            }
        };

        const errorMsg = React.createRef();

        const countErrors = errors => {
            let count = 0;
            for (let e in errors) {
                count += errors[e].length;
            }
            return count;
        };

        const createErrorMessage = (errors, thing, action) => {
            const count = countErrors(errors);
            let errorsPlural = count > 1 ? 'errors are' : 'error is';
            return `${count} ${errorsPlural} preventing this ${thing} from being ${action}.`;
        };

        const handleRemoveFileFromState = (imageKey, stateKey, updatedData) => {
            /* eslint-disable */
            let { [imageKey]: deletedFile, ...restFiles } = files;
            let data =
                stateKey === 'heroImageUploadInfo'
                    ? { ...updatedData, heroImage: null }
                    : stateKey === 'aboutImageUploadInfo'
                    ? { ...updatedData, aboutImage: null }
                    : stateKey === 'jobHeroImageUploadInfo'
                    ? { ...updatedData, jobHeroImage: null }
                    : updatedData;

            setFiles(restFiles);
            setData(data);
        };

        const handleFileUpload = (key, file, userName = undefined) => {
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onload = (() => {
                    return e => {
                        const binaryData = e.target.result;
                        const base64String = window.btoa(binaryData);
                        const updatedFiles = {
                            ...files,
                            [key]: {
                                name: file.name,
                                type: file.type,
                                b64: base64String
                            }
                        };
                        const dataToUpdate = userName !== undefined ? { ...data, updatedBy: userName, ...updatedFiles } : { ...data, ...updatedFiles };
                        setFiles(updatedFiles);
                        if (isExisting) {
                            handleUpdate(dataToUpdate, true)
                                .then(resolve)
                                .catch(reject);
                        } else {
                            resolve();
                        }
                    };
                })();
                reader.readAsBinaryString(file);
            });
        };

        const handleGet = () => {
            const { id } = props.computedMatch.params;
            api.get[domain](id)
                .then(({ data }) => {
                    setData(data);
                    setIsLoading(false);
                })
                .catch(error => {
                    if (error.response) {
                        console.error(error.response);
                    } else if (error.request) {
                        console.error(error.request);
                    } else {
                        console.error(error.message);
                    }
                    setErrors({
                        message: `We're unable to load this ${domain.toLowerCase()} at the moment. Please refresh the page to try again.`
                    });
                    setIsLoading(false);
                });
        };

        const generateRedirectUrl = (domain, data) => {
            const { region } = props;
            let route = `${domain.toLowerCase()}s`;
            if (domain === 'ContentPage') {
                route = 'content/page';
            } else if (domain === 'EventPage') {
                route = 'content/event';
            } else if (domain === 'PartnerPage') {
                route = 'content/partner';
            }

            return `/${region}/${route}/${data.id}`;
        };

        const handleAdd = data => {
            const { history, region } = props;
            data = {
                ...data,
                region,
                ...files
            };

            setIsSubmitting(true);

            api.add[domain](data)
                .then(({ data }) => {
                    const redirectUrl = generateRedirectUrl(domain, data);
                    history.push(redirectUrl);
                })
                .catch(error => {
                    if (error.response) {
                        const data = error.response.data.errors || error.response.data;

                        const errors =
                            typeof data === 'string'
                                ? { message: data }
                                : {
                                      message: createErrorMessage(data, domain, 'created'),
                                      ...data
                                  };

                        setErrors(errors);
                        errorMsg.current.focus();
                        console.error(data);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log(error.message);
                    }
                    setIsSubmitting(false);
                });
        };

        const resolveDataForUpdate = newData => {
            setErrors({});
            if (domain === 'Job') {
                const { disciplines, locations, workTypes, levelOfExperience, ...rest } = newData;
                return {
                    ...data,
                    ...rest,
                    disciplines: disciplines ? disciplines : data.disciplines.map(d => d.id),
                    locations: newData.hasOwnProperty('locations') ? locations : data.locations.map(item => item.sourceText),
                    workTypes: workTypes ? workTypes : data.workTypes.map(w => w.id),
                    levelOfExperience: levelOfExperience ? levelOfExperience : null
                };
            } else {
                return {
                    ...data,
                    ...newData,
                    ...files
                };
            }
        };

        const handleUpdate = (newOrChangedData, v2 = false) => {
            return new Promise((resolve, reject) => {
                let updatedData = v2 ? newOrChangedData : resolveDataForUpdate(newOrChangedData);
                api.update[domain](updatedData.id, updatedData)
                    .then(({ data }) => {
                        setData(data);
                        return resolve();
                    })
                    .catch(error => {
                        const data = error.response.data.errors || error.response.data;
                        const errors =
                            typeof data === 'string'
                                ? { message: data }
                                : {
                                      message: createErrorMessage(data, domain, 'updated'),
                                      ...data
                                  };

                        setErrors(errors);
                        errorMsg.current.focus();
                        console.error(data);
                        return reject();
                    });
            });
        };

        const handleDelete = () => {
            const { history, location, region } = props;
            const canDelete = ['Job', 'User'];
            const redirectLocation = location.state && location.state.redirectLocation;

            if (!canDelete.includes(domain)) {
                return;
            }

            if (!window.confirm(`Are you sure you want to delete this ${domain.toLowerCase()}? This action cannot be undone.`)) {
                return;
            }

            api.del[domain](data.id)
                .then(() => {
                    const redirectUrl = redirectLocation ? redirectLocation : `/${region}/${domain.toLowerCase()}s/all`;
                    history.push(redirectUrl);
                })
                .catch(error => {
                    if (error.response) {
                        const data = error.response.data.errors || error.response.data;

                        const errors =
                            typeof data === 'string'
                                ? { message: data }
                                : {
                                      message: createErrorMessage(data, domain, 'deleted'),
                                      ...data
                                  };

                        setErrors(errors);
                        errorMsg.current.focus();
                        console.error(data);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log(error.message);
                    }
                });
        };

        const handleOnLoad = () => {
            const path = props.computedMatch.path.split('/');
            const isExisting = path[path.length - 1] === ':id';
            if (isExisting) {
                setIsExisting(true);
                handleGet();
            } else {
                setIsLoading(false);
            }
        };

        useEffect(() => {
            handleOnLoad();
        }, []);

        if (isLoading) {
            return <Loading />;
        }

        document.title = `${domain} | WORK180 Portal`;

        return (
            <>
                <ErrorView errors={errors} forwardRef={errorMsg} />
                <WrappedComponent
                    handleFileUpload={handleFileUpload}
                    handleRemoveFileFromState={handleRemoveFileFromState}
                    handleSubmit={isExisting ? handleUpdate : handleAdd}
                    handleDelete={handleDelete}
                    errors={errors}
                    data={data}
                    files={files}
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    isExisting={isExisting}
                    {...props}
                />
            </>
        );
    };

    return WrappedViewEditAdd;
};

export default withData;
