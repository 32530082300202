import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { asField } from 'informed';

import { Icon } from '../../atoms';
import { toTitleCase } from '../../helpers';

const TagInputField = ({ disabled, errors, fieldState, fieldApi, onBlur, delimiter }) => {
    const [tagValue, setTagValue] = useState('');
    const containerRef = useRef();
    const tagRef = useRef();

    // Keep a ref to the tagValue to allow callbacks to be memoized
    useEffect(() => {
        tagRef.current = tagValue;
    });

    // Add a tag if it doesn't exist and set the field to 'touched'
    const addTag = useCallback(
        tag => {
            const { value } = fieldState;
            const { setValue } = fieldApi;

            if (tag && tag.trim()) {
                let currentTags = !value ? [] : value;
                const isExisting = currentTags.includes(tag);

                if (!isExisting) {
                    currentTags = [...currentTags, tag];
                    setValue(currentTags);
                }
                setTagValue('');
                return;
            }
            fieldApi.setTouched(true);
        },
        [fieldState, fieldApi]
    );

    // Control handler for updating the tagValue.
    // When a specified delimeter is encountered, a tag is created
    const handleChange = e => {
        const tag = e.target.value;
        if (tag && tag !== delimiter && tag.endsWith(delimiter)) {
            addTag(tag.slice(0, delimiter.length * -1));
        } else {
            setTagValue(tag);
        }
    };

    // Memoized blur handler. Tags are created on blur
    const handleBlur = useCallback(
        e => {
            const currentTag = tagRef.current;
            if (currentTag) {
                addTag(currentTag);
            }
            fieldApi.setTouched(true);
            onBlur && onBlur(e);
        },
        [addTag, fieldApi, onBlur]
    );

    const handleRemove = (e, tag) => {
        const { value } = fieldState;
        const { setValue } = fieldApi;
        const tagIndex = value.indexOf(tag);
        if (tagIndex > -1) {
            setValue([...value.slice(0, tagIndex), ...value.slice(tagIndex + 1)]);
        }

        // Prevent clicking on the tag from causing an outside click
        e.stopPropagation();
    };

    const handleKeyPress = useCallback(
        e => {
            if (e.key === 'Enter') {
                addTag(tagRef.current);
                e.preventDefault();
                return false;
            }
        },
        [addTag]
    );

    return (
        <Container tabIndex={-1} innerRef={containerRef} onBlur={handleBlur}>
            {fieldState &&
                fieldState.value &&
                fieldState.value.map(tag => (
                    <Tag key={tag} onClick={e => handleRemove(e, tag)}>
                        {tag}
                        <Icon icon="Times" style={{ marginLeft: '8px' }} />
                    </Tag>
                ))}
            <input type="text" value={tagValue} onChange={handleChange} onKeyPress={handleKeyPress} disabled={disabled} aria-invalid={!!errors && !!errors.length} />
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    font-size: 0.875rem;
    line-height: 1.125rem;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    width: 100%;
    padding: 0 6px;
    &:focus-within {
        border-color: #ffc845;
        outline: none;
    }

    input {
        flex-grow: 1;
        height: calc(1.375rem + 1.125rem - 2px);
        border: none;
        font-size: 0.875rem;
        outline: none;
        padding: 0;
    }
`;

const Tag = styled.span`
    display: flex;
    align-items: center;
    background: #ffc845;
    color: #484848;
    padding: 4px;
    border-radius: 8px;
    margin: 6px 4px 6px 0;

    &:hover {
        cursor: pointer;
        color: #000000;
    }
`;

TagInputField.propTypes = {
    field: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
    delimiter: PropTypes.string
};

TagInputField.defaultProps = {
    delimiter: ','
};

const AsTagInputField = asField(TagInputField);

/**
 * TagInput
 * Wrapper component to pass in field name and validation properties to the actual input component.
 * It's necessary to wrap custom inputs like this in order for the Informed library to function correctly.
 */
const TagInput = props => {
    const { label, name, required } = props;

    const validate = (value = '') => {
        const fieldName = label || toTitleCase(name);

        if (required && (!value || value.length === 0)) {
            return `The ${fieldName} field is required.`;
        }

        if (props.validate) {
            return !value ? undefined : props.validate(value);
        }
    };

    const validationProps = (required || props.validate) && {
        validate: validate,
        validateOnChange: true,
        validateOnMount: true
    };

    return <AsTagInputField {...props} {...validationProps} />;
};

TagInput.propTypes = TagInputField.propTypes;

export default TagInput;
