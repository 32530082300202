import React, { Fragment, useContext } from 'react';
import { Panel, Tbody } from '../../molecules';
import { Label, Th } from '../../atoms';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useData } from '../../hooks';
import { ApiClientContext } from '../../services';

const EmployerJobAutomationInformation = props => {
    const { slug, apiToken } = props;
    const { apiClient } = useContext(ApiClientContext);
    const response = useData(apiClient.getEmployerIntegrationInfo, slug, null);

    if (response.data === null) return <div> </div>;

    const data = response.data;

    const hasJobAutomation = data.jobAutomation !== false;
    const integrationType = data.jobAutomationType;
    const isScraper = integrationType === 'Scraper';
    const isFeedIntegration = integrationType === 'Feed';
    const isSmartScraper = data.scraperType === 'Smart Scraper';
    const hasJobs = Array.isArray(data.jobSourceCountInfo) && data.jobSourceCountInfo.length;

    return (
        <Panel title="Job Automation Information">
            <Label label="Job Automation">{data.jobAutomation.toString()}</Label>
            {hasJobAutomation && (
                <Fragment>
                    <Label label="Automation Type">{integrationType}</Label>
                    {isFeedIntegration && (
                        <Fragment>
                            <Label label="Provider">{data.provider}</Label>
                            <Label label="Feed Url">
                                <span style={{ overflow: 'auto' }}>{data.feedUrl}</span>
                            </Label>
                        </Fragment>
                    )}
                    {isScraper && (
                        <Fragment>
                            <Label label="Scraper Type">{data.scraperType}</Label>
                            {!isSmartScraper && <Label label="Provider">{data.provider}</Label>}
                        </Fragment>
                    )}
                </Fragment>
            )}
            <Label label="Job source information">
                {hasJobs && (
                    <Table>
                        <thead>
                            <tr>
                                <Th>Source</Th>
                                <Th>Job Count</Th>
                            </tr>
                        </thead>
                        <Tbody>
                            {data.jobSourceCountInfo.map((jobSource, i) => {
                                return (
                                    <tr key={i}>
                                        <Cell>{jobSource.jobSource}</Cell>
                                        <Cell>{jobSource.jobCount}</Cell>
                                    </tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                )}
                {!hasJobs && (
                    <div>
                        <em> - No active jobs</em>
                    </div>
                )}
            </Label>
            <Label label="API Token">{apiToken}</Label>
        </Panel>
    );
};

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const Cell = styled.td`
    padding: 0;
    border-bottom: 1px solid #f1f1f1;
    height: 4rem;
    font-size: 0.75rem;
    font-weight: 500;
    align-items: center;
    min-height: 4rem;
    padding: 0.5rem 1rem;
    line-height: 1.4;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    position: relative;
`;

EmployerJobAutomationInformation.propTypes = {
    slug: PropTypes.string.isRequired,
    data: PropTypes.object,
    apiToken: PropTypes.string
};

EmployerJobAutomationInformation.defaultProps = {
    data: {}
};

export default EmployerJobAutomationInformation;
