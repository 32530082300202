import React from 'react';
import styled from 'styled-components';
import { Tbody } from '../../molecules';
import { Button, Td, Th } from '../../atoms';

const JobAutomationTable = ({ results, ...rest }) => {
    return (
        <Container {...rest}>
            <Table>
                <thead>
                    <tr>
                        <Th>EmployerId</Th>
                        <Th>Name of Provider</Th>
                        <Th>Status</Th>
                        <Th>Source</Th>
                        <Th />
                    </tr>
                </thead>
                <Tbody>
                    {results.map(jobAutomation => {
                        const jobAutomationUrl = `/job-automations/${jobAutomation.id}`;
                        return (
                            <tr key={jobAutomation.id}>
                                <Td to={jobAutomationUrl} primary="true">
                                    {jobAutomation.id}
                                </Td>
                                <Td to={jobAutomationUrl}>{jobAutomation.provider}</Td>
                                <Td to={jobAutomationUrl}>{jobAutomation.isDisabled === false ? 'Active' : 'Inactive'}</Td>
                                <Td to={jobAutomationUrl}>{'Database'}</Td>
                                <BtnCell>
                                    <Button to={jobAutomationUrl} size="mini" filled="border">
                                        Edit
                                    </Button>
                                </BtnCell>
                            </tr>
                        );
                    })}
                </Tbody>
            </Table>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const BtnCell = styled(Td)`
    width: 5rem;

    > * {
        padding: 0 1rem;
    }
`;

export default JobAutomationTable;
