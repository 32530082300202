import React from 'react';
import styled from 'styled-components';

function Placeholder() {
    return (
        <StyledPlaceholder>
            <AnimatedBackground />
        </StyledPlaceholder>
    );
}

const StyledPlaceholder = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #f1f1f1;
`;

const AnimatedBackground = styled.div`
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #bfbfbf;
    background: linear-gradient(to right, #f1f1f1 10%, #dddddd 18%, #f1f1f1 33%);
    background-size: 800px 104px;
    height: 100%;
    position: relative;

    @keyframes placeHolderShimmer {
        0% {
            background-position: 100% 0;
        }
        100% {
            background-position: -120% 0;
        }
    }
`;

export default Placeholder;
