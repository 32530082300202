import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { asField } from 'informed';

import { toTitleCase, triggerClickOnEnter } from '../../helpers';

/**
 * Combobox input component
 * This component should be used when there are too many options for a basic select input.
 * When text is typed into the input, the handleChange callback executed with the input value.
 * Normally, the handleChange callback will make an api call to populate the options based on the input value.
 *
 * The input value is stored in local state until:
 * 1) An option is clicked from the list
 * 2) An exact match is typed.
 * When either of these actions occur, the value is saved to the form field
 *
 * == EXAMPLE USAGE (Using the Informed library for form management) ==
 * The component can be configured for basic or complex usage.
 * Basic: Use when the value displayed in the input element is the same as the value saved to the form
 *   const handleSearch = value => {
 *     // Somehow retrieve the options based on the value
 *     // options: [{ id: '1', slug: 'slug-text'}, { id: '2', slug: 'another-slug }]
 *     setState({ options })
 *   }
 *
 *   <Combobox
 *     setValue={formApi.setValue}
 *     valueKey="slug"
 *     handleChange={handleSearch}
 *     data={options}
 *   />
 *
 * Complex: Use when the value displayed in the input element is different to the value saved in the form
 *   <Combobox
 *       setValue={formApi.setValue}
         parseValue={value => (value ? value.name : '')}
 *       handleChange={handleSearch}
 *       data={options}
 *     />
 */

class ComboboxField extends Component {
    constructor(props) {
        super(props);
        const { parseOption, parseValue, fieldState, valueKey } = props;
        const state = { inputValue: fieldState.value, showOptions: false };

        if (parseValue) {
            state.inputValue = parseValue(fieldState.value);
        }

        if (process.env.REACT_APP_BUILD_ENVIRONMENT !== 'production' && valueKey && parseOption) {
            console.warn('<Combobox>: You can use either valueKey or parseOption, but not both');
        }

        this.state = state;
    }

    handleSelection = value => {
        const { fieldApi, onBlur, valueKey, parseOption } = this.props;
        const fieldValue = valueKey ? value[valueKey] : parseOption(value);
        fieldApi.setValue(fieldValue);
        if (onBlur) {
            onBlur();
        }
        this.setState({ showOptions: false });
    };

    handleChange = e => {
        const { data, fieldApi, fieldState, valueKey, handleChange, parseOption } = this.props;
        const value = e.target.value;
        let showOptions = true;
        handleChange(value);

        // If the user types an exact match, set the field
        if (data.length > 0 && value === data[0].name) {
            const fieldValue = valueKey ? data[0][valueKey] : parseOption(data[0]);
            fieldApi.setValue(fieldValue);
            showOptions = false;
        } else if (fieldState.value) {
            // Otherwise, clear the field
            fieldApi.setValue('');
        }

        this.setState({ inputValue: value, showOptions });
    };

    componentDidUpdate(prevProps) {
        const { fieldState, parseValue } = this.props;
        // Keep the inputValue state in sync when a user clicks on an option in the dropdown
        if (fieldState.value && prevProps.fieldState.value !== fieldState.value && fieldState.value !== this.state.inputValue) {
            this.setState({ inputValue: parseValue ? parseValue(fieldState.value) : fieldState.value });
        }
    }

    render() {
        const { data, optionKey } = this.props;
        const { inputValue, showOptions } = this.state;

        return (
            <Fragment>
                <ComboboxWrapper aria-haspopup="listbox" aria-controls="listbox" aria-expanded={true}>
                    <Input value={inputValue} onChange={this.handleChange} aria-autocomplete="list" aria-controls="listbox" autoComplete="off" />
                    {!!data.length && showOptions && (
                        <Listbox>
                            {data.map((suggestion, i) => {
                                return (
                                    <Li key={i} onClick={() => this.handleSelection(suggestion)} onKeyDown={e => triggerClickOnEnter(e)} aria-selected={false}>
                                        {suggestion[optionKey]}
                                    </Li>
                                );
                            })}
                        </Listbox>
                    )}
                </ComboboxWrapper>
            </Fragment>
        );
    }
}

const AsComboboxField = asField(ComboboxField);

const Combobox = ({ name, label, required, ...props }) => {
    const validate = value => {
        const fieldName = label || toTitleCase(name);
        const hasValue = !!value;
        return hasValue ? undefined : `The ${fieldName} field is required.`;
    };

    const validationProps = required && {
        validate: validate,
        validateOnChange: true,
        validateOnMount: true
    };

    return <AsComboboxField field={name} {...validationProps} {...props} />;
};

const ComboboxWrapper = styled.div.attrs({
    role: 'combobox'
})`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
`;

const Listbox = styled.ul.attrs({
    role: 'listbox'
})`
    position: absolute;
    top: 100%;
    width: 100%;
    margin: 0;
    padding: 0.5rem 0;
    list-style-type: none;
    background-color: #ffffff;
    border-left: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    box-sizing: border-box;
    z-index: 1;
`;

const Li = styled.li.attrs({
    role: 'option',
    tabIndex: '0'
})`
    padding: 0.5rem 1rem;

    &:hover,
    &:focus {
        outline: none;
        background-color: #ffc845;
    }
`;

const Input = styled.input`
    font-family: 'Open Sans';
    padding: 0.6875rem 1rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    width: 100%;

    height: ${props => `calc(1.375rem + ${1.125 * (props.rows ? props.rows : 1)}rem)`};
    ${props => props.errors && props.errors.length && 'border-color: #000000'};

    &:focus {
        border-color: #ffc845;
        outline: none;
    }
`;

Combobox.propTypes = {
    name: PropTypes.string.isRequired,
    initialValue: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.array, PropTypes.object]))),
    parseValue: PropTypes.func,
    parseOption: PropTypes.func,
    valueKey: PropTypes.string,
    optionKey: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool
};

Combobox.defaultProps = {
    parseOption: value => value,
    optionKey: 'name'
};

export default Combobox;
