import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SyntaxHighlighter from 'react-syntax-highlighter/prism';
import { okaidia } from 'react-syntax-highlighter/styles/prism';

import { Button, Icon } from '../../atoms';

class WidgetCodeSample extends Component {
    state = {
        isCopied: false
    };

    handleClick = () => {
        this.input.select();
        document.execCommand('copy');
        this.setState({ isCopied: true });
    };

    render() {
        const { isCopied } = this.state;
        const { domain, region } = this.props;
        const codeString = `<div id="work180-jobs" style="width: 100%"></div>\n<script>\n(function(w,d,s,o,f,js,fjs){w.Work180_Widget=o;w[o]=w[o]||function(){(w[o].q=w[o].q||[]).push(arguments)};js=d.createElement(s),fjs=d.getElementsByTagName(s)[0];js.id=o;js.src=f;js.async=1;fjs.parentNode.insertBefore(js,fjs)}(window,document,'script','widget','https://feeds.work180.co/widget.js'));\nwidget({'parentId':'work180-jobs','domain':'${domain}','region':'${region}'});\n</script>`;

        return (
            <Container>
                <CopyToClipboard type="button" onClick={() => this.handleClick()}>
                    {isCopied ? `Copied` : <Icon icon="Copy" />}
                </CopyToClipboard>
                <SyntaxHighlighter language="javascript" style={okaidia}>
                    {codeString}
                </SyntaxHighlighter>
                <HiddenInput
                    innerRef={x => {
                        this.input = x;
                    }}
                    defaultValue={codeString}
                />
            </Container>
        );
    }
}

const Container = styled.div`
    position: relative;
`;

const CopyToClipboard = styled(Button)`
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    width: auto;

    svg {
        font-size: 1.25rem;
    }
`;

const HiddenInput = styled.textarea`
    opacity: 0;
    position: absolute;
    left: -9999px;
`;

WidgetCodeSample.propTypes = {
    domain: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired
};

export default WidgetCodeSample;
