import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Text, TextArea } from 'informed';

import { toTitleCase } from '../../helpers';

class TextInput extends Component {
    validate = (value = '') => {
        const { label, name, validate, required } = this.props;
        const fieldName = label || toTitleCase(name);

        if (required && (!value || !value.trim())) {
            return `The ${fieldName} field is required.`;
        }

        if (validate) {
            return !value ? undefined : validate(value);
        }
    };

    render() {
        const { name, initialValue, rows, errors, required, validate, ...rest } = this.props;
        const mainProps = {
            field: name,
            initialValue,
            rows,
            errors
        };
        const validationProps = (required || validate) && {
            validate: this.validate,
            validateOnChange: true,
            validateOnMount: true
        };

        if (rows === 1) {
            return <SingleRowInput {...mainProps} aria-invalid={!!errors && !!errors.length} {...validationProps} {...rest} />;
        }

        return <MultiRowInput {...mainProps} aria-invalid={!!errors && !!errors.length} {...validationProps} {...rest} />;
    }
}

const inputStyles = css`
    font-family: 'Open Sans';
    padding: 0.6875rem 1rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    width: 100%;
    color: ${props => (props.disabled ? '#bcbcbc' : '#000000')};
    background-color: ${props => (props.disabled ? '#F1F1F1' : '#FFFFFF')};
    cursor: ${props => (props.disabled ? 'default' : 'auto')}
    height: ${props => `calc(1.375rem + ${1.125 * props.rows}rem)`};
    ${props => props.errors && props.errors.length && 'border-color: #000000'};

    &:focus {
        border-color: #ffc845;
        outline: none;
    }
`;

const SingleRowInput = styled(Text)`
    ${inputStyles};
`;

const MultiRowInput = styled(TextArea)`
    ${inputStyles};
`;

TextInput.defaultProps = {
    rows: 1
};

TextInput.propTypes = {
    /** used to create the form state */
    name: PropTypes.string.isRequired,
    /** used to create the height for textarea input */
    rows: PropTypes.number,
    /** used to style the input as invalid */
    errors: PropTypes.arrayOf(PropTypes.string),
    /** is this a required input? */
    required: PropTypes.bool,

    label: PropTypes.string,
    validate: PropTypes.func,
    initialValue: PropTypes.any
};

export default TextInput;
