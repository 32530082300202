import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    width: ${props => (props.isSelfService ? '200px' : '260px')};
    display: flex;
    font-size: 14px;
`;

export const statusStyles = css`
    width: 60px;
    text-align: center;
`;

export const updatedStyles = css`
    width: 100px;
    text-align: center;
    padding: 0 5px;
`;

export const LastUpdated = styled.div.attrs({
    role: 'cell'
})`
    ${updatedStyles}
    font-size: 10px;
`;

export const StatusContainer = styled.div.attrs({
    role: 'cell'
})`
    ${statusStyles}
    display: flex;
    font-size: 10px;
`;

export const Approve = styled.div`
    width: 80px;
    text-align: center;
`;
