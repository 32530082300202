import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

class DragAndDrop extends Component {
    state = { isDragging: false };

    handleDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ isDragging: false });
    };

    handleDrop = e => {
        // const { setValue } = this.props;
        e.preventDefault();
        e.stopPropagation();
        // setValue('logo', e.dataTransfer.files[0]);
        this.setState({ isDragging: false });
    };

    componentDidMount() {
        ['dragenter', 'dragover'].forEach(eventName => {
            document.addEventListener(eventName, e => {
                e.preventDefault();
                e.stopPropagation();
                this.setState({ isDragging: true });
            });
        });
    }

    render() {
        const { isDragging } = this.state;

        return (
            <Wrapper isDragging={isDragging} onDragLeave={this.handleDragLeave} onDrop={this.handleDrop}>
                <DragText>Drag image here</DragText>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    padding: 3rem;
    background-color: #f1f1f1;
    opacity: ${props => (props.isDragging ? 1 : 0)};
    pointer-events: ${props => (props.isDragging ? 'auto' : 'none')};
`;

const DragText = styled.p`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.125rem dashed;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
`;

DragAndDrop.propTypes = {
    setValue: PropTypes.func
};

export default DragAndDrop;
