import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Button, Field, Loading, SuccessMessage, Spinner } from '../../atoms';
import { Form, useFormState } from 'informed';
import { useData } from '../../hooks';
import { ApiClientContext } from '../../services';
import { Errors } from '../../types/types';
import { useHistory } from 'react-router';

interface FeatureProps {
    employerId: string;
    userName: string;
    setErrors?: Dispatch<SetStateAction<Errors | undefined>>;
    errors?: Errors;
}

export type EmployerFeatureModel = {
    featureName: string;
    enabled: boolean;
};

export type EmployerFeatureModelList = {
    updatedBy: string;
    employerFeatureModels: EmployerFeatureModel[];
};

type FeatureSubmitButtonProps = {
    disabled: boolean;
    handleSubmit: () => void;
    isApiLoading: boolean;
    isSaved: boolean;
};

const EmployerFeatures: React.FC<FeatureProps> = ({ employerId, userName, setErrors, errors, ...rest }) => {
    const [employerFeatures, setEmployerFeatures] = useState<EmployerFeatureModel[]>([]);

    const { apiClient } = useContext(ApiClientContext);
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const { data: initialValue, isLoading, error } = useData(apiClient.getEmployerFeatures, employerId, [], () => employerId !== undefined);
    const formState = useFormState<EmployerFeatureModel[]>();
    const history = useHistory();

    useEffect(() => {
        if (employerFeatures.length === 0) {
            setEmployerFeatures(initialValue);
            setErrors && setErrors(error as Errors);
        }
    }, [initialValue, setEmployerFeatures, setErrors, error, employerFeatures.length]);

    function setFormValue(name: string, value: boolean) {
        setEmployerFeatures([{ featureName: name, enabled: value }]);
        setIsSaved(false);
    }

    const updateFeatures = (employerFeatureModels: EmployerFeatureModel[], updateFunctionAfterSave: () => void) => {
        const body: EmployerFeatureModelList = {
            updatedBy: userName,
            employerFeatureModels: employerFeatureModels
        };

        useData.apiCall(apiClient.updateEmployerFeatures, updateFunctionAfterSave, setErrors, setIsApiLoading)(employerId, body);
    };

    const handleSubmitFeatures = (employerFeatures: EmployerFeatureModel[] | undefined) => {
        if (employerFeatures && employerFeatures?.length !== 0) {
            const updateFunctionAfterSave = () => {
                setIsSaved(true);
                setEmployerFeatures(employerFeatures ?? []);
                history.go(0); //HACK: reload employer page, to open feature dependent sections
            };
            updateFeatures(employerFeatures, updateFunctionAfterSave);
        }
    };

    const handleSubmit = () => handleSubmitFeatures(employerFeatures);

    const dataHandler = (featureName: string): boolean => {
        const feature = employerFeatures.find((feature: EmployerFeatureModel) => feature.featureName === featureName);

        if (feature) {
            return feature.enabled;
        }
        return false;
    };

    if (isLoading || isApiLoading || !employerFeatures) {
        return <Loading />;
    }
    return (
        <Form onSubmit={handleSubmit}>
            <Field
                label="Trial CV Capture Opt-in"
                name="trial-cv-capture"
                initialValue={dataHandler('trial-cv-capture')}
                fieldType="checkbox"
                extraDetail="Allow Job seekers to apply by Work180"
                onValueChange={(newValue: boolean) => setFormValue('trial-cv-capture', newValue)}
            />
            <SubmitButton disabled={formState.invalid} handleSubmit={handleSubmit} isApiLoading={isApiLoading} isSaved={isSaved} />
        </Form>
    );
};

const SubmitButton: React.FC<FeatureSubmitButtonProps> = ({ disabled, handleSubmit, isApiLoading, isSaved }) => {
    const text = { button: 'Add Features', successMessage: 'New Features Updated' };

    return (
        <>
            <Button type="submit" disabled={disabled} theme="success" style={{ marginTop: '1rem' }} handleSubmit={handleSubmit}>
                {text.button} {isApiLoading && <Spinner />}
            </Button>
            {isSaved && <SuccessMessage message={text.successMessage} />}
        </>
    );
};

export default EmployerFeatures;
