import { Dispatch, useEffect, useState } from 'react';

interface OpenEndedFunction {
    (...args: any[]): void;
}

interface Response {
    data: object;
}

interface ApiError extends Error {
    response: { data: object | string; statusText: string };
    request: object;
}

interface ApiCallFunction {
    (
        action: (...args: any[]) => Promise<Response>,
        setData?: OpenEndedFunction,
        setError?: OpenEndedFunction,
        setLoading?: OpenEndedFunction,
        startCall?: OpenEndedFunction
    ): Function;
}

interface UseDataFunction {
    (apiAction: (...args: any[]) => Promise<Response>, args?: any, initialState?: any, predicate?: OpenEndedFunction): {
        data: any;
        setData: Dispatch<any>;
        isLoading: boolean;
        error: object | null;
    };
    apiCall: ApiCallFunction;
}

const useData: UseDataFunction = (apiAction, args, initialState, predicate) => {
    const [data, setData] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const inputs = Array.isArray(args) ? args : [args];
    useEffect(() => {
        let mounted = true;
        if (predicate === undefined || (typeof predicate === 'function' ? predicate() : predicate)) {
            setIsLoading(true);
            setError(null);
            apiAction(args)
                .then(
                    ({ data }) => {
                        if (mounted) {
                            setData(data);
                            setIsLoading(false);
                        }
                    },
                    error => {
                        if (mounted) {
                            setError(error);
                            setIsLoading(false);
                        }
                    }
                )
                .catch(error => {
                    if (mounted) {
                        setError(error);
                        setIsLoading(false);
                    }
                });
        }
        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, inputs);

    return { data, setData, isLoading, error };
};

const getErrorMessage = (error: ApiError) => {
    let message = "We're unable to complete this operation at the moment. Please refresh the page to try again.";
    if (error) {
        if (error.response) {
            const { data, statusText } = error.response;
            if (typeof data === 'string' && data) {
                message = data;
            } else {
                message = statusText;
            }
        } else if (error.message) {
            message = error.message;
        } else if (error.request) {
            message = 'A network error occurred. Please refresh the page and try again.';
        }
    }

    return { message };
};

const apiCall: ApiCallFunction = (action, setData = () => ({}), setError = () => ({}), setLoading = () => ({}), startCall = () => ({})) => {
    return (...args: any[]) =>
        new Promise((resolve, reject) => {
            startCall();
            setLoading(true);
            action(...args)
                .then(
                    ({ data }) => {
                        setData(data);
                        resolve(data);
                    },
                    // Handle caught error
                    error => {
                        setError(getErrorMessage(error));
                        reject(error);
                    }
                )
                // Handle uncaught error
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
};

useData.apiCall = apiCall;

export default useData;
