import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { triggerClickOnEnter } from '../../helpers';
import { useDialogController } from '../../hooks';
import { Button, ImageCropper, Label, TrashButton } from '../../atoms';
import { ImageCropDialog } from '../../molecules';
import { DEFAULT_FILE_SIZE_LIMIT } from '../../constants';

function ImageUpload(props) {
    const {
        currentImage,
        dialogTitle,
        dialogConfirmLabel,
        labelText,
        uploadButtonText,
        editButtonText,
        previewImageAlt,
        showPreview,
        previewShape,
        aspectRatio,
        uploadedFilename,
        handleFileRemove,
        fileSizeLimit,
        infoText
    } = props;

    const [isShown, { showDialog, hideDialog }] = useDialogController(false);
    const [error, setError] = useState('');
    const [targetImage, setCropTarget] = ImageCropper.useImageCropper();
    const [isSavingImage, setIsSavingImage] = useState(false);
    const fileInput = useRef(null);
    const sizeLimit = fileSizeLimit ? fileSizeLimit : DEFAULT_FILE_SIZE_LIMIT;
    // Open the crop dialog with the current image
    const cropCurrentImage = () => {
        setCropTarget({ imageURL: props.currentImage });
        showDialog();
    };

    // Open the crop dialog when a new image has been selected
    const handleFileSelect = file => {
        if (file) {
            const imageURL = URL.createObjectURL(file);
            setCropTarget({ imageURL, imageType: file.type, name: file.name });
            showDialog();
        }
    };

    // Finished cropping, so save the image
    const handleCropComplete = file => {
        setIsSavingImage(true);
        const reader = new FileReader();
        const readerPromise = new Promise((resolve, reject) => {
            reader.onload = e => {
                const binaryData = e.target.result;
                const base64String = window.btoa(binaryData);

                if (file.size <= sizeLimit) {
                    props
                        .handleFileUpload(base64String, file)
                        .then(data => {
                            hideDialog();
                            setIsSavingImage(false);
                            resolve(data);
                        })
                        .catch(reject);
                } else {
                    setError('Your image is too large. Please optimise the file size and re-upload.');
                    setIsSavingImage(false);
                }
            };
        });
        reader.readAsBinaryString(file);

        return readerPromise;
    };

    return (
        <React.Fragment>
            <ImageRow>
                <Label label={labelText} isInline={true}>
                    <input
                        key={fileInput.current && fileInput.current.files[0] ? fileInput.current.files[0].name : 'logo-input'}
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        ref={fileInput}
                        onChange={() => handleFileSelect(fileInput.current.files[0])}
                        style={{ display: 'none' }}
                    />
                    <Upload tabIndex="0" onKeyDown={e => triggerClickOnEnter(e)}>
                        {uploadButtonText}
                    </Upload>
                </Label>
                {!!currentImage && (
                    <Button type="button" size="mini" onClick={cropCurrentImage} style={{ marginBottom: '0.25rem', marginRight: '0.5rem' }}>
                        {editButtonText}
                    </Button>
                )}
                {!!handleFileRemove && currentImage && (
                    <TrashButton dataForRemoval={handleFileRemove.dataForRemoval} apiAction={handleFileRemove.apiAction} setData={handleFileRemove.setData} />
                )}
                {!!uploadedFilename && <FileName>{uploadedFilename}</FileName>}
                {!!infoText && <InfoText>{infoText}</InfoText>}
            </ImageRow>
            {currentImage && showPreview && (
                <React.Fragment>
                    <ImagePreview onClick={cropCurrentImage} previewShape={previewShape}>
                        <img src={currentImage} alt={previewImageAlt} />
                    </ImagePreview>
                </React.Fragment>
            )}

            <ImageCropDialog
                title={dialogTitle}
                confirmLabel={dialogConfirmLabel}
                imageSrc={targetImage.imageURL}
                imageType={targetImage.imageType}
                imageName={targetImage.name}
                imageQuality={0.9}
                isShown={isShown}
                handleConfirm={handleCropComplete}
                handleCloseComplete={hideDialog}
                isSaving={isSavingImage}
                viewportShape={previewShape}
                aspectRatio={aspectRatio}
                error={error}
            />
        </React.Fragment>
    );
}

ImageUpload.propTypes = {
    uploadedFilename: PropTypes.string.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    dialogConfirmLabel: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    uploadButtonText: PropTypes.string.isRequired,
    editButtonText: PropTypes.string.isRequired,
    currentImage: PropTypes.string,
    previewImageAlt: PropTypes.string,
    showPreview: PropTypes.bool,
    aspectRatio: PropTypes.number,
    previewShape: PropTypes.string,
    handleFileUpload: PropTypes.func,
    handleFileRemove: PropTypes.shape({
        dataForRemoval: PropTypes.object.isRequired,
        apiAction: PropTypes.func,
        setData: PropTypes.func
    }),
    fileSizeLimit: PropTypes.number,
    infoText: PropTypes.string
};

const Upload = styled.span`
    border: 0.125rem solid #ffc845;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: #000000;
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    transition: 0.15s ease-in;
    box-sizing: border-box;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    height: 2rem;
    margin-right: 0.5rem;
    cursor: pointer;

    &:hover,
    &:focus {
        background-color: #ffc845;
        transition: 0.15s ease-in;
        outline: none;
    }
`;

const FileName = styled.div`
    margin: 0.8125rem 0;
    opacity: 0.5;
    font-size: 0.875rem;
`;

const ImageRow = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

/* eslint-disable prettier/prettier */
const ImagePreview = styled.div`
    max-height: 12rem;
    margin-bottom: 1rem;
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    box-sizing: border-box;
    cursor: pointer;

    img {
        object-fit: contain;
        max-width: 20rem;
        max-height: 9rem;
        ${props =>
            props.previewShape === 'circle' &&
            css`
                border-radius: 50%;
            `}
    }
`;

const InfoText = styled.div`
    font-size: 0.75rem;
    font-weight: 500;
    color: #999999;
    margin-left: 9.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
`;

export default ImageUpload;
