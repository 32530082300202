import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { FileUpload } from '../../molecules';
import { Button, Icon } from '../../atoms';

const PolicyDocuments = ({ id, getApi, uploadApi, removeApi, downloadApi }) => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        async function getEmployerFiles() {
            const { data } = await getApi(id);
            setUploadedFiles(data);
        }
        getEmployerFiles();
    }, [getApi, id]);

    const removeFile = fileId => {
        removeApi(id, fileId).then(() => {
            const updatedFiles = uploadedFiles.filter(x => x.fileId !== fileId);
            setUploadedFiles(updatedFiles);
        });
    };

    const downloadFile = (fileId, fileName) => {
        downloadApi(id, fileId, fileName);
    };

    const handleFileUpload = file => {
        const formData = new FormData();
        formData.append('file', file);

        const isValid = validateFile(file);
        if (!isValid) return Promise.resolve();

        return uploadApi(id, formData)
            .then(({ data: fileId }) => {
                const newFile = { fileId: fileId, fileName: file.name };
                setUploadedFiles(uploadedFiles => [...uploadedFiles, newFile]);
                return fileId;
            })
            .catch(error => {
                setErrors([error.message]);
            });
    };

    const validateFile = file => {
        const sizeLimit = 1048576 * 2;
        const acceptedFileTypes = [
            '.doc',
            '.docx',
            '.pdf',
            'application/msword',
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ];

        if (!file) return true;

        const isValidSize = file.size <= sizeLimit;
        let isValidType = acceptedFileTypes.includes(file.type);
        if (!file.type && !isValidType) {
            // For some reason windows doesn't send file type anymore for word docs
            isValidType = file.name.includes('.doc');
        }
        const errors = [];
        if (!isValidSize) {
            errors.push('Max file size is: 2mb');
        }
        if (!isValidType) {
            errors.push('Accepted file types: .doc, .docx, .pdf.');
        }
        setErrors(errors);
        return isValidSize && isValidType;
    };

    return (
        <Container>
            <span>Attach any relevant policy or WGEA documents here:</span>
            <FileUploadContainer>
                <FileUpload
                    name="policyDocuments"
                    accept=".doc,.docx,.pdf,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    handleFileUpload={handleFileUpload}
                    errors={errors}
                />
            </FileUploadContainer>
            {uploadedFiles.map(uploadedFile => (
                <Files key={uploadedFile.fileId}>
                    <Button theme="link" onClick={() => downloadFile(uploadedFile.fileId, uploadedFile.fileName)} isSentenceCase={true}>
                        {uploadedFile.fileName}
                    </Button>
                    <Button onClick={() => removeFile(uploadedFile.fileId)} size="mini" filled="border">
                        <Icon icon="Times" />
                    </Button>
                </Files>
            ))}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-flow: column wrap;
    align-content: flex-end;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;
`;

const FileUploadContainer = styled.div`
    text-align: right;
    margin: 10px 0;
`;

const Files = styled.div`
    text-align: right;
`;

export default PolicyDocuments;
