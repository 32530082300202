import 'core-js';
import 'blueimp-canvas-to-blob';
import 'url-search-params-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './global.css';
import App from './App';
import { ErrorBoundary } from './molecules';
import { AuthenticationProvider, AuthorizationProvider } from './auth';

import { ApiClientProvider } from './services';

ReactDOM.render(
    <ErrorBoundary>
        <BrowserRouter>
            <AuthenticationProvider>
                <AuthorizationProvider>
                    <ApiClientProvider>
                        <App />
                    </ApiClientProvider>
                </AuthorizationProvider>
            </AuthenticationProvider>
        </BrowserRouter>
    </ErrorBoundary>,
    document.getElementById('root')
);
