import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { history as historyPropTypes } from 'history-prop-types';

import { Pagination, SearchField } from '../../molecules';

const SearchPaginationRow = ({ currentPage, totalPages, total: totalResults, currentResults, history, location, handlePagination, renderResultsInfo, ...rest }) => {
    return (
        <Container>
            <SearchField history={history} location={location} {...rest} />
            <SearchPaginationToolbar hasInfo={!!renderResultsInfo}>
                {renderResultsInfo && renderResultsInfo()}
                <StyledPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    currentResults={currentResults}
                    totalResults={totalResults}
                    handlePagination={handlePagination}
                />
            </SearchPaginationToolbar>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    max-width: 100%;
    flex-wrap: wrap;
`;

const StyledPagination = styled(Pagination)`
    @media (min-width: 60rem) {
        margin-left: 2rem;
        justify-content: center;
    }
`;

const SearchPaginationToolbar = styled.div`
    display: flex;
    justify-content: ${({ hasInfo }) => (hasInfo ? 'space-between' : 'flex-end')};
    width: 100%;
    flex-direction: column;

    @media (min-width: 60rem) {
        flex-direction: row;
    }
`;

SearchPaginationRow.propTypes = {
    history: PropTypes.shape(historyPropTypes).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string
    }).isRequired,
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    currentResults: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    handlePagination: PropTypes.func.isRequired,
    renderResultsInfo: PropTypes.func
};

export default SearchPaginationRow;
