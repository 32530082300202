import { useField } from 'informed';
import React from 'react';
import styled from 'styled-components';

const NumberInput = props => {
    const { step } = props;
    const { fieldState, fieldApi, render, ref, userProps } = useField({ ...props });

    const { value } = fieldState;
    const { setValue, setTouched } = fieldApi;
    const { onChange, onBlur, ...rest } = userProps;

    return render(
        <Input
            {...rest}
            type="number"
            ref={ref}
            step={step || 'any'}
            value={value || ''}
            onChange={e => {
                setValue(e.target.value);
                if (onChange) {
                    onChange(e);
                }
            }}
            onBlur={e => {
                setTouched(true);
                if (onBlur) {
                    onBlur(e);
                }
            }}
            style={fieldState.error ? { border: 'solid 1px red' } : null}
        />
    );
};

const Input = styled.input`
    font-family: 'Open Sans';
    padding: 0.6875rem 1rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    color: ${props => (props.disabled ? '#bcbcbc' : '#000000')};
    background-color: ${props => (props.disabled ? '#F1F1F1' : '#FFFFFF')};
    cursor: ${props => (props.disabled ? 'default' : 'auto')}
    height: ${props => `calc(1.375rem + ${1.125 * props.rows}rem)`};
    ${props => props.errors && props.errors.length && 'border-color: #000000'};

    &:focus {
        border-color: #ffc845;
        outline: none;
    }
`;

export default NumberInput;
