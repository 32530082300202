import React from 'react';
import { Icon } from '../../atoms';
import styled, { keyframes } from 'styled-components';

const Loading = () => {
    return (
        <Container>
            <Spinner />
        </Container>
    );
};

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: #e31c79;
`;

export const Spinner = styled(Icon).attrs({
    icon: 'Spinner'
})`
    font-size: 3rem;
    animation: ${rotate} 2s linear infinite;
`;

export default Loading;
