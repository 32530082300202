import { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { REGION_PARAM } from '../constants';

const useRegion = () => {
    let routeMatch = useRouteMatch(`/${REGION_PARAM}`);
    let regionFromRoute = routeMatch.params.region;
    return {
        routeMatch,
        regionFromRoute
    };
};

const usePathForRegion = path => {
    const { regionFromRoute } = useRegion();
    return useMemo(() => {
        if (typeof path === 'string') {
            return `/${regionFromRoute}${path}`;
        } else {
            const clonedPathObject = { ...path };
            clonedPathObject.pathname = `/${regionFromRoute}${path.pathname}`;
            return clonedPathObject;
        }
    }, [path, regionFromRoute]);
};

const useLocale = () => {
    const { regionFromRoute } = useRegion();
    switch (regionFromRoute) {
        case 'au':
            return 'en-au';
        case 'uk':
            return 'en-gb';
        case 'us':
            return 'en-us';
        default:
            return 'en-au';
    }
};

export { useRegion, usePathForRegion, useLocale };
