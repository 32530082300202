import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Scope } from 'informed';

import { ErrorMessage, Field, TextInput } from '../../atoms';
import { ImageUpload } from '../../molecules';
import { combineErrors, validateUrl } from '../../helpers';

const EmployeeProfileContentBlockFields = ({ errors, formState, formApi, isSubmitting, ...rest }) => {
    const [uploadedFilename, setUploadedFilename] = useState(null);
    const handleFileUpload = (b64, file) => {
        return rest.handleFileUpload(b64, file.name, file.type, file, responseData => {
            formApi.setValue('content.imageUrl', responseData.url);
            setUploadedFilename(file.name);
        });
    };

    const formErrors = {};
    const contentTouched = formState.touched.content || {};
    const contentErrors = formState.errors.content;
    if (contentErrors) {
        formErrors.heading = combineErrors(errors.Heading, contentErrors.heading, contentTouched.heading);
        formErrors.summary = combineErrors(errors.Summary, contentErrors.summary, contentTouched.summary);
        formErrors.readMoreText = combineErrors(errors.ReadMoreText, contentErrors.readMoreText, contentTouched.readMoreText);
        formErrors.profileUrl = combineErrors(errors.ProfileUrl, contentErrors.profileUrl, contentTouched.profileUrl);
        formErrors.imageUrl = combineErrors(errors.ImageUrl, contentErrors.imageUrl, contentTouched.imageUrl);
    }

    return (
        <Fragment>
            <Scope scope="content">
                <Field label="Heading" name="heading" required disabled={isSubmitting} errors={formErrors.heading} fieldType="text" />
                <Field label="Summary" name="summary" required disabled={isSubmitting} errors={formErrors.summary} fieldType="text" />
                <Field label="Read More Text" name="readMoreText" required disabled={isSubmitting} errors={errors.readMoreText} fieldType="text" rows={4} />
                <Field label="Profile URL" name="profileUrl" required disabled={isSubmitting} errors={formErrors.profileUrl} fieldType="text" validate={validateUrl} />
                {/* Hidden input to capture imageUrl value in form */}
                <TextInput name="imageUrl" required style={{ display: 'none' }} />
            </Scope>
            <ImageUpload
                handleFileUpload={handleFileUpload}
                currentImage={formState.values.content ? formState.values.content.imageUrl : ''}
                uploadedFilename={uploadedFilename || ''}
                labelText="Image"
                uploadButtonText="Choose image"
                editButtonText="Edit image"
                dialogTitle="Edit image"
                dialogConfirmLabel="Save image"
                previewImageAlt="Quote Image"
                showPreview
                aspectRatio={290 / 157}
            />
            {formErrors.imageUrl && <ErrorMessage errors={formErrors.imageUrl} />}
        </Fragment>
    );
};

EmployeeProfileContentBlockFields.propTypes = {
    formState: PropTypes.object.isRequired,
    errors: PropTypes.object,
    isSubmitting: PropTypes.bool,
    handleFileUpload: PropTypes.func,
    formApi: PropTypes.object
};

EmployeeProfileContentBlockFields.defaultProps = {
    handleFileUpload: () => {},
    isSubmitting: false,
    errors: {}
};

export default EmployeeProfileContentBlockFields;
