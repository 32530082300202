import React from 'react';
import { Redirect } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Loading } from '../atoms';

const ProtectedRedirect = ({ component, ...args }) => (
    <Redirect
        component={withAuthenticationRequired(component, {
            onRedirecting: () => <Loading />
        })}
        {...args}
    />
);

export default ProtectedRedirect;
