import React, { useCallback, useEffect, useRef, useState } from 'react';
import Icon from '../Icon';
import styled from 'styled-components';
import { useData } from '../../hooks';
import { ErrorMessage, Spinner } from '../index';

interface Response {
    data: object;
}

interface TrashButtonProps {
    dataForRemoval: { id: string; data: object };
    apiAction: (...args: any[]) => Promise<Response>;
    setData: () => void;
}

const TrashButton: React.FC<TrashButtonProps> = ({ dataForRemoval, apiAction, setData }) => {
    const isDisposed = useRef(false);
    useEffect(() => {
        return () => {
            isDisposed.current = true;
        };
    }, []);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<string | null>(null);

    const loadingWrapper = (value: boolean) => {
        if (!isDisposed) setIsLoading(value);
    };

    const handleRemoveItem = () => useData.apiCall(apiAction, setData, setErrors, loadingWrapper)(dataForRemoval.id, dataForRemoval.data);

    return (
        <>
            <RemoveLink onClick={handleRemoveItem}>
                <TrashIcon icon="TrashAlt" />
                <span>Remove</span>
                {isLoading && <Spinner />}
            </RemoveLink>
            {errors && <ErrorMessage errors={[errors]} />}
        </>
    );
};

const TrashIcon = styled(Icon)`
    color: #747474;
    margin-right: 0.375rem;
`;

const RemoveLink = styled.a`
    color: #0469ca;
    font-size: 0.8rem;

    &:hover {
        cursor: pointer;

        ${TrashIcon} {
            color: #212121;
        }
    }

    span {
        margin-right: 0.375rem;
    }
`;

export default TrashButton;
