import React, { Component, Fragment } from 'react';

import { ViewAll } from '../../hocs';
import { InlinePageTitle, Pagination, JobAutomationTable } from '../../molecules';
import { EmptyState } from '../../atoms';

class JobAutomations extends Component {
    render() {
        const { data, history, location, handleGetAll } = this.props;
        const { results, ...dataLessResults } = data;

        return (
            <Fragment>
                <InlinePageTitle title="Job Automations" />
                {data && data.total ? (
                    <Fragment>
                        <Pagination
                            {...dataLessResults}
                            totalResults={data.total}
                            currentResults={results.length}
                            currentPage={data.currentPage}
                            totalPages={data.totalPages}
                            history={history}
                            location={location}
                            handlePagination={handleGetAll}
                        />
                        <JobAutomationTable results={results} />
                    </Fragment>
                ) : (
                    <EmptyState screen="JobAutomations" />
                )}
            </Fragment>
        );
    }
}

export default ViewAll(JobAutomations)({ domain: 'JobAutomations' });
