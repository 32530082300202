import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Th = ({ children, ...rest }) => {
    return (
        <Cell tabIndex="0" scope="col" {...rest}>
            <Wrapper>
                <Inner>{children}</Inner>
            </Wrapper>
        </Cell>
    );
};

const Cell = styled.th.attrs({
    tabIndex: 0,
    scope: 'col'
})`
    background-color: #f1f1f1;

    &:first-child {
        border-top-left-radius: 0.5rem;
    }

    &:last-child {
        border-top-right-radius: 0.5rem;
    }

    &:focus {
        outline: none;

        span {
            border-color: #ffc845;
        }
    }
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
`;

const Inner = styled.span`
    border-bottom: 0.0625rem solid transparent;
`;

Th.propTypes = {
    children: PropTypes.node
};

export default Th;
