import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

const Date = ({ date, ...rest }) => {
    return <Moment date={date} fromNowDuring={172800000} format="DD/MM/YYYY" {...rest} />;
};

Date.propTypes = {
    date: PropTypes.string.isRequired
};

export default Date;
