import React, { Fragment, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'informed';
import { Redirect } from 'react-router';
import styled, { css } from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';

import { InlinePageTitle, Panel } from '../../molecules';
import { Button, Date, Field, Label, Loading } from '../../atoms';
import { CONTENT_TYPES, CONTENT_BLOCK_STATUS } from '../../constants';
import { ApiClientContext } from '../../services';
import TextContentBlockFields from './TextContentBlockFields';
import QuoteContentBlockFields from './QuoteContentBlockFields';
import VideoContentBlockFields from './VideoContentBlockFields';
import EmployeeProfileContentBlockFields from './EmployeeProfileContentBlockFields';
import EndorsementsContentBlockFields from './EndorsementsContentBlockFields';
import { useData } from '../../hooks';
import { usePathForRegion } from '../../hooks';
import EndorsedEmployerContentBlockFields from './EndorsedEmployerContentBlockFields';
import { ErrorView } from '../index';

const apiCall = useData.apiCall;

const useContentBlockData = props => {
    const {
        computedMatch: {
            params: { blockId, id }
        }
    } = props;
    const { apiClient } = useContext(ApiClientContext);
    const isExisting = blockId !== undefined;

    const { data, setData, isLoading, error: loadError } = useData(apiClient.getEmployerPageContentBlock, blockId, null, isExisting);
    const [apiError, setApiError] = useState(null);
    const addEmployerPageContentBlock = apiCall(payload => apiClient.addEmployerPageContentBlock(payload), setData, setApiError);
    const updateEmployerPageContentBlock = apiCall((blockId, payload) => apiClient.updateEmployerPageContentBlock(blockId, payload), setData, setApiError);
    const deleteEmployerPageContentBlock = apiCall(blockId => apiClient.deleteEmployerPageContentBlock(blockId), setData, setApiError);
    const uploadImage = (b64, name, type, file, successCb) => {
        return apiClient.uploadContentFile({ b64, name, type }).then(({ data: responseData }) => {
            if (successCb && typeof successCb === 'function') {
                successCb(responseData, data, setData);
            }

            return responseData.url;
        });
    };
    const navigateBackPath = usePathForRegion(`/employers/${id}`);

    return {
        contentBlock: data,
        isLoading,
        error: loadError || apiError,
        addEmployerPageContentBlock,
        updateEmployerPageContentBlock,
        deleteEmployerPageContentBlock,
        uploadImage,
        navigateBackPath
    };
};

const EmployerContentBlock = props => {
    const { errors = {}, computedMatch, location, isSubmitting } = props;
    const isExisting = computedMatch.params.blockId !== undefined;
    const {
        contentBlock,
        isLoading,
        error,
        addEmployerPageContentBlock,
        updateEmployerPageContentBlock,
        deleteEmployerPageContentBlock,
        uploadImage,
        navigateBackPath
    } = useContentBlockData(props);
    const formRef = useRef(null);
    const portalRef = useRef(null);
    const { name: userName } = useAuth0();

    // Redirect back to employer page if there are no query params
    if (!isExisting && !location.search) {
        return <Redirect to={`/employers/${computedMatch.params.id}`} />;
    }

    if (isLoading || (isExisting && !contentBlock)) {
        return <Loading />;
    }
    const isDraft = isExisting ? CONTENT_BLOCK_STATUS[contentBlock.status] === 'Draft' : false;

    const handleSubmit = formValues => {
        // Map endorsements from string array to id object array
        if (formValues.contentType === 'endorsements' && formValues.content.endorsements) {
            formValues.content.endorsements = formValues.content.endorsements.map(e => ({
                id: e
            }));
        }

        if (isExisting) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { content, contentType, modifiedBy, ...otherContentBlockValues } = contentBlock;
            updateEmployerPageContentBlock(computedMatch.params.blockId, {
                ...formValues,
                modifiedBy: userName,
                ...otherContentBlockValues
            }).then(navigateBack);
            return;
        }

        const params = new URLSearchParams(location.search);
        const pageTab = params.get('pageTab');
        const employerSlug = params.get('employerSlug');
        const sidebar = params.get('sidebar');

        // Don't create the content block if required query params are missing
        if (!pageTab || !employerSlug) {
            navigateBack();
            return;
        }

        const payload = {
            createdBy: userName,
            modifiedBy: userName,
            employerSlug,
            sidebar: sidebar === 'true',
            pageTab,
            ...formValues
        };

        addEmployerPageContentBlock(payload).then(navigateBack);
    };

    const handleDelete = () => {
        if (!isExisting) {
            return;
        }
        deleteEmployerPageContentBlock(computedMatch.params.blockId).then(navigateBack);
    };

    const navigateBack = () => {
        const params = new URLSearchParams(location.search);
        const pageTab = params.get('pageTab');
        props.history.push({
            pathname: navigateBackPath,
            search: `?pageTab=${pageTab}`
        });
    };

    const initialValues = isExisting ? contentBlock : {};

    // Map endorsements from id object array to string array
    if (initialValues.content && initialValues.content.endorsements) {
        initialValues.content.endorsements = initialValues.content.endorsements.map(e => e.id);
    }

    return (
        <>
            <ErrorView errors={error} />
            <InlinePageTitle title={`${isExisting ? 'Update' : 'Add'} content block`}>
                {isExisting && (
                    <Button onClick={handleDelete} filled="border">
                        {isDraft ? 'Delete draft' : 'Delete'}
                    </Button>
                )}
            </InlinePageTitle>

            <Panel title="Content block details">
                <Form onSubmit={handleSubmit} allowEmptyStrings initialValues={initialValues} getApi={api => (formRef.current = api)}>
                    {({ formState, formApi }) => {
                        const { values } = formState;
                        const contentType = CONTENT_TYPES.find(type => type.id === values.contentType);
                        const contentTypeName = contentType ? contentType.name : '';
                        const contentTypeId = contentType ? contentType.id : null;
                        return (
                            <Fragment>
                                <Field
                                    label="Type"
                                    name="contentType"
                                    required
                                    disabled={isExisting || isSubmitting}
                                    errors={errors.WorkType || (formState.touched.contentType && formState.errors.contentType && [formState.errors.contentType])}
                                    displayValueAs={<span>{contentTypeName}</span>}
                                    fieldType="select"
                                    options={CONTENT_TYPES}
                                />
                                {isExisting && (
                                    <Fragment>
                                        <Label label="Status">
                                            <StatusLabel isDraft={isDraft}>{CONTENT_BLOCK_STATUS[contentBlock.status]}</StatusLabel>
                                        </Label>
                                        <Label label="Created by">
                                            <span>{initialValues.createdBy}</span>
                                        </Label>
                                        <Label label="Created">
                                            <Date disabled date={initialValues.created} />
                                        </Label>
                                        <Label label="Modified by">
                                            <span>{initialValues.modifiedBy}</span>
                                        </Label>
                                        <Label label="Modified">
                                            <Date disabled date={initialValues.modified} />
                                        </Label>
                                    </Fragment>
                                )}
                                {contentTypeId === 'text' && (
                                    <TextContentBlockFields formState={formState} errors={errors} data={contentBlock} handleFileUpload={uploadImage} />
                                )}
                                {contentTypeId === 'quote' && (
                                    <QuoteContentBlockFields formState={formState} formApi={formApi} errors={errors} data={contentBlock} handleFileUpload={uploadImage} />
                                )}
                                {contentTypeId === 'video' && <VideoContentBlockFields formState={formState} formApi={formApi} errors={errors} data={contentBlock} />}
                                {contentTypeId === 'employeeprofile' && (
                                    <EmployeeProfileContentBlockFields
                                        formState={formState}
                                        formApi={formApi}
                                        errors={errors}
                                        data={contentBlock}
                                        handleFileUpload={uploadImage}
                                    />
                                )}
                                {contentTypeId === 'endorsements' && (
                                    <EndorsementsContentBlockFields
                                        formState={formState}
                                        formApi={formApi}
                                        errors={errors}
                                        data={contentBlock}
                                        handleFileUpload={uploadImage}
                                        portalRef={portalRef}
                                    />
                                )}
                                {contentTypeId === 'endorsedemployer' && <EndorsedEmployerContentBlockFields formState={formState} errors={errors} />}
                            </Fragment>
                        );
                    }}
                </Form>
                {/* Portal for endorsement management form */}
                <div ref={portalRef} />
            </Panel>
            <Button
                type="submit"
                onClick={() => formRef.current && formRef.current.submitForm()}
                disabled={(formRef.current && formRef.current.getState().invalid) || isSubmitting}
                style={{ marginRight: '0.5rem' }}
            >
                Save draft
            </Button>
            <Button type="button" onClick={navigateBack} filled="border">
                Cancel
            </Button>
        </>
    );
};

EmployerContentBlock.propTypes = {
    errors: PropTypes.object,
    isSubmitting: PropTypes.bool,
    computedMatch: PropTypes.any,
    location: PropTypes.any,
    history: PropTypes.any
};

const StatusLabel = styled.span`
    font-weight: 600;
    ${props =>
        props.isDraft &&
        css`
            color: #0890a8;
        `}
`;

export default EmployerContentBlock;
