import React, { Component } from 'react';
import { ErrorMessage } from '../../atoms';

export default class ErrorBoundary extends Component {
    state = { hasError: false };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        console.warn('<ErrorBoundary>', error);
        console.warn('<ErrorBoundary>', info);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorMessage errors={['An error occurred while trying to complete your request. Please refresh the page and try again']} />;
        }

        return this.props.children;
    }
}
