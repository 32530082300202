import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import withSizes from 'react-sizes';

import { Header, Sidebar } from '../../components';

class View extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSidebarVisible: this.props.isMobile ? false : true
        };
    }

    toggleState = key => {
        this.setState({
            [key]: !this.state[key]
        });
    };

    componentDidMount() {
        document.title = document.title ? `${document.title} | WORK180 Portal` : 'WORK180 Portal';
    }

    render() {
        const { children, isMobilePortraitOnly, ...rest } = this.props;
        const { isSidebarVisible } = this.state;

        return (
            <Fragment>
                <Header includeUserMenu={!isMobilePortraitOnly} toggleState={this.toggleState} {...rest} />
                <Main>
                    <Sidebar isSidebarVisible={isSidebarVisible} includeUserMenu={isMobilePortraitOnly} {...rest} />
                    <Container isSidebarVisible={isSidebarVisible}>
                        <Content>{children}</Content>
                    </Container>
                </Main>
            </Fragment>
        );
    }
}

const Main = styled.div`
    display: flex;
    justify-content: flex-end;
    min-height: calc(100vh - 3rem);
    position: relative;
    overflow: hidden;
    margin-top: 3rem;
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    transition: all 0.5s ease;

    flex-shrink: ${props => (props.isSidebarVisible ? 1 : 0)};

    @media (min-width: 48rem) {
        width: ${props => props.isSidebarVisible && 'calc(100% - 18rem)'};
    }
`;

const Content = styled.div`
    width: 100%;
    max-width: 60rem;
    padding: 2rem 1rem;
    box-sizing: border-box;

    @media (min-width: 48rem) {
        padding: 2rem;
    }
`;

const mapSizesToProps = ({ width }) => ({
    isMobile: width < 768,
    isMobilePortraitOnly: width < 480
});

export default withSizes(mapSizesToProps)(View);
