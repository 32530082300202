import React from 'react';
import { Dialog as EDialog } from 'evergreen-ui';
import styled from 'styled-components';

function Dialog({ children, title, ...rest }) {
    return (
        <EDialog title={<Title>{title}</Title>} {...rest}>
            {children}
        </EDialog>
    );
}

const Title = styled.span`
    font-family: 'Montserrat', sans-serif;
    color: #000000;
`;

export default Dialog;
