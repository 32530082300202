import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ErrorView } from '../../components';
import { Loading } from '../../atoms';
import { getQueryString } from '../../helpers';
import { ApiClientContext } from '../../services';

const ViewAll = WrappedComponent => ({ domain }) => {
    const WrappedViewAll = ({ location, ...props }) => {
        const [data, setData] = useState({});
        const [errors, setErrors] = useState({});
        const [isLoading, setLoading] = useState(true);
        const { apiClient } = useContext(ApiClientContext);

        const getAll = useMemo(
            () => ({
                Employers: apiClient.getAllEmployers,
                Jobs: apiClient.getAllJobs,
                Applications: apiClient.getAllApplications,
                Users: apiClient.getAllUsers,
                JobAnalytics: apiClient.getJobAnalytics,
                JobFilters: [
                    { apiCall: apiClient.getAllJobFilters, key: 'jobFilters' },
                    { apiCall: apiClient.getAllEmployers, key: 'employers' }
                ],
                Widgets: apiClient.getAllWidgets,
                JobAutomations: apiClient.getAllJobAutomations
            }),
            [apiClient]
        );

        const handleGetAll = useCallback(
            page => {
                const { search } = location;
                const queryStr = getQueryString(search);
                const apiCall = getAll[domain];
                const apiCallArr = Array.isArray(apiCall) ? apiCall : [{ apiCall }];
                new Promise((resolve, reject) => {
                    return Promise.all(apiCallArr.map(a => a.apiCall(page, queryStr)))
                        .then(results => {
                            let data = {};
                            results.forEach((result, i) => {
                                if (apiCallArr[i].key) {
                                    data[apiCallArr[i].key] = result.data;
                                } else {
                                    data = {
                                        ...data,
                                        ...result.data
                                    };
                                }
                            });

                            resolve(data);
                        })
                        .catch(reject);
                })
                    .then(data => {
                        setData(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        if (error.response) {
                            console.error(error.response);
                        } else if (error.request) {
                            console.error(error.request);
                        } else {
                            console.error(error.message);
                        }
                        setErrors({ message: `We're unable to load ${domain.toLowerCase()} at the moment. Please refresh the page to try again.` });
                        setLoading(false);
                    });
            },
            [getAll, location]
        );

        useEffect(() => {
            handleGetAll();
        }, [handleGetAll]);

        if (isLoading) {
            return <Loading />;
        }

        document.title = `${domain} | WORK180 Portal`;

        return (
            <>
                <ErrorView errors={errors} />

                <WrappedComponent
                    setData={setData}
                    setLoading={setLoading}
                    handleGetAll={handleGetAll}
                    data={data}
                    isLoading={isLoading}
                    errors={errors}
                    location={location}
                    {...props}
                />
            </>
        );
    };

    return WrappedViewAll;
};

export default ViewAll;
