export const MENU_ITEMS = (apiUrl, token) => [
    {
        label: 'Employers',
        href: '/employers',
        children: [
            {
                label: 'View all employers',
                href: '/employers/all'
            },
            {
                label: 'Add a new employer',
                href: '/employers/add'
            }
        ]
    },
    {
        label: 'Jobs',
        href: '/jobs',
        children: [
            {
                label: 'View all jobs',
                href: '/jobs/all'
            },
            {
                label: 'Add a new job',
                href: '/jobs/add'
            },
            {
                label: 'Job analytics',
                href: '/jobs/analytics'
            }
        ]
    },
    {
        label: 'Applications',
        href: '/applications',
        children: [
            {
                label: 'View all applications',
                href: '/applications/all'
            }
        ]
    },
    {
        label: 'Users',
        href: '/users',
        children: [
            {
                label: 'View all users',
                href: '/users/all'
            },
            {
                label: 'Add a new user',
                href: '/users/add'
            }
        ]
    },
    {
        label: 'Widgets',
        href: '/widgets',
        children: [
            {
                label: 'View all widgets',
                href: '/widgets/all'
            },
            {
                label: 'Add a new widget',
                href: '/widgets/add'
            }
        ]
    },
    {
        label: 'JobAd180',
        href: '/jobs/analysis'
    },
    {
        label: 'Job Automations',
        href: '/job-automations',
        children: [
            {
                label: 'View all job automations',
                href: '/job-automations/all'
            }
        ]
    },
    {
        label: 'Hangfire Dashboard',
        href: `${apiUrl}hangfire?access_token=${token}`
    }
];

export const REGIONS = [
    { id: 'au', name: 'Australia' },
    { id: 'uk', name: 'United Kingdom' },
    { id: 'us', name: 'United States' }
];
export const REGION_PARAM = ':region';
export const REGION_PARAM_WHITELIST = `${REGION_PARAM}(au|uk|us)`;

export const WORK_TYPES = [
    { name: 'Casual', id: 'casual' },
    { name: 'Contract/Temp', id: 'contract' },
    { name: 'Part Time', id: 'part-time' },
    { name: 'Full Time', id: 'full-time' }
];

export const DISCIPLINES = [
    { name: 'Accounting', id: 'accounting' },
    { name: 'Sport & Recreation', id: 'sport-and-recreation' },
    { name: 'Administration', id: 'administration' },
    { name: 'Advert/Media/Comm/Ent & Design', id: 'advert-slash-media-slash-comm-slash-ent-and-design' },
    { name: 'Apprenticeships, Traineeships & Internships', id: 'apprenticeships-and-traineeships' },
    { name: 'Banking & Finance', id: 'banking-and-finance' },
    { name: 'Call Centre & Customer Service', id: 'call-centre-and-customer-service' },
    { name: 'Consulting', id: 'consulting' },
    { name: 'Construction & Architecture', id: 'construction-and-architecture' },
    { name: 'Education & Training', id: 'education-and-training' },
    { name: 'Engineering', id: 'engineering' },
    { name: 'Executive', id: 'executive' },
    { name: 'Government, Defence, Emergency', id: 'government-defence-emergency' },
    { name: 'Graduate/Undergraduate', id: 'Graduate' },
    { name: 'Healthcare & Medical', id: 'healthcare-and-medical' },
    { name: 'Hospitality, Tourism & Travel', id: 'hospitality-tourism-and-travel' },
    { name: 'HR & Recruitment', id: 'hr-and-recruitment' },
    { name: 'I.T. & T', id: 'it-and-t' },
    { name: 'Insurance & Superannuation', id: 'insurance-and-superannuation' },
    { name: 'Legal', id: 'legal' },
    { name: 'Manufacturing/Operations', id: 'manufacturing-slash-operations' },
    { name: 'Marketing', id: 'marketing' },
    { name: 'Mining, Resources & Energy', id: 'mining-oil-and-gas' },
    { name: 'Not-for-Profit Organisation', id: 'not-for-profit-organisation' },
    { name: 'Real Estate & Property', id: 'real-estate-and-property' },
    { name: 'Records and Information Management', id: 'records-and-information-management' },
    { name: 'Retail & Fashion', id: 'retail-and-fashion' },
    { name: 'Sales', id: 'sales' },
    { name: 'Science', id: 'science' },
    { name: 'Trade & Services', id: 'trade-and-services' },
    { name: 'Transport, Shipping, Logistics', id: 'transport-shipping-logistics' }
];

export const LEVELS_OF_EXPERIENCE = [
    { name: 'Not Selected', id: null },
    { name: 'Apprenticeships, Traineeships, Cadetships & Internships', id: 1 },
    { name: 'Junior/Entry level', id: 2 },
    { name: 'Graduate/Undergraduate', id: 3 },
    { name: 'Mid-Level', id: 7 },
    { name: 'Senior/Lead', id: 4 },
    { name: 'Manager/Team lead', id: 5 },
    { name: 'Director/Executive', id: 6 }
];

export const APPLICATION_LINK_TYPES = [
    { name: 'WORK180', id: 'WORK180' },
    { name: "My company's ATS", id: 'external' },
    { name: 'Email only', id: 'email' }
];

export const REMOTE_REGIONS_AU = [
    { name: 'AU/NZ Only', id: true },
    { name: 'Global', id: false }
];

export const REMOTE_REGIONS_UK = [
    { name: 'UK/EU Only', id: true },
    { name: 'Global', id: false }
];

export const REMOTE_REGIONS_US = [
    { name: 'USA Only', id: true },
    { name: 'Global', id: false }
];

export const USER_ROLE_TYPES = [
    { id: 'client', name: 'Client' },
    { id: 'admin', name: 'Admin' }
];

export const PASSWORD_REQUIREMENTS = [
    { id: 'uppercase', name: 'Uppercase (A-Z)' },
    { id: 'lowercase', name: 'Lowercase (a-z)' },
    { id: 'number', name: 'Number (0-9)' },
    { id: 'special', name: 'Special character (!@#$%^&*)' },
    { id: 'minimum', name: 'Minimum of 8 characters' }
];

export const CONTENT_TYPES = [
    { id: 'text', name: 'Text' },
    { id: 'quote', name: 'Quote' },
    { id: 'video', name: 'Video' },
    { id: 'employeeprofile', name: 'Employee profile' },
    { id: 'jobalert', name: 'Job alert' },
    { id: 'endorsements', name: 'Endorsements' },
    { id: 'endorsedemployer', name: 'Endorsed Employer' }
];

export const COMPANY_SIZES = [
    { id: '1-20', name: '1-20' },
    { id: '21-50', name: '21-50' },
    { id: '51-100', name: '51-100' },
    { id: '101-250', name: '101-250' },
    { id: '251-500', name: '251-500' },
    { id: '501-1,000', name: '501-1,000' },
    { id: '1,001-5,000', name: '1,001-5,000' },
    { id: '5,001-10,000', name: '5,001-10,000' },
    { id: '10,001+', name: '10,001+' }
];

export const EMPLOYER_STATUSES = [
    { id: 0, name: 'Draft' },
    { id: 1, name: 'Active' },
    { id: 2, name: 'Expired' }
];

export const CONTENT_BLOCK_STATUS = ['Published', 'Draft'];

export const GENDER_BIAS = {
    'strongly-masculine': 'GenderStronglyMale',
    masculine: 'GenderMale',
    neutral: 'GenderNeutral',
    empty: 'GenderNeutral',
    feminine: 'GenderFemale',
    'strongly-feminine': 'GenderStronglyFemale'
};

export const DEFAULT_FILE_SIZE_LIMIT = 500000; /*bytes*/
