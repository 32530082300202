import React from 'react';
import styled from 'styled-components';

import { Navigation, UserMenu } from '../../molecules';
import { MENU_ITEMS } from '../../constants';
import config from '../../config';
import { useAuthorization } from '../../auth';

const Sidebar = props => {
    const { isClient, isSuper, idToken } = useAuthorization();
    const { isSidebarVisible, includeUserMenu, ...rest } = props;
    const { region } = rest;
    const containerProps = {
        isSidebarVisible,
        includeUserMenu
    };

    let menuItems = MENU_ITEMS(config.api.url, idToken);
    if (isClient) {
        menuItems = menuItems.filter(x => x.label !== 'Employers');
    }
    if (isClient || !config.toggles.users) {
        menuItems = menuItems.filter(item => item.label !== 'Users');
    }
    if (isClient) {
        menuItems = menuItems.filter(x => x.label !== 'Widgets');
    }
    if (isClient) {
        menuItems = menuItems.filter(x => x.label !== 'Content');
    }
    if (isClient) {
        menuItems = menuItems.filter(x => x.label !== 'Job Automations');
    }
    if (!isSuper) {
        menuItems = menuItems.filter(x => x.label !== 'Hangfire Dashboard');
    }

    return (
        <Container {...containerProps}>
            {includeUserMenu && (
                <UserMenuWrapper>
                    <UserMenu {...rest} />
                </UserMenuWrapper>
            )}
            <Navigation menu={menuItems} region={region} />
        </Container>
    );
};

const Container = styled.aside`
    width: 18rem;
    background-color: #f1f1f1;
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    transition: left 0.5s ease;
    padding: 1.5rem 0;
    position: fixed;
    top: 3rem;
    bottom: 0;
    z-index: 4;
    left: ${props => (props.isSidebarVisible ? 0 : '-18rem')};
`;

const UserMenuWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3rem;
    padding: 0 1rem;
    display: flex;
    background-color: #e9e9e9;

    > div {
        margin: unset;
        padding: unset;
        border: unset;
        flex-grow: 1;
        justify-content: space-between;

        > * {
            color: #000000;
        }
    }
`;

export default Sidebar;
