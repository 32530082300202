import { Form } from 'informed';
import React, { Fragment, useContext } from 'react';
import styled from 'styled-components';

import { useAuth0 } from '@auth0/auth0-react';
import { Button, Date, Field, InfoBanner, Label } from '../../atoms';
import config from '../../config';
import { COMPANY_SIZES, EMPLOYER_STATUSES } from '../../constants';
import { getRegion, validateUrl } from '../../helpers';
import { ViewEditAdd } from '../../hocs';
import { useData } from '../../hooks';
import { ImageUpload, InlinePageTitle, Panel } from '../../molecules';
import { ApiClientContext } from '../../services';
import EmployerFeatures from '../EmployerFeatures';
import EmployerPerks from '../EmployerPerks';
import EmployerJobAutomationInformation from './EmployerJobAutomationInformation';
import EmployerPage from './EmployerPage';
import EmployerSelfService from './EmployerSelfService';

function Employer(props) {
    const { data, errors, files, isExisting, handleSubmit, handleFileUpload, handleRemoveFileFromState } = props;
    const { user } = useAuth0();
    const { apiClient } = useContext(ApiClientContext);
    const { getAllEmployerIndustries, removeEmployerImage } = apiClient;
    const userName = user.name;
    const { data: industryList } = useData(getAllEmployerIndustries, [], null);
    const employerPerksEnabled = data?.employerFeatureMaps?.find(featureMap => featureMap?.employerFeature?.featureName === 'employer-perks')?.enabled ?? false;
    const handleImageFileUpload = key => async (b64, file) => handleFileUpload(key, file, isExisting ? userName : undefined);

    const handleRemoveEmployerImageProps = (imageKey, stateKey) => ({
        dataForRemoval: { id: data.id, data: { updatedBy: userName, imageType: stateKey } },
        apiAction: removeEmployerImage,
        setData: updatedData => handleRemoveFileFromState(imageKey, stateKey, updatedData)
    });

    function getStatusName(status) {
        var resolved = EMPLOYER_STATUSES.find(x => x.id === status);
        if (!resolved) return '';
        return resolved.name;
    }

    const updateForm = updatedFormState => {
        updatedFormState.updatedBy = userName;
        return handleSubmit(updatedFormState);
    };

    const getHubspotProcessDocLink = () => {
        const region = getRegion();
        switch (region) {
            case 'uk':
                return 'https://docs.google.com/document/d/1uAelSAYxWVxvC7piOjOkX5H21fZAEuLYgm_Va1v0PuE/';
            case 'us':
                return 'https://docs.google.com/document/d/1pmpAiXf4UESCYsBKR_9mcxfcOEHPpxq_wcOnyIU1ofY/edit?usp=sharing';
            default:
                return 'https://docs.google.com/document/d/1JmW6RsjPCtgJc6taWVuKePPIOTrAHbeHzZLcim9YPmk/edit?usp=sharing';
        }
    };

    return (
        <Fragment>
            <InlinePageTitle title="Employer">
                {isExisting && (
                    <ButtonRow>
                        <Button
                            to={{
                                pathname: '/jobs/all',
                                search: `?employer=${data.slug}`
                            }}
                            icon="ArrowRight"
                        >
                            View Jobs
                        </Button>
                    </ButtonRow>
                )}
            </InlinePageTitle>
            {!isExisting && (
                <InfoBanner>
                    Note: Employers should be automatically setup via HubSpot automation based on the deal transitioning throughout the pipeline{' '}
                    <a href={getHubspotProcessDocLink()} target="_blank" rel="noopener noreferrer">
                        (see the process docs on this)
                    </a>
                    , please ensure this is not the case before continuing with adding this Employer.
                </InfoBanner>
            )}
            {(!isExisting || (!!data && !!data.name)) && (
                <Form onSubmit={updateForm} allowEmptyStrings>
                    {({ formState }) => (
                        <Fragment>
                            {isExisting && (
                                <Field
                                    name="name"
                                    initialValue={data.name}
                                    required
                                    errors={errors.Name || (formState.touched.name && formState.errors.name && [formState.errors.name])}
                                    displayValueAs={<h2 style={{ marginBottom: 0 }}>{data.name}</h2>}
                                    fieldType="text"
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    handleSubmit={() => updateForm(formState.values)}
                                    invalid={!!formState.errors.name}
                                />
                            )}
                            <Panel title="Employer Details">
                                {!isExisting && (
                                    <Field
                                        label="Name"
                                        name="name"
                                        initialValue={data.name}
                                        required
                                        errors={errors.Name || (formState.touched.name && formState.errors.name && [formState.errors.name])}
                                        fieldType="text"
                                        initialRenderType={isExisting ? 'value' : 'field'}
                                        isTogglingEnabled={isExisting}
                                        handleSubmit={() => handleSubmit(formState.values)}
                                        invalid={!!formState.errors.name}
                                    />
                                )}
                                <Field
                                    label="Hide name"
                                    extraDetail="Should the employer name be hidden on the banner?"
                                    name="hideNameOnPage"
                                    initialValue={data.hideNameOnPage}
                                    errors={
                                        errors.HideNameOnPage ||
                                        (formState.touched.hideNameOnPage && formState.errors.hideNameOnPage && [formState.errors.hideNameOnPage])
                                    }
                                    displayValueAs={<span>{data.hideNameOnPage ? 'Yes' : 'No'}</span>}
                                    fieldType="checkbox"
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    handleSubmit={() => updateForm(formState.values)}
                                    invalid={!!formState.errors.hideNameOnPage}
                                />
                                <Field
                                    label="Url"
                                    name="slug"
                                    initialValue={data.slug}
                                    includeBefore={<Url>{config.website}for-women/employer/</Url>}
                                    required
                                    errors={errors.Slug || (formState.touched.slug && formState.errors.slug && [formState.errors.slug])}
                                    fieldType="text"
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    handleSubmit={() => handleSubmit(formState.values)}
                                    invalid={!!formState.errors.slug}
                                />
                                <Field
                                    label="Status"
                                    name="status"
                                    initialValue={data.status}
                                    displayValueAs={<span>{getStatusName(data.status)}</span>}
                                    initialRenderType={'value'}
                                    isTogglingEnabled={false}
                                    hideOptionalLabel={true}
                                />
                                <Field
                                    label="Hide benefits on site"
                                    name="hideBenefitsOnSite"
                                    extraDetail="The Benefits tab will be hidden on the Employer page."
                                    initialValue={data.hideBenefitsOnSite}
                                    displayValueAs={<span>{data.hideBenefitsOnSite ? 'Yes' : 'No'}</span>}
                                    fieldType="checkbox"
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    handleSubmit={() => updateForm(formState.values)}
                                    invalid={!!formState.errors.hideBenefitsOnSite}
                                />
                                <Field
                                    label="Hide jobs onsite"
                                    name="hideJobsOnsite"
                                    extraDetail="The Jobs tab will be hidden on the Employer page, but individual jobs will still come up in search results."
                                    initialValue={data.hideJobsOnsite}
                                    displayValueAs={<span>{data.hideJobsOnsite ? 'Yes' : 'No'}</span>}
                                    fieldType="checkbox"
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    handleSubmit={() => updateForm(formState.values)}
                                    invalid={!!formState.errors.hideJobsOnsite}
                                />
                                <Field
                                    label="Website URL"
                                    name="websiteUrl"
                                    initialValue={data.websiteUrl}
                                    errors={errors.WebsiteUrl || (formState.touched.websiteUrl && formState.errors.websiteUrl && [formState.errors.websiteUrl])}
                                    fieldType="text"
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    validate={validateUrl}
                                    handleSubmit={() => updateForm(formState.values)}
                                    invalid={!!formState.errors.websiteUrl}
                                />
                                <Field
                                    label="Careers Page URL"
                                    name="careersPageUrl"
                                    initialValue={data.careersPageUrl}
                                    errors={
                                        errors.careersPageUrl ||
                                        (formState.touched.careersPageUrl && formState.errors.careersPageUrl && [formState.errors.careersPageUrl])
                                    }
                                    fieldType="text"
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    validate={validateUrl}
                                    handleSubmit={() => updateForm(formState.values)}
                                    invalid={!!formState.errors.careersPageUrl}
                                />
                                <Field
                                    label="Office Locations"
                                    name="officeLocations"
                                    initialValue={data.officeLocations ? data.officeLocations : []}
                                    errors={
                                        errors.OfficeLocations ||
                                        (formState.touched.officeLocations && formState.errors.officeLocations && [formState.errors.officeLocations])
                                    }
                                    displayValueAs={
                                        <span>{!!data.officeLocations && !!data.officeLocations.length ? data.officeLocations.join(', ') : 'Choose locations'}</span>
                                    }
                                    fieldType="tag"
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    handleSubmit={() => updateForm(formState.values)}
                                    invalid={!!formState.officeLocations}
                                />
                                <Field
                                    label="Industry"
                                    name="industryId"
                                    initialValue={data.industryId}
                                    displayNameAsLabel
                                    errors={errors.industryId || (formState.touched.industryId && formState.errors.industryId && [formState.errors.industryId])}
                                    fieldType="select"
                                    options={industryList ? industryList : []}
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    handleSubmit={() => updateForm(formState.values)}
                                    invalid={!!formState.errors.industryId}
                                />
                                <Field
                                    label="Year Founded"
                                    name="yearFounded"
                                    initialValue={data.yearFounded}
                                    errors={errors.YearFounded || (formState.touched.yearFounded && formState.errors.yearFounded && [formState.errors.yearFounded])}
                                    fieldType="number"
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    handleSubmit={() => updateForm(formState.values)}
                                    invalid={!!formState.errors.yearFounded}
                                />
                                <Field
                                    label="Company Size"
                                    name="companySize"
                                    initialValue={data.companySize}
                                    errors={errors.CompanySize || (formState.touched.companySize && formState.errors.companySize && [formState.errors.companySize])}
                                    displayValueAs={<span>{data.companySize}</span>}
                                    fieldType="select"
                                    options={COMPANY_SIZES}
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    handleSubmit={() => updateForm(formState.values)}
                                    invalid={!!formState.errors.companySize}
                                />
                                <Field
                                    label="Company Size is Global"
                                    name="companySizeIsGlobal"
                                    initialValue={data.companySizeIsGlobal}
                                    displayValueAs={<span>{data.companySizeIsGlobal ? 'Yes' : 'No'}</span>}
                                    fieldType="checkbox"
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    handleSubmit={() => updateForm(formState.values)}
                                    invalid={!!formState.errors.companySizeIsGlobal}
                                />
                                <Field
                                    label="Headquarter Location"
                                    name="headquartersLocation"
                                    initialValue={data.headquartersLocation}
                                    errors={
                                        errors.HeadquartersLocation ||
                                        (formState.touched.headquartersLocation && formState.errors.headquartersLocation && [formState.errors.headquartersLocation])
                                    }
                                    fieldType="text"
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    handleSubmit={() => updateForm(formState.values)}
                                    invalid={!!formState.errors.headquartersLocation}
                                />
                                <Field
                                    label="Twitter URL"
                                    name="twitterUrl"
                                    initialValue={data.twitterUrl}
                                    errors={errors.TwitterUrl || (formState.touched.twitterUrl && formState.errors.twitterUrl && [formState.errors.twitterUrl])}
                                    fieldType="text"
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    validate={validateUrl}
                                    handleSubmit={() => updateForm(formState.values)}
                                    invalid={!!formState.errors.twitterUrl}
                                />
                                <Field
                                    label="Facebook URL"
                                    name="facebookUrl"
                                    initialValue={data.facebookUrl}
                                    errors={errors.FacebookUrl || (formState.touched.facebookUrl && formState.errors.facebookUrl && [formState.errors.facebookUrl])}
                                    fieldType="text"
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    validate={validateUrl}
                                    handleSubmit={() => updateForm(formState.values)}
                                    invalid={!!formState.errors.facebookUrl}
                                />
                                <Field
                                    label="YouTube URL"
                                    name="youTubeUrl"
                                    initialValue={data.youTubeUrl}
                                    errors={errors.YouTubeUrl || (formState.touched.youTubeUrl && formState.errors.youTubeUrl && [formState.errors.youTubeUrl])}
                                    fieldType="text"
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    validate={validateUrl}
                                    handleSubmit={() => updateForm(formState.values)}
                                    invalid={!!formState.errors.youTubeUrl}
                                />
                                <Field
                                    label="LinkedIn URL"
                                    name="linkedInUrl"
                                    initialValue={data.linkedInUrl}
                                    errors={errors.LinkedInUrl || (formState.touched.linkedInUrl && formState.errors.linkedInUrl && [formState.errors.linkedInUrl])}
                                    fieldType="text"
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    validate={validateUrl}
                                    handleSubmit={() => updateForm(formState.values)}
                                    invalid={!!formState.errors.linkedInUrl}
                                />
                                <ImageUpload
                                    handleFileUpload={handleImageFileUpload('logoUploadInfo')}
                                    currentImage={data.logo}
                                    uploadedFilename={files.logoUploadInfo ? files.logoUploadInfo.name : ''}
                                    labelText="Logo"
                                    uploadButtonText="Choose logo"
                                    editButtonText="Edit logo"
                                    dialogTitle="Edit logo"
                                    dialogConfirmLabel="Save logo"
                                    previewImageAlt="Logo"
                                    showPreview
                                />
                                <ImageUpload
                                    handleFileUpload={handleImageFileUpload('shareImageUploadInfo')}
                                    handleFileRemove={handleRemoveEmployerImageProps('shareImageUploadInfo', 'shareImage')}
                                    currentImage={data.shareImage}
                                    uploadedFilename={files.shareImageUploadInfo ? files.shareImageUploadInfo.name : ''}
                                    labelText="Share image"
                                    uploadButtonText="Choose share image"
                                    editButtonText="Edit share image"
                                    dialogTitle="Edit logo"
                                    dialogConfirmLabel="Save share image"
                                    previewImageAlt="Share image"
                                    showPreview
                                />
                                <Field
                                    label="Hide From Digital Display Ad Feeds"
                                    name="hideFromDigitalDisplayFeeds"
                                    initialValue={data.hideFromDigitalDisplayFeeds}
                                    displayValueAs={<span>{data.hideFromDigitalDisplayFeeds ? 'Yes' : 'No'}</span>}
                                    fieldType="checkbox"
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    handleSubmit={() => updateForm(formState.values)}
                                    invalid={!!formState.errors.hideFromDigitalDisplayFeeds}
                                    hideOptionalLabel
                                    extraDetail="Removes Employers Jobs from DDA job feeds (ADMIN ONLY)"
                                />
                            </Panel>
                            {!isExisting && (
                                <Button type="submit" disabled={formState.invalid} theme="success" style={{ marginTop: '1rem' }}>
                                    Create employer
                                </Button>
                            )}
                        </Fragment>
                    )}
                </Form>
            )}

            {isExisting && (
                <EmployerPage
                    slug={data.slug}
                    employerId={data.id}
                    handleHeroImageUpload={handleImageFileUpload('heroImageUploadInfo')}
                    handleAboutImageUpload={handleImageFileUpload('aboutImageUploadInfo')}
                    handleJobHeroImageUpload={handleImageFileUpload('jobHeroImageUploadInfo')}
                    handleRemoveHeroImageProps={handleRemoveEmployerImageProps('heroImageUploadInfo', 'heroImage')}
                    handleRemoveAboutImageProps={handleRemoveEmployerImageProps('aboutImageUploadInfo', 'aboutImage')}
                    handleRemoveJobHeroImageProps={handleRemoveEmployerImageProps('jobHeroImageUploadInfo', 'jobHeroImage')}
                    data={data}
                    userName={userName}
                />
            )}

            {isExisting && (
                <Panel title="Employer Features">
                    <EmployerFeatures employerId={data.id} userName={userName} />
                </Panel>
            )}

            {isExisting && <EmployerPerks employerId={data.id} employerPerksEnabled={employerPerksEnabled} />}

            {isExisting && <EmployerJobAutomationInformation slug={data.slug} apiToken={data.token} />}

            {isExisting && <EmployerSelfService slug={data.slug} />}

            {isExisting && (
                <Panel title="Last Updated Information">
                    <Label label="Updated">
                        <Date date={data.updated} />
                    </Label>
                    <Label label="Updated By">{data.updatedBy}</Label>
                </Panel>
            )}

            {isExisting && data.employerChangeAudit && (
                <Panel title="Page Status History">
                    <ul>
                        {data.employerChangeAudit.map((change, index) => {
                            return (
                                <li key={index}>
                                    <strong>
                                        <Date date={change.changedDateTime} />
                                    </strong>{' '}
                                    {change.affectedProperty} changed from <em>{change.originalValue}</em> to <em>{change.newValue}</em> by {change.changedBy}
                                </li>
                            );
                        })}
                    </ul>
                </Panel>
            )}
        </Fragment>
    );
}

const Url = styled.span`
    opacity: 0.5;
    margin-right: -0.5rem;
`;

const ButtonRow = styled.div`
    a:first-child {
        margin-right: 0.5rem;
    }
`;

export default ViewEditAdd(Employer)({ domain: 'Employer' });
