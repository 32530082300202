import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Tbody } from '../../molecules';
import { Button, Date, Td, Th } from '../../atoms';
import { truncatedStyles } from '../../styles';

const EmployerJobTable = ({ results, ...rest }) => {
    return (
        <Container {...rest}>
            <Table>
                <thead>
                    <tr>
                        <Th colSpan="2">Employer</Th>
                        <Th>Job</Th>
                        <Th>Added</Th>
                        <Th colSpan="2">Updated</Th>
                    </tr>
                </thead>
                <Tbody>
                    {results.map(job => {
                        const jobUrl = `/jobs/${job.id}`;
                        const employerUrl = job.employer && job.employer.id && `/employers/${job.employer.id}`;
                        return (
                            <tr key={job.id}>
                                <Td to={employerUrl}>{job.employer && job.employer.name}</Td>
                                <Td to={employerUrl} tabIndex="-1">
                                    {job.employer && job.employer.logo && (
                                        <Logo>
                                            <img src={job.employer.logo.split(' ').join('%20')} alt={`${job.employer.name} logo`} />
                                        </Logo>
                                    )}
                                </Td>
                                <Td to={jobUrl} primary="true">
                                    {job.title}
                                    {!!job.reference && <Smaller>Reference Number {job.reference}</Smaller>}
                                </Td>
                                <Td to={jobUrl}>
                                    <Date date={job.added} />
                                </Td>
                                <Td to={jobUrl}>
                                    <Date date={job.updated} />
                                </Td>
                                <BtnCell>
                                    <Button to={jobUrl} size="mini" filled="border">
                                        Edit
                                    </Button>
                                </BtnCell>
                            </tr>
                        );
                    })}
                </Tbody>
            </Table>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const Logo = styled.div`
    width: 4rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 4rem;
        max-height: 3rem;
    }
`;

const BtnCell = styled(Td)`
    width: 5rem;

    > * {
        padding: 0 1rem;
    }
`;

const Smaller = styled.div`
    ${truncatedStyles};
    font-size: 0.75rem;
    font-weight: 500;
    color: #999999;
    max-width: 14rem;
`;

EmployerJobTable.propTypes = {
    results: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            reference: PropTypes.string,
            added: PropTypes.date,
            updated: PropTypes.date,
            employer: PropTypes.shape({
                name: PropTypes.string,
                id: PropTypes.string,
                logo: PropTypes.string
            })
        })
    ).isRequired
};

export default EmployerJobTable;
