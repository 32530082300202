import React, { ChangeEvent, Dispatch, FC, Fragment, SetStateAction, useContext, useEffect, useState } from 'react';
import { ErrorView } from '../index';
import { Errors } from '../../types/types';
import { InlinePageTitle, Panel } from '../../molecules';
import { Button, Date, ErrorMessage, Field, Icon, Label, Spinner, SuccessMessage } from '../../atoms';
import { ApiClientContext } from '../../services';
import { useData } from '../../hooks';
import { useHistory } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import { Form, RadioGroup, Radio, useFormState } from 'informed';

interface EmployerPerkPage {
    errors?: Errors;
    computedMatch: any;
}

interface EmployerPerkProps {
    id: string | undefined;
    employerId: string;
    order: string | null;
    setErrors?: Dispatch<SetStateAction<Errors | undefined>>;
    errors?: Errors;
}

type EmployerPerksModel = {
    id?: string;
    employerId: string;
    title: string;
    summary: string;
    icon: EmployerPerksIconModel;
    order: number;
    modifiedBy: string;
    modifiedAt: Date;
    totalPerksForEmployer?: number;
};

type EmployerPerksIconModel = {
    id: string;
    name: string;
    src: string;
    alt: string;
};

type EmployerPerkForm = {
    employerId: string;
    title: string;
    summary: string;
    icon: EmployerPerksIconModel;
    order: number;
    modifiedBy: string;
};

const EmployerPerkPage: FC<EmployerPerkPage> = ({ errors: initialErrors, ...props }) => {
    const {
        computedMatch: {
            params: { perkId, id: employerId }
        }
    } = props;
    const params = new URLSearchParams(location.search);
    const order = params.get('order');
    const [errors, setErrors] = useState(initialErrors);
    return (
        <>
            <ErrorView errors={errors} />
            <EmployerPerkViewEdit id={perkId} employerId={employerId} setErrors={setErrors} errors={errors} order={order} />
        </>
    );
};

const EmployerPerkViewEdit: FC<EmployerPerkProps> = ({ id, employerId, setErrors, errors, order }) => {
    const { apiClient } = useContext(ApiClientContext);
    const history = useHistory();
    const {
        user: { name: userName }
    } = useAuth0();

    const { data: initialData, isLoading, error } = useData(apiClient.getEmployerPerk, id, {}, () => id !== undefined);
    const [employerPerk, setEmployerPerk] = useState<EmployerPerksModel>(initialData);
    const [isExisting, setIsExisting] = useState(id !== undefined);
    const [apiDeleteError, setApiDeleteError] = useState<{ message: string } | null>(null);
    const [deleteIsLoading, setDeleteIsLoading] = useState(false);

    const [isSaved, setIsSaved] = useState(false);
    const [apiSaveError, setApiSaveError] = useState<{ message: string } | null>(null);
    const [saveIsLoading, setSaveIsLoading] = useState(false);

    const [openIconSelection, setOpenIconSelection] = useState(!isExisting);
    const [summaryIsValid, setSummaryIsValid] = useState(true);

    const formState = useFormState<EmployerPerkForm>();

    function toggleIconSelection() {
        setOpenIconSelection(prevState => !prevState);
    }

    function redirectToEmployerPage() {
        history.push(`/employers/${employerId}`);
    }

    function deleteEmployerPerk() {
        useData.apiCall(apiClient.deleteEmployerPerk, () => redirectToEmployerPage, setApiDeleteError, setDeleteIsLoading)(employerPerk.id, `"${userName}"`);
    }

    function updateAfterSave(data: EmployerPerksModel) {
        setEmployerPerk(data);
        setIsSaved(true);
        setIsExisting(true);
    }

    function handleSubmit() {
        const updatedValues: EmployerPerksModel = id
            ? { ...employerPerk, id: id, modifiedBy: userName.toString(), order: employerPerk.order }
            : { ...employerPerk, modifiedBy: userName.toString(), order: order !== null ? parseInt(order) : 0, employerId };

        useData.apiCall(apiClient.saveEmployerPerk, updateAfterSave, setApiSaveError, setSaveIsLoading)(updatedValues);
    }

    function setFormValue(name: string, value: string) {
        setIsSaved(false);
        setEmployerPerk(prevState => ({ ...prevState, [name]: value }));

        if (name === 'summary') {
            setSummaryIsValid(!!value && value.length <= 45);
        }
    }

    const setIconSelection = (availableIcons: EmployerPerksIconModel[]) => (id: string) => {
        setIsSaved(false);
        const selectedIcon = availableIcons.find(i => i.id === id);
        if (selectedIcon) setEmployerPerk(prevState => ({ ...prevState, icon: selectedIcon }));
    };

    useEffect(() => {
        setEmployerPerk(initialData);
        setErrors && setErrors(error as Errors);
    }, [initialData, setEmployerPerk, error, setErrors]);

    useEffect(() => {
        setOpenIconSelection(!isExisting);
    }, [isExisting]);

    return (
        <>
            <InlinePageTitle title="Employer Perk">
                {isLoading && (
                    <>
                        <Spinner /> Loading the employer perk{' '}
                    </>
                )}
                <Button filled="border" to={`/employers/${employerId}`} style={{ marginRight: '10px' }}>
                    View employer
                </Button>
                {isExisting && (
                    <>
                        <Button onClick={deleteEmployerPerk}>
                            {deleteIsLoading ? (
                                <>
                                    <Spinner /> Deleting
                                </>
                            ) : (
                                <>
                                    <StyledPerkIcon icon={'TrashAlt'} /> Delete
                                </>
                            )}
                        </Button>
                        {apiDeleteError && <ErrorMessage errors={[apiDeleteError.message]} />}
                    </>
                )}
            </InlinePageTitle>
            <Form onSubmit={handleSubmit}>
                <Fragment>
                    {isExisting && (
                        <Field
                            name="title"
                            initialValue={employerPerk.title}
                            required
                            errors={
                                (errors && errors.Title) ||
                                (formState.touched && formState.touched.title && formState.errors && formState.errors.title && [formState.errors.title])
                            }
                            displayValueAs={<h2>{employerPerk.title}</h2>}
                            fieldType="text"
                            initialRenderType={isExisting ? 'value' : 'field'}
                            isTogglingEnabled={isExisting}
                            onValueChange={(newValue: string) => setFormValue('title', newValue)}
                        />
                    )}
                    <Panel title="Perk Details">
                        <Fragment>
                            {!isExisting && (
                                <Field
                                    label="Title"
                                    name="title"
                                    initialValue={employerPerk.title}
                                    required
                                    errors={
                                        (errors && errors.Title) ||
                                        (formState.touched && formState.touched.title && formState.errors && formState.errors.title && [formState.errors.title])
                                    }
                                    fieldType="text"
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    onValueChange={(newValue: string) => setFormValue('title', newValue)}
                                    extraDetail={
                                        <span>
                                            <Icon icon={'InfoCircle'} /> This field is to help identify employer perks in portal
                                        </span>
                                    }
                                />
                            )}
                            <Label label="Icon">
                                <IconContainer>
                                    {employerPerk.icon && (
                                        <>
                                            <img src={employerPerk.icon.src} width={'150px'} height={'150px'} alt={employerPerk.icon.alt} />
                                            <Button filled={'border'} style={{ marginTop: '1rem', width: '160px', marginBottom: '1rem' }} onClick={toggleIconSelection}>
                                                {openIconSelection ? 'Close options' : 'Change icon'}
                                            </Button>
                                        </>
                                    )}
                                    {openIconSelection && <IconSelection initialIcon={employerPerk.icon} setIconSelection={setIconSelection} />}
                                </IconContainer>
                            </Label>
                            <Field
                                label="Summary"
                                name="summary"
                                initialValue={employerPerk.summary}
                                required
                                errors={
                                    (errors && errors.Title) ||
                                    (formState.touched && formState.touched.summary && formState.errors && formState.errors.summary && [formState.errors.summary])
                                }
                                fieldType="text"
                                initialRenderType={isExisting ? 'value' : 'field'}
                                isTogglingEnabled={isExisting}
                                onValueChange={(newValue: string) => setFormValue('summary', newValue)}
                                extraDetail={
                                    <span>
                                        <Icon icon={'InfoCircle'} /> This text will be visible on the job site
                                    </span>
                                }
                            />
                            {!summaryIsValid && <StyledError errors={['The summary can only be 45 characters, please shorten']} />}
                            {isExisting && (
                                <>
                                    <Label label="Modified by">
                                        <span>{employerPerk.modifiedBy}</span>
                                    </Label>
                                    <Label label="Modified">
                                        <Date disabled date={employerPerk.modifiedAt} />
                                    </Label>
                                </>
                            )}
                        </Fragment>
                    </Panel>
                    <SubmitAndAddAnotherButtons
                        employerId={employerId}
                        isExisting={isExisting}
                        disabled={formState.invalid || !summaryIsValid}
                        handleSubmit={handleSubmit}
                        isApiLoading={saveIsLoading}
                        isSaved={isSaved}
                        totalPerksForEmployer={employerPerk.totalPerksForEmployer}
                    />
                    {apiSaveError && <ErrorMessage errors={[apiSaveError.message]} />}
                </Fragment>
            </Form>
        </>
    );
};

const StyledError = styled(ErrorMessage)`
    color: #eb0000;
`;

const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

type SubmitButtonProps = {
    isExisting: boolean;
    disabled: boolean;
    handleSubmit: () => void;
    isApiLoading: boolean;
    isSaved: boolean;
    employerId: string;
    totalPerksForEmployer?: number;
};

const SubmitAndAddAnotherButtons: React.FC<SubmitButtonProps> = ({ isExisting, disabled, handleSubmit, isApiLoading, isSaved, totalPerksForEmployer, employerId }) => {
    const [showPerkLimitReachedMessage, setShowPerkLimitReachedMessage] = useState(false);
    const history = useHistory();

    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null;

        if (showPerkLimitReachedMessage) {
            timeout = setTimeout(() => {
                setShowPerkLimitReachedMessage(false);
            }, 5000);
        }
        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [showPerkLimitReachedMessage]);

    function addAnotherPerk(e: any, totalPerksForEmployer: number) {
        e.preventDefault();
        if (totalPerksForEmployer >= 6) return setShowPerkLimitReachedMessage(true);
        else {
            history.push(`/employers/${employerId}/employer-perk/new?order=${totalPerksForEmployer}`);
        }
    }

    const text = isExisting
        ? { button: 'Save perk', successMessage: 'Employer perk has been saved' }
        : { button: 'Create perk', successMessage: 'New employer perk has been created' };

    return (
        <>
            <ButtonRow>
                <Button type="submit" disabled={disabled} theme="success" style={{ marginTop: '1rem' }} handleSubmit={handleSubmit}>
                    {text.button} {isApiLoading && <Spinner />}
                </Button>
                {(isExisting || isSaved) && totalPerksForEmployer && (
                    <Button filled="border" onClick={(e: any) => addAnotherPerk(e, totalPerksForEmployer)} style={{ marginLeft: '10px', marginTop: '1rem' }}>
                        Create another perk
                    </Button>
                )}
            </ButtonRow>
            {isSaved && <SuccessMessage message={text.successMessage} />}
            {showPerkLimitReachedMessage && <ErrorMessage errors={['Only 6 perks can be added, please remove one to add more.']} />}
        </>
    );
};

const StyledPerkIcon = styled(Icon)`
    margin-right: 8px;
`;

interface IconSelectionProps {
    initialIcon: EmployerPerksIconModel;
    setIconSelection: (availableIcons: EmployerPerksIconModel[]) => (id: string) => void;
}

const IconSelection: FC<IconSelectionProps> = ({ initialIcon, setIconSelection }) => {
    const { apiClient } = useContext(ApiClientContext);
    const { data, isLoading, error } = useData(apiClient.getEmployerPerkIcons);
    const [icons, setIcons] = useState<EmployerPerksIconModel[]>([]);

    useEffect(() => {
        setIcons(data);
    }, [data]);

    if (isLoading)
        return (
            <div>
                <Spinner /> Loading icons{' '}
            </div>
        );
    if (error)
        return (
            <div>
                <ErrorMessage errors={['There was a problem loading the employer perk icons, please try again later.']} />{' '}
            </div>
        );
    return (
        <div>
            Please choose an icon;
            <IconSelectionContainer>
                <RadioGroup initialValue={initialIcon} field={'icon'}>
                    {icons.map(icon => {
                        return (
                            <IconSelectionLabel key={icon.id} htmlFor={`${icon}-${icon.id}`}>
                                <Radio
                                    value={icon.id}
                                    id={`${name}-${icon.id}`}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        const value = event.target.value;
                                        setIconSelection(icons)(value);
                                    }}
                                />
                                <img src={icon.src} alt={icon.alt} width={'60px'} height={'60px'} />
                                <IconName>{icon.name}</IconName>
                            </IconSelectionLabel>
                        );
                    })}
                </RadioGroup>
            </IconSelectionContainer>
        </div>
    );
};

const IconSelectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const IconName = styled.span`
    width: 20px;
`;

const IconSelectionLabel = styled.label`
    font-family: 'Open Sans';
    min-width: 50%;
    font-size: 0.875rem;
    display: flex;
    align-items: start;
    position: relative;
    color: #000000;
    display: flex;
    align-items: center;
    margin-top: 8px;

    img {
        margin: 0 8px;
    }

    input[type='radio'] {
        &:focus + div::before {
            content: '';
        }

        &:checked + div::after {
            content: '';
        }
    }
`;

export default EmployerPerkPage;
