import { useEffect } from 'react';

const useScriptSnippet = snippet => {
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.innerHTML = snippet;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [snippet]);
};

const useScriptSrc = url => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url]);
};

export { useScriptSnippet, useScriptSrc };
