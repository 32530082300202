import React, { Component, Fragment } from 'react';

import { ViewAll } from '../../hocs';
import { EmployersTable, InlinePageTitle, SearchPaginationRow } from '../../molecules';
import { Button, EmptyState } from '../../atoms';

class Employers extends Component {
    render() {
        const { data, history, location, handleGetAll } = this.props;
        const { results, ...dataLessResults } = data;

        return (
            <Fragment>
                <InlinePageTitle title="Employers">
                    <Button to="/employers/add" icon="Plus" aria-label="Add new">
                        New
                    </Button>
                </InlinePageTitle>
                {data && data.total ? (
                    <Fragment>
                        <SearchPaginationRow {...dataLessResults} currentResults={results.length} history={history} location={location} handlePagination={handleGetAll} />
                        <EmployersTable results={results} />
                    </Fragment>
                ) : (
                    <EmptyState screen="Employers" />
                )}
            </Fragment>
        );
    }
}

export default ViewAll(Employers)({ domain: 'Employers' });
