import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text, ArrayField } from 'informed';

import Icon from '../Icon';

const TextInputGroup = field => {
    const handleAdd = useCallback((field, add) => {
        if (field.disabled) {
            return null;
        }
        add();
        field.onChange();
    }, []);

    const handleRemove = useCallback((field, remove) => {
        if (field.disabled) {
            return null;
        }
        remove();
        field.onChange();
    }, []);

    return (
        <>
            <ArrayField field={field.name} initialValue={field.initialValue}>
                {({ add, fields }) => {
                    if (fields.length === 0) {
                        add();
                    }
                    return (
                        <>
                            {fields.map(({ field: name, initialValue, key, remove }) => {
                                return (
                                    <Wrapper key={key}>
                                        <Input field={name} initialValue={initialValue} onBlur={field.onChange} disabled={field.disabled} />
                                        <RemoveButton onClick={() => handleRemove(field, remove)}>
                                            <Icon icon="MinusCircle" />
                                        </RemoveButton>
                                        {field.maxItems > 0 && fields.length < field.maxItems && (
                                            <AddButton onClick={() => handleAdd(field, add)}>
                                                <Icon icon="PlusCircle" />
                                            </AddButton>
                                        )}
                                    </Wrapper>
                                );
                            })}
                            {(!fields || fields.length === 0) && (
                                <AddButton onClick={() => handleAdd(field, add)}>
                                    <Icon icon="PlusCircle" />
                                </AddButton>
                            )}
                        </>
                    );
                }}
            </ArrayField>
        </>
    );
};

const Wrapper = styled.label`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
`;

const Input = styled(Text)`
    font-family: 'Open Sans';
    padding: 0.6875rem 1rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    width: 100%;
    color: ${props => (props.disabled ? '#bcbcbc' : '#000000')};
    background-color: ${props => (props.disabled ? '#F1F1F1' : '#FFFFFF')};
    cursor: ${props => (props.disabled ? 'default' : 'auto')}
    height: ${props => `calc(1.375rem + ${1.125 * props.rows}rem)`};
    ${props => props.errors && props.errors.length && 'border-color: #000000'};

    &:focus {
        border-color: #ffc845;
        outline: none;
    }
`;

const RemoveButton = styled.a`
    margin: 0 5px;

    &:hover {
        cursor: pointer;
    }
`;

const AddButton = styled.a`
    margin: 0 5px;

    &:hover {
        cursor: pointer;
    }
`;

TextInputGroup.defaultProps = {
    maxItems: 0
};

TextInputGroup.propTypes = {
    field: PropTypes.any,
    maxItems: PropTypes.number
};

export default TextInputGroup;
