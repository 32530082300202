import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Scope } from 'informed';

import { Field } from '../../atoms';
import { combineErrors, validateUrl } from '../../helpers';
import styled, { css } from 'styled-components';

const EndorsedEmployerContentBlockFields = ({ errors, formState, isSubmitting }) => {
    const formErrors = {};
    const contentTouched = formState.touched.content || {};
    const contentErrors = formState.errors.content;
    if (contentErrors) {
        formErrors.name = combineErrors(errors.name, contentErrors.name, contentTouched.name);
        formErrors.url = combineErrors(errors.url, contentErrors.url, contentTouched.url);
    }

    return (
        <Fragment>
            <Scope scope="content">
                <Field
                    label="Name"
                    name="name"
                    required
                    disabled={isSubmitting}
                    errors={formErrors.name}
                    fieldType="text"
                    extraDetail="Name is an internal reference. It is not displayed on the employer page."
                />
                <Field label="Url" name="url" disabled={isSubmitting} validate={validateUrl} errors={formErrors.url} fieldType="text" />
            </Scope>
            <ImagePreview previewShape="circle">
                <img src="https://s.work180.co/assets/content/W180-endorsement.png" alt="endorsed-employer-badge" />
            </ImagePreview>
        </Fragment>
    );
};

EndorsedEmployerContentBlockFields.propTypes = {
    formState: PropTypes.object.isRequired,
    errors: PropTypes.object,
    isSubmitting: PropTypes.bool
};

EndorsedEmployerContentBlockFields.defaultProps = {
    isSubmitting: false,
    errors: {}
};

const ImagePreview = styled.div`
    max-height: 12rem;
    margin-bottom: 1rem;
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    box-sizing: border-box;
    cursor: pointer;

    img {
        object-fit: contain;
        max-width: 20rem;
        max-height: 9rem;
        ${// eslint-disable-next-line prettier/prettier
    props => props.previewShape === 'circle' &&
            css`
                border-radius: 50%;
            `}
    }
`;

export default EndorsedEmployerContentBlockFields;
