import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '../../atoms';

type PanelProps = {
    title: string;
    primary?: boolean;
    isCollapsible?: boolean;
    isCollapsed?: boolean;
    handleToggle?: (id: string | undefined) => void;
    id?: string;
};

const Panel: React.FunctionComponent<PanelProps> = ({ id, title, children, primary = true, isCollapsible = false, isCollapsed, handleToggle, ...rest }) => {
    return (
        <Wrapper primary={primary} isCollapsible={isCollapsible} {...rest}>
            {!!title && (
                <Header onClick={isCollapsible && handleToggle ? () => handleToggle(id) : undefined}>
                    <H3>{title}</H3>
                    {isCollapsible && <Icon icon="ChevronDown" />}
                </Header>
            )}
            {!!children && <Body isCollapsed={isCollapsed}>{children}</Body>}
        </Wrapper>
    );
};

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem;

    @media (min-width: 48rem) {
        padding: 0.75rem 1.5rem;
    }
`;

const H3 = styled.h3`
    margin: 0;
    margin-right: 1rem;
`;

const Body = styled.div<Partial<PanelProps>>`
    padding: 0.75rem;
    border: 0.0625rem solid #f1f1f1;

    ${props => props.isCollapsed && 'display: none'};

    @media (min-width: 48rem) {
        padding: 0.75rem 1.5rem;
    }
`;

const isCollapsibleStyles = css`
    ${Header} {
        cursor: pointer;
        transition: 0.3s ease;
    }

    &:hover {
        ${Header} {
            opacity: 0.85;
            transition: 0.3s ease;
        }
    }
`;

const primaryStyles = css`
    margin: 2rem 0;

    ${Header} {
        background-color: #f1f1f1;
        color: #000000;
        border: 0.0625rem solid #f1f1f1;
    }

    ${H3} {
        font-size: 1.125rem;
        font-weight: 600;
    }
`;

const secondaryStyles = css`
    margin: 0.25rem 0;

    ${Header} {
        background-color: #555555;
        color: #ffffff;
        border: 0.0625rem solid #555555;
    }

    ${H3} {
        font-size: 1rem;
        font-weight: 500;
    }
`;

const Wrapper = styled.div<Partial<PanelProps>>`
    ${props => (props.primary ? primaryStyles : secondaryStyles)};
    ${props => props.isCollapsible && isCollapsibleStyles};
    border-radius: 0.125rem;
`;

export default Panel;
