import React, { Fragment, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { ViewAll } from '../../hocs';
import { InlinePageTitle, SearchPaginationRow, JobAnalyticsTable } from '../../molecules';
import { Button, Date, EmptyState, Link, Spinner } from '../../atoms';
import { ApiClientContext } from '../../services';
import { getQueryString } from '../../helpers';
import { useAuthorization } from '../../auth';

const JobAnalytics = props => {
    let timer = 0;

    const [isDownloading, setIsDownloading] = useState(false);
    const [fileId, setFileId] = useState(null);
    const { isClient } = useAuthorization();
    const { apiClient } = useContext(ApiClientContext);

    useEffect(() => {
        const timerTick = () => {
            if (fileId) {
                apiClient.pingReport(fileId).then(
                    () => {
                        setIsDownloading(false);
                        window.location.href = apiClient.getDownloadUrl(fileId);
                    },
                    () => {
                        timer = setTimeout(timerTick, 3000);
                    }
                );
            }
        };

        if (fileId) {
            timer = setTimeout(timerTick, 3000);
        }

        return () => clearTimeout(timer);
    }, [fileId]);

    const downloadCsv = () => {
        const { search } = props.location;
        const queryStr = getQueryString(search);

        setIsDownloading(true);
        apiClient.generateAnalytics(queryStr).then(({ data }) => {
            setFileId(data.fileId);
        });
    };

    const { data, history, location, handleGetAll } = props;
    const { results, ...dataLessResults } = data;
    const params = new URLSearchParams(location.search);
    const startDate = params.get('startDate');
    const endDate = params.get('endDate');
    const hasDateRange = startDate && moment(startDate).isValid() && endDate && moment(endDate).isValid();

    return (
        <Fragment>
            <InlinePageTitle title="Job analytics">
                {isDownloading ? (
                    <Spinner />
                ) : (
                    <Button onClick={downloadCsv} icon="Download" aria-label="download csv">
                        Download .csv
                    </Button>
                )}
            </InlinePageTitle>
            <p>
                Want other statistics? Let us know what stats you would like to see. Email{' '}
                <Link isExternal="true" to="mailto:feedback@work180.co">
                    feedback@work180.co
                </Link>
            </p>
            {data && data.total ? (
                <Fragment>
                    <SearchPaginationRow
                        {...dataLessResults}
                        currentResults={results.length}
                        history={history}
                        location={location}
                        handlePagination={handleGetAll}
                        keywordLabelAs="Job title or reference number"
                        includeEmployerSearch={!isClient}
                        includeDateRange
                        includeFeaturedSearch={false}
                        renderResultsInfo={() => {
                            if (hasDateRange) {
                                return (
                                    <p>
                                        Showing results from <Date date={startDate} /> to <Date date={endDate} />
                                    </p>
                                );
                            }

                            return <p>Showing results for the last 30 days.</p>;
                        }}
                    />
                    <JobAnalyticsTable results={results} isClient={isClient} />
                </Fragment>
            ) : (
                <EmptyState screen="JobAnalytics" />
            )}
        </Fragment>
    );
};

export default ViewAll(JobAnalytics)({ domain: 'JobAnalytics' });
