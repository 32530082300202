import React, { Component } from 'react';
import styled from 'styled-components';
import { Form } from 'informed';
import { InlinePageTitle, Panel } from '../../molecules';
import { TextEditorInput, Button, Icon } from '../../atoms';
import { GENDER_BIAS } from '../../constants';
import { throttle } from 'throttle-debounce';
import { ApiClientContext } from '../../services';

class JobAdAnalysis extends Component {
    state = {
        data: null
    };

    static contextType = ApiClientContext;

    handleSubmit = form => {
        this.throttledUpdate(form['htmlBody']);
    };

    throttledUpdate = throttle(2000, body => {
        this.context.apiClient.getJobAnalysis({ body }).then(({ data }) => {
            if (data) {
                this.setState({ data: data });
            }
        });
    });

    render() {
        const { data } = this.state;

        document.title = `JobAd180 | WORK180 Portal`;

        return (
            <>
                <InlinePageTitle title="JobAd180" />
                <p>JobAd180 analyses your ads and provides an appropriate score of strongly feminine, feminine, neutral, masculine or strongly masculine coded ads.</p>
                <p>By avoiding gender-biased language you will appeal to more candidates, increasing the number of women who choose your company through WORK180.</p>
                <ResponseWrapper>
                    <StyledForm onSubmit={this.handleSubmit}>
                        {({ formState }) => {
                            return (
                                <>
                                    <TextEditorInput name="htmlBody" label="Description" handleEditorChange={() => this.throttledUpdate(formState.values.htmlBody)} />

                                    <StyledButton type="submit" theme="success">
                                        {'Submit'}
                                    </StyledButton>
                                </>
                            );
                        }}
                    </StyledForm>

                    <GenderBiasResult>
                        {!!data && (
                            <Panel title="Gender bias result">
                                <h4>
                                    <StyledIcon icon={GENDER_BIAS[data.coding]} />
                                    {data.codingReadable}
                                </h4>
                                <p>{data.codingExplanation}</p>
                                <strong>Feminine words {data.feminineWordMatches.length > 0 ? '(' + data.feminineWordMatches.length + ')' : ''}</strong>
                                {data.feminineWordMatches.length === 0 && <p>No matches</p>}
                                {!!data.feminineWordMatches && (
                                    <ul>
                                        {data.feminineWordMatches.map((word, i) => {
                                            return <li key={i}>{word}</li>;
                                        })}
                                    </ul>
                                )}

                                <strong>Masculine words {data.masculineWordMatches.length > 0 ? '(' + data.masculineWordMatches.length + ')' : ''}</strong>
                                {data.masculineWordMatches.length === 0 && <p>No matches</p>}
                                {!!data.masculineWordMatches && (
                                    <ul>
                                        {data.masculineWordMatches.map((word, i) => {
                                            return <li key={i}>{word}</li>;
                                        })}
                                    </ul>
                                )}
                            </Panel>
                        )}
                    </GenderBiasResult>
                </ResponseWrapper>
            </>
        );
    }
}

const ResponseWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    font-size: 12px;
`;

const StyledForm = styled(Form)`
    width: 70%;
`;

const GenderBiasResult = styled.div`
    width: 30%;
    margin-left: 30px;
    margin-top: -30px;
`;

const StyledButton = styled(Button)`
    margin-top: 20px;
`;

const StyledIcon = styled(Icon)`
    margin-right: 10px;
`;

export default JobAdAnalysis;
