import React, { Component } from 'react';
import { asField } from 'informed';

import classnames from 'classnames';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import 'rc-time-picker/assets/index.css';
import TimePickerPanel from 'rc-time-picker/lib/Panel';
import { Icon } from '../../atoms';
import { getRegion } from '../../helpers';

import moment from 'moment';

// locale selector

import enUk from 'rc-calendar/lib/locale/en_GB';
import enUS from 'rc-calendar/lib/locale/en_US';
import Button from '../Button';

enUk.timeSelect = 'Select Time';

class DateTimePicker extends Component {
    handleChange = date => {
        const { fieldApi } = this.props;
        fieldApi.setValue(date);
    };
    handleOpen = open => {
        const { isTogglingEnabled } = this.props;

        if (isTogglingEnabled && !open) {
            this.props.onBlur();
        }
    };

    render() {
        const { fieldState, onBlur, onChange, ...rest } = this.props;
        const date = moment(fieldState.value);
        let locale = enUk;
        let format = 'DD/MM/YYYY HH:mm';
        if (getRegion() === 'us') {
            locale = enUS;
            format = 'MM/DD/YYYY HH:mm';
        }

        return (
            <DatePicker
                calendar={
                    <Calendar
                        locale={locale}
                        style={{ zIndex: 1000 }}
                        dateInputPlaceholder="Select Time and Date"
                        format={format}
                        onChange={onChange}
                        timePicker={<TimePickerPanel showSecond={false} />}
                    />
                }
                value={date}
                onOpenChange={this.handleOpen}
                onChange={this.handleChange}
                {...rest}
            >
                {() => {
                    return (
                        <Button className={classnames('form-control')}>
                            <Icon onBlur={onBlur} icon="Calendar" />
                        </Button>
                    );
                }}
            </DatePicker>
        );
    }
}

const AsDateTimePickerField = asField(DateTimePicker);

const DateTimePickerField = ({ name, ...props }) => {
    return <AsDateTimePickerField field={name} {...props} />;
};

export default DateTimePickerField;
