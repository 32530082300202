import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ApplicationsTable, InlinePageTitle, SearchPaginationRow } from '../../molecules';
import { Loading } from '../../atoms';
import { ErrorView } from '../index';
import { getQueryString } from '../../helpers';
import { ApiClientContext } from '../../services';
import { useData } from '../../hooks';
import Button from '../../atoms/Button';
import { useAuthorization } from '../../auth';

const Applications = props => {
    const { history, location } = props;
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [sortByKey, setSortByKey] = useState('added'); //  object property from api
    const [sortIsDescending, setSortIsDescending] = useState(true);
    const { isClient } = useAuthorization();
    const { apiClient } = useContext(ApiClientContext);
    const { getAllApplications } = apiClient;

    const currentSortConfig = useMemo(
        () => ({
            sortByKey,
            sortIsDescending
        }),
        [sortByKey, sortIsDescending]
    );

    const handleSort = ({ key, isDescendingInitially }) => {
        setSortByKey(key);
        if (key === sortByKey) {
            setSortIsDescending(current => !current);
        } else {
            setSortIsDescending(isDescendingInitially);
        }
    };

    const handleGetAll = useCallback(
        page => {
            const { search } = location;
            const queryStr = getQueryString(search);
            useData.apiCall(getAllApplications, setData, setErrors, setIsLoading)(page, queryStr, sortByKey, sortIsDescending);
        },
        [getAllApplications, location, sortByKey, sortIsDescending]
    );

    useEffect(() => {
        handleGetAll();
    }, [handleGetAll]);

    const { results, ...dataLessResults } = data;

    if (isLoading) {
        return <Loading />;
    }

    document.title = `Applications | WORK180 Portal`;
    return (
        <Fragment>
            <ErrorView errors={errors} />
            <InlinePageTitle title="Applications" />
            {data && data.total ? (
                <Fragment>
                    <SearchPaginationRow
                        {...dataLessResults}
                        currentResults={results.length}
                        history={history}
                        location={location}
                        handlePagination={handleGetAll}
                        includeDateRange
                        includeEmployerSearch={!isClient}
                        keywordLabelAs="Search by job title"
                        includeSecondarySearch
                        SecondaryLabelAs="Search by applicant name"
                    />
                    <ApplicationsTable results={results} handleSort={handleSort} currentSortConfig={currentSortConfig} />
                </Fragment>
            ) : (
                <>
                    <p>There are no applications that match the selected criteria</p>
                    <Button to="/applications/all">Reset Search</Button>
                </>
            )}
        </Fragment>
    );
};

export default Applications;
