import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '../Icon';

function ErrorWrapper({ children, error }) {
    if (!error) {
        return children;
    }

    let message;
    if (typeof error === 'string') {
        message = error;
    } else if (error.message) {
        message = error.message;
    }

    return (
        <Fragment>
            {!!message && (
                <ErrorMsg tabIndex={0}>
                    <Icon icon="ExclamationCircle" />
                    <p>{message}</p>
                </ErrorMsg>
            )}
            {children}
        </Fragment>
    );
}

ErrorWrapper.propTypes = {
    error: PropTypes.oneOf([PropTypes.string, PropTypes.shape({ message: PropTypes.string })]),
    children: PropTypes.node
};

const ErrorMsg = styled.div`
    display: flex;
    align-items: center;
    padding: 0.875rem 1rem;
    background-color: #333333;
    color: #ffffff;

    @media (min-width: 48rem) {
        padding: 0.875rem 2rem;
    }

    &:focus {
        outline: none;
        p {
            border-bottom: 1px solid #ffffff;
        }
    }

    svg {
        margin-right: 0.5rem;
        font-size: 1.5rem;
    }

    p {
        margin: 0;
    }
`;

export default ErrorWrapper;
