import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import logo from './logo-inverse.svg';
import { useRegion } from '../../hooks';

const Logo = () => {
    const { regionFromRoute } = useRegion();

    return (
        <StyledLink to={`/${regionFromRoute}`}>
            <img src={logo} alt="Work180 Logo" width="300" height="77" />
        </StyledLink>
    );
};

const StyledLink = styled(Link)`
    line-height: 1;

    &:focus {
        outline: 1px solid #fff;
        outline-offset: 5px;
    }

    img {
        width: 6.25rem;
        height: 1.625rem;
    }
`;

export default Logo;
