import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ErrorMessage, Wysiwyg } from '../../atoms';
import { ApiClientContext } from '../../services';

class TextEditorInput extends Component {
    static contextType = ApiClientContext;

    validate = (value = '') => {
        const { label } = this.props;
        return value.trim() ? undefined : `The ${label} field is required.`;
    };

    handleSubmit = () => {
        const { handleSubmit } = this.props;
        if (!handleSubmit) {
            return;
        }

        handleSubmit().catch(errors => {
            for (let err in errors) {
                console.error(`Error: ${errors[err].join(', ')}`);
            }
        });
    };

    handleFileUpload = (b64, name, type, file, successCb) => {
        return this.context.apiClient.uploadContentFile({ b64, name, type }).then(({ data: responseData }) => {
            if (successCb && typeof successCb === 'function') {
                successCb(responseData);
            }

            return responseData.url;
        });
    };

    render() {
        const { name, initialValue, errors, required, handleEditorChange, handleFileUpload, isExisting, ...rest } = this.props;
        const validationProps = required && {
            validate: this.validate,
            validateOnChange: true,
            validateOnMount: true
        };

        return (
            <Container>
                <Wysiwyg
                    field={name}
                    initialValue={initialValue}
                    errors={errors}
                    aria-invalid={!!errors && !!errors.length}
                    handleBlur={isExisting ? this.handleSubmit : undefined}
                    handleEditorChange={handleEditorChange}
                    handleFileUpload={handleFileUpload || this.handleFileUpload}
                    {...validationProps}
                    {...rest}
                />
                <ErrorMessage errors={errors} />
            </Container>
        );
    }
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

TextEditorInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    initialValue: PropTypes.string,
    handleSubmit: PropTypes.func,
    required: PropTypes.bool,
    isExisting: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
    handleEditorChange: PropTypes.func,
    handleFileUpload: PropTypes.func
};

export default TextEditorInput;
