import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Tbody } from '../../molecules';
import { Date, Td, Th } from '../../atoms';
import { truncatedStyles } from '../../styles';

const JobAnalyticsTable = ({ results, isClient, ...rest }) => {
    return (
        <Container {...rest}>
            <Table>
                <thead>
                    <tr>
                        <Th>Job</Th>
                        <Th>Company</Th>
                        {!isClient && <Th>Job ad views</Th>}
                        <Th>Apply clicks</Th>
                        <Th>Added</Th>
                    </tr>
                </thead>
                <Tbody>
                    {results.map(job => {
                        const jobUrl = `/jobs/${job.id}`;
                        const employerUrl = job.employer && job.employer.id && `/employers/${job.employer.id}`;
                        return (
                            <tr key={job.id}>
                                <Td to={jobUrl} primary="true">
                                    {job.title}
                                    {!!job.reference && <Smaller>Reference Number {job.reference}</Smaller>}
                                </Td>
                                <Td to={employerUrl}>{job.employer && job.employer.name}</Td>
                                {!isClient && (
                                    <Td to={jobUrl} primary="true">
                                        {job.adViews}
                                    </Td>
                                )}
                                <Td to={jobUrl} primary="true">
                                    {job.applyClicks}
                                </Td>
                                <Td to={jobUrl} primary="true">
                                    <Date date={job.added} />
                                </Td>
                            </tr>
                        );
                    })}
                </Tbody>
            </Table>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const Smaller = styled.div`
    ${truncatedStyles};
    font-size: 0.75rem;
    font-weight: 500;
    color: #999999;
    max-width: 14rem;
`;

JobAnalyticsTable.propTypes = {
    isClient: PropTypes.bool.isRequired,
    results: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            reference: PropTypes.string,
            adViews: PropTypes.number,
            applyClicks: PropTypes.number,
            employer: PropTypes.shape({
                name: PropTypes.string,
                id: PropTypes.string
            })
        })
    ).isRequired
};

export default JobAnalyticsTable;
