import React, { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Field, Link, Loading, Spinner, SuccessMessage } from '../../atoms';
import { InlinePageTitle, Panel } from '../../molecules';
import { ApiClientContext } from '../../services/Api';
import { useData } from '../../hooks';
import { useHistory, useLocation, useParams } from 'react-router';
import { Form, useFormState, useFormApi, useFieldState } from 'informed';
import { USER_ROLE_TYPES } from '../../constants';
import { Employer, Errors } from '../../types/types';
import { throttle } from 'throttle-debounce';
import { ErrorView, View } from '../index';

interface ViewProps {
    region: string;
    getProfile: () => {};
    errors?: Errors;
}

interface UserProps {
    region: string;
    setErrors?: Dispatch<SetStateAction<Errors | undefined>>;
    errors?: Errors;
}

type User = {
    id?: string;
    name: string;
    emailAddress: string;
    role: UserRoles;
    employer?: Employer;
};

type UserForm = {
    name: string;
    emailAddress: string;
    role: UserRoles;
    employerSlug: string;
};

type UserRoles = 'admin' | 'client';

const blankUser: User = {
    name: '',
    emailAddress: '',
    role: 'admin',
    employer: undefined
};

const UserPage: React.FC<ViewProps> = ({ region, getProfile, errors: initialErrors, ...rest }) => {
    const [errors, setErrors] = useState(initialErrors);

    return (
        <>
            <ErrorView errors={errors} />
            <User region={region} setErrors={setErrors} errors={errors} />
        </>
    );
};

export const User: React.FC<UserProps> = ({ region, setErrors, errors }) => {
    const { id: userId } = useParams();
    const { apiClient } = useContext(ApiClientContext);
    const { data: initialValue, isLoading, error } = useData(apiClient.getUser, userId, {}, () => userId !== undefined);
    const [userData, setUserData] = useState<User>(blankUser);
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [isExisting, setIsExisting] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const history = useHistory();
    const location = useLocation<{ redirectLocation: string | undefined }>();
    const formState = useFormState<UserForm>();

    useEffect(() => {
        setIsExisting(initialValue.name !== undefined);
        setUserData(initialValue);
        setErrors && setErrors(error as Errors);
    }, [initialValue, setUserData, error, setErrors]);

    function setFormValue(name: string, value: string) {
        setIsSaved(false);
        setUserData({ ...userData, [name]: value });
    }

    function handleDelete() {
        function onDelete() {
            setIsExisting(false);
            setUserData(blankUser);
            const redirectLocation = location.state && location.state.redirectLocation;
            const redirectUrl = redirectLocation ? redirectLocation : `/${region}/users/all`;
            history.push(redirectUrl);
        }

        if (!window.confirm(`Are you sure you want to delete this user? This action cannot be undone.`)) {
            return;
        }
        useData.apiCall(apiClient.deleteUser, onDelete, setErrors, setIsApiLoading)(userId);
    }

    function updateDataAfterSave(data: User) {
        setIsSaved(true);
        setUserData(data);
    }

    function updateDataAfterAdd(data: User) {
        setIsSaved(true);
        setUserData(data);

        const redirectUrl = `/${region}/users/${data.id}`;
        history.push(redirectUrl);
    }

    const updateUser = (id: string, values: User) => useData.apiCall(apiClient.updateUser, updateDataAfterSave, setErrors, setIsApiLoading)(id, values);
    const addUser = (values: User) => useData.apiCall(apiClient.addUser, updateDataAfterAdd, setErrors, setIsApiLoading)(values);
    const handleSubmit = () => (isExisting ? updateUser(userId, userData) : addUser(userData));

    if (isLoading) {
        return <Loading />;
    }
    return (
        <Fragment>
            <InlinePageTitle title="User">
                {isExisting && (
                    <Button onClick={handleDelete} filled="border">
                        Delete
                    </Button>
                )}
            </InlinePageTitle>
            <Form onSubmit={handleSubmit}>
                <Fragment>
                    {isExisting && (
                        <Field
                            name="name"
                            initialValue={userData.name}
                            required
                            errors={
                                (errors && errors.Name) ||
                                (formState.touched && formState.touched.name && formState.errors && formState.errors.name && [formState.errors.name])
                            }
                            displayValueAs={<h2>{userData.name}</h2>}
                            fieldType="text"
                            initialRenderType={isExisting ? 'value' : 'field'}
                            isTogglingEnabled={isExisting}
                            onValueChange={(newValue: string) => setFormValue('name', newValue)}
                        />
                    )}
                    <Panel title="User Details">
                        <Fragment>
                            {!isExisting && (
                                <Field
                                    label="Name"
                                    name="name"
                                    initialValue={userData.name}
                                    required
                                    errors={
                                        (errors && errors.Name) ||
                                        (formState.touched && formState.touched.name && formState.errors && formState.errors.name && [formState.errors.name])
                                    }
                                    fieldType="text"
                                    initialRenderType={isExisting ? 'value' : 'field'}
                                    isTogglingEnabled={isExisting}
                                    onValueChange={(newValue: string) => setFormValue('name', newValue)}
                                />
                            )}
                            <Field
                                label="Email"
                                name="emailAddress"
                                initialValue={userData.emailAddress}
                                required
                                errors={
                                    (errors && errors.EmailAddress) ||
                                    (formState.touched &&
                                        formState.touched.emailAddress &&
                                        formState.errors &&
                                        formState.errors.emailAddress && [formState.errors.emailAddress])
                                }
                                fieldType="email"
                                initialRenderType={isExisting ? 'value' : 'field'}
                                isTogglingEnabled={isExisting}
                                onValueChange={(newValue: string) => setFormValue('emailAddress', newValue)}
                            />
                            <Field
                                label="Role"
                                name="role"
                                initialValue={userData.role}
                                required
                                errors={
                                    (errors && errors.Role) ||
                                    (formState.touched && formState.touched.role && formState.errors && formState.errors.role && [formState.errors.role])
                                }
                                displayValueAs={<span>{userData.role}</span>}
                                fieldType="select"
                                options={USER_ROLE_TYPES}
                                initialRenderType={isExisting ? 'value' : 'field'}
                                isTogglingEnabled={false}
                                onValueChange={(newValue: string) => setFormValue('role', newValue)}
                            />
                        </Fragment>
                    </Panel>
                    <ClientEmployerSelect isExisting={isExisting} userData={userData} errors={errors} setFormValue={setFormValue} />
                    <SubmitButton isExisting={isExisting} disabled={formState.invalid} handleSubmit={handleSubmit} isApiLoading={isApiLoading} isSaved={isSaved} />
                </Fragment>
            </Form>
        </Fragment>
    );
};

const ClientEmployerSelect: React.FC<{
    isExisting: boolean;
    errors: Errors | undefined;
    userData: User;
    setFormValue: (name: string, value: string) => void;
}> = ({ errors, userData, isExisting, setFormValue }) => {
    const [employers, setEmployers] = useState<Array<Employer>>([]);
    const formApi = useFormApi<UserForm>();
    const formState = useFormState<UserForm>();
    const { value: role } = useFieldState('role');
    const { apiClient } = useContext(ApiClientContext);

    const handleSearchEmployers = throttle(1000, (value: string) => {
        const queryStr = `&search=${value}`;
        // @ts-ignore
        apiClient.getAllEmployers(1, queryStr).then(({ data }) => {
            setEmployers(!!value && !!data.results.length && value !== data.results[0].slug ? data.results : []);
        });
    });

    if (role == 'client' || userData.role === 'client')
        return (
            <Panel title="Employer Details">
                <Fragment>
                    <Field
                        label="Employer"
                        name="employerSlug"
                        initialValue={userData.employer != null ? userData.employer.slug : ''}
                        required
                        errors={
                            (errors && errors.EmployerSlug) ||
                            (formState.touched && formState.touched.employerSlug && formState.errors && formState.errors.employerSlug && [formState.errors.employerSlug])
                        }
                        fieldType="combobox"
                        initialRenderType={isExisting ? 'value' : 'field'}
                        isTogglingEnabled={isExisting}
                        onValueChange={(newValue: string) => setFormValue('employerSlug', newValue)}
                        setValue={formApi.setValue}
                        valueKey="slug"
                        handleChange={handleSearchEmployers}
                        data={employers}
                    />
                    {userData.employer && userData.employer.logo && (
                        <Link to={`/employers/${userData.employer.id}`} hoverTheme="outline" isExternal={false}>
                            <Logo>
                                <img src={userData.employer.logo} alt="Logo" />
                            </Logo>
                        </Link>
                    )}
                </Fragment>
            </Panel>
        );
    return null;
};

type UserSubmitButtonProps = {
    isExisting: boolean;
    disabled: boolean;
    handleSubmit: () => {};
    isApiLoading: boolean;
    isSaved: boolean;
};

const SubmitButton: React.FC<UserSubmitButtonProps> = ({ isExisting, disabled, handleSubmit, isApiLoading, isSaved }) => {
    const text = isExisting ? { button: 'Update user', successMessage: 'User has been saved' } : { button: 'Create user', successMessage: 'New user has been created' };

    return (
        <>
            <Button type="submit" disabled={disabled} theme="success" style={{ marginTop: '1rem' }} handleSubmit={handleSubmit}>
                {text.button} {isApiLoading && <Spinner />}
            </Button>
            {isSaved && <SuccessMessage message={text.successMessage} />}
        </>
    );
};

const Logo = styled.div`
    max-width: 20rem;
    max-height: 12rem;
    margin-bottom: 1rem;
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    box-sizing: border-box;

    img {
        object-fit: contain;
        max-width: 17rem;
        max-height: 9rem;
    }
`;

export default UserPage;
