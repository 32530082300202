import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Icon } from '../../atoms';
import { resetUl, resetButton, truncatedStyles } from '../../styles';

const Tags = ({ tags, handleClick, ...rest }) => {
    if (!tags) {
        return null;
    }

    return (
        <Ul {...rest}>
            {tags.map(tag => {
                return (
                    <li key={tag.id} style={{ maxWidth: '100%' }}>
                        <Tag type="button" onClick={handleClick ? () => handleClick(tag.id) : undefined}>
                            <span>{tag.name}</span>
                            {!!handleClick && <Icon icon="Times" />}
                        </Tag>
                    </li>
                );
            })}
        </Ul>
    );
};

const Ul = styled.ul`
    ${resetUl};
    display: flex;
    flex-wrap: wrap;
`;

const Tag = styled.button`
    ${resetButton};
    font-family: 'Montserrat', sans-serif;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    border: 0.125rem solid #ffc845;
    border-radius: 1rem;
    margin: 0 0.5rem 0.25rem 0;
    height: 2rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    max-width: 100%;
    ${props => !props.onClick && 'cursor: inherit'};

    &:hover,
    &:focus {
        outline: none;

        svg {
            background-color: #ffc845;
            transition: background-color 0.3s ease;
        }
    }

    span {
        ${truncatedStyles};
        padding: 0 0.75rem;
    }

    svg {
        padding: 0 0.75rem;
        height: 100%;
        min-height: 1.75rem;
        background-color: rgba(255, 200, 69, 0.25);
        transition: background-color 0.3s ease;
    }
`;

Tags.propTypes = {
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
        })
    ),
    handleClick: PropTypes.func
};

export default Tags;
