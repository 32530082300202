/**
 * A performant component for large lists of checkbox inputs.
 *
 * react-window provides most of the optimizations for handling large lists in React
 * by rendering the items in a virtual list. Virtual lists only render a subset of the items
 * that are currently being displayed. Without this, all items are rendered and re-rendered when
 * props change.
 *
 * Using React.memo and memoize provide smaller gains by only re-rendering when props change.
 */

import React from 'react';
import { asField } from 'informed';
import styled from 'styled-components';
import { FixedSizeList as List, areEqual } from 'react-window';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';

import { Label, StyledCheckbox } from '../../atoms';

const handleCheckboxChange = (e, fieldState, fieldApi) => {
    const isChecked = e.target.checked;
    let newFieldState = fieldState.value ? [...fieldState.value] : [];
    const fieldIndex = newFieldState.indexOf(e.target.value);

    if (isChecked) {
        if (fieldIndex === -1) {
            newFieldState.push(e.target.value);
        }
    } else {
        if (fieldIndex !== -1) {
            newFieldState = [...newFieldState.slice(0, fieldIndex), ...newFieldState.slice(fieldIndex + 1)];
        }
    }

    fieldApi.setValue(newFieldState);
};

const CheckboxWrapper = React.memo(({ field, fieldState, fieldApi }) => (
    <Label key={field} label={field} fullWidth labelFirst isInline>
        <Wrapper>
            <HiddenInput
                type="checkbox"
                value={field}
                onChange={e => handleCheckboxChange(e, fieldState, fieldApi)}
                checked={fieldState.value ? fieldState.value.indexOf(field) !== -1 : false}
            />
            <StyledCheckbox />
        </Wrapper>
    </Label>
));

const Wrapper = styled.div`
    position: relative;

    input[type='checkbox'] {
        &:focus + div::before {
            content: '';
        }

        &:checked + div > svg {
            display: block;
        }
    }
`;

const HiddenInput = styled.input`
    position: absolute;
    left: -1rem;
    opacity: 0;
`;

const Row = React.memo(
    ({ data: { items, fieldState, fieldApi }, index, style }) => (
        <div style={style}>
            <div style={{ padding: '0 0.5rem' }}>
                <CheckboxWrapper field={items[index]} fieldState={fieldState} fieldApi={fieldApi} />
            </div>
        </div>
    ),
    areEqual
);

const createItemData = memoize((items, fieldState, fieldApi) => ({
    items,
    fieldState,
    fieldApi
}));

const CheckboxInputGroup = React.memo(
    asField(({ fieldApi, fieldState, options }) => {
        const itemData = createItemData(options, fieldState, fieldApi);
        return (
            <List
                height={400}
                itemCount={options.length}
                itemData={itemData}
                itemSize={40}
                width="100%"
                style={{ border: '1px solid rgb(225, 225, 225)', whiteSpace: 'nowrap' }}
            >
                {Row}
            </List>
        );
    })
);

CheckboxInputGroup.propTypes = {
    /** field name for informed  */
    field: PropTypes.string.isRequired,
    /** Options for this field. These are transformed into individual checkbox inputs */
    options: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default CheckboxInputGroup;
