import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const InlinePageSubtitle = ({ title, children, ...rest }) => {
    return (
        <Wrapper {...rest}>
            <h2>{title}</h2>
            {!!children && <div>{children}</div>}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;

    h2 {
        margin: auto 0.5rem auto 0;
    }

    > * {
        margin: auto;
        margin-right: 0;
    }
`;

InlinePageSubtitle.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any
};

export default InlinePageSubtitle;
