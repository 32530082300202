import React, { Fragment } from 'react';

import { ViewAll } from '../../hocs';
import { InlinePageTitle, JobsTable, SearchPaginationRow } from '../../molecules';
import { Button, EmptyState } from '../../atoms';
import { useAuthorization } from '../../auth';

const Jobs = props => {
    const { isClient } = useAuthorization();

    const { data, history, location, handleGetAll } = props;
    const { results, ...dataLessResults } = data;

    return (
        <Fragment>
            <InlinePageTitle title="Jobs">
                <Button to="/jobs/add" icon="Plus" aria-label="Add new">
                    New
                </Button>
            </InlinePageTitle>
            {data && data.total ? (
                <Fragment>
                    <SearchPaginationRow
                        {...dataLessResults}
                        currentResults={results.length}
                        history={history}
                        location={location}
                        handlePagination={handleGetAll}
                        keywordLabelAs="Job title or reference number"
                        includeFeaturedSearch={!isClient}
                        includeEmployerSearch={!isClient}
                    />
                    <JobsTable results={results} queryString={location.search} />
                </Fragment>
            ) : (
                <EmptyState screen="Jobs" />
            )}
        </Fragment>
    );
};

export default ViewAll(Jobs)({ domain: 'Jobs' });
