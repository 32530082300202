import React, { useCallback, useState, useReducer, useMemo, createContext, useContext } from 'react';
import { useData, useEmployerBenefits } from './index';
import { ApiClientContext } from '../services';
import { EmployerBenefitsProvider } from './useEmployerBenefits';

const ApproveBenefitsContext = createContext();

const ApproveBenefitsProvider = ({ children, id, employer, approver }) => {
    const [isApprovalLoading, setIsApprovalLoading] = useState(false);
    const [isApprovalSuccessful, setIsApprovalSuccessful] = useState(null);
    const [approvalError, setApprovalError] = useState(null);
    const { apiClient } = useContext(ApiClientContext);
    const { getEmployerBenefits, saveEmployerBenefits } = apiClient;

    function reducer(state, action) {
        switch (action.type) {
            case 'add':
                return [...state, action.responseId];
            case 'remove':
                return state.filter(x => x !== action.responseId);
            case 'reset':
                return [];
            default:
                return [...state];
        }
    }

    const [approveList, dispatch] = useReducer(reducer, []);

    const value = useMemo(
        () => ({
            approver,
            isApprovalLoading,
            setIsApprovalLoading,
            isApprovalSuccessful,
            setIsApprovalSuccessful,
            approvalError,
            setApprovalError,
            approveList,
            dispatch
        }),
        [approver, isApprovalLoading, isApprovalSuccessful, approvalError, approveList]
    );

    return (
        <EmployerBenefitsProvider id={id} getApi={getEmployerBenefits} saveApi={saveEmployerBenefits} isSelfService={false} employerData={employer} approver={approver}>
            <ApproveBenefitsContext.Provider value={value}>{children}</ApproveBenefitsContext.Provider>
        </EmployerBenefitsProvider>
    );
};

const useApproveBenefits = () => {
    const { refreshBenefits, id } = useEmployerBenefits();
    const { apiClient } = useContext(ApiClientContext);

    const approveContext = useContext(ApproveBenefitsContext);
    if (approveContext === undefined) {
        throw new Error('useApproveBenefits must be used within a ApproveBenefitsContext');
    }

    const { isApprovalLoading, isApprovalSuccessful, approveList, setIsApprovalSuccessful, setApprovalError, setIsApprovalLoading, dispatch } = approveContext;

    const toggleApprove = useCallback(
        responseId => {
            if (approveList.includes(responseId)) {
                dispatch({ type: 'remove', responseId: responseId });
            } else {
                dispatch({ type: 'add', responseId: responseId });
            }
        },
        [approveList, dispatch]
    );

    const isApproved = useCallback(
        statement =>
            approveList.some(responseId => statement && statement.responses && statement.responses.length > 0 && statement.responses.some(x => x.id === responseId)),
        [approveList]
    );

    const approveBenefits = useCallback(() => {
        const onStart = () => {
            setIsApprovalSuccessful(false);
            setApprovalError(null);
        };

        const onSuccess = () => {
            setIsApprovalSuccessful(true);
            setTimeout(function() {
                dispatch({ type: 'reset' });
                setIsApprovalSuccessful(false);
            }, 2000);
        };

        const payload = { responses: approveList, approver: approveContext.approver };
        return useData.apiCall(apiClient.approveEmployerBenefits, onSuccess, setApprovalError, setIsApprovalLoading, onStart)(id, payload);
    }, [approveList, approveContext.approver, apiClient.approveEmployerBenefits, setApprovalError, setIsApprovalLoading, id, setIsApprovalSuccessful, dispatch]);

    const canApproveBenefits = approveList.length > 0;

    const approveThenRefreshBenefits = useCallback(() => {
        approveBenefits().then(() => refreshBenefits());
    }, [approveBenefits, refreshBenefits]);

    return useMemo(
        () => ({
            approveThenRefreshBenefits,
            canApproveBenefits,
            isApprovalLoading,
            isApprovalSuccessful,
            isApproved,
            toggleApprove
        }),
        [approveThenRefreshBenefits, canApproveBenefits, isApprovalLoading, isApprovalSuccessful, isApproved, toggleApprove]
    );
};

export { ApproveBenefitsProvider, useApproveBenefits };
