import React, { Component } from 'react';
import { asField } from 'informed';
import PropTypes from 'prop-types';
import { DateRangePicker as RDDateRangePicker } from 'react-dates';

import './injectStyles';

class DateRangePicker extends Component {
    state = {
        focusedInput: null
    };

    handleChange = ({ startDate, endDate }) => {
        const { fieldState, fieldApi } = this.props;
        const values = fieldState.value || [];

        if (startDate || startDate === null) {
            if (values.length > 0) {
                values[0] = startDate;
            } else {
                values.push(startDate);
            }
        }

        if (endDate || endDate === null) {
            if (values.length > 1) {
                values[1] = endDate;
            } else {
                values.push(endDate);
            }
        }

        fieldApi.setValue(values);
    };

    render() {
        const { fieldState, ...rest } = this.props;
        const startDate = fieldState.value && fieldState.value.length > 0 ? fieldState.value[0] || null : null;
        const endDate = fieldState.value && fieldState.value.length > 1 ? fieldState.value[1] || null : null;

        return (
            <RDDateRangePicker
                startDateId="startDate"
                endDateId="endDate"
                startDate={startDate}
                endDate={endDate}
                onDatesChange={this.handleChange}
                focusedInput={this.state.focusedInput}
                onFocusChange={focusedInput => this.setState({ focusedInput })}
                orientation="horizontal"
                anchorDirection="left"
                numberOfMonths={1}
                hideKeyboardShortcutsPanel
                showClearDates
                displayFormat="DD/MM/YYYY"
                {...rest}
            />
        );
    }
}

DateRangePicker.propTypes = {
    field: PropTypes.string,
    fieldState: PropTypes.any,
    fieldApi: PropTypes.any
};

export default asField(DateRangePicker);
