import { getRegion } from './helpers';

const region = getRegion();
let locale = '';

if (region === 'uk') locale = 'en-gb';
else if (region === 'au') locale = 'en-au';
else locale = 'en-us';

const localUrl = 'http://localhost:3000';
const stagingUrl = 'https://portal.serenity.work180.co';
const prodUrl = 'https://portal.work180.co';

const tinyMceApiKey = 'pkn36dbwduway5npw8uhzwg4z0guukqbslwj1778gyfo98le';

const devConfig = {
    auth: {
        domain: 'work180-local.au.auth0.com',
        clientId: '7PvsXqqJcKoNYXBnwqxvombKsLRETjQM',
        allowedCallbackUrl: `${localUrl}/loading?region=${region}`,
        allowedWebOrigins: localUrl,
        allowedLogoutUrl: localUrl
    },
    api: {
        name: 'work180.api',
        url: 'http://localhost:4000/'
    },
    website: `http://localhost:5000/${locale}/`,
    tinymce: {
        apiKey: tinyMceApiKey
    },
    toggles: {
        users: true,
        applications: true
    },
    headerColor: '#81ca41',
    environment: 'development'
};

const stagingConfig = {
    auth: {
        domain: 'work180-staging.au.auth0.com',
        clientId: 'f9ef5b0BYLs8gU89TXg9L5fPkVpBm3qd',
        allowedCallbackUrl: `${stagingUrl}/loading?region=${region}`,
        allowedWebOrigins: stagingUrl,
        allowedLogoutUrl: stagingUrl
    },
    api: {
        name: 'work180.api',
        url: 'https://api.serenity.work180.co/'
    },
    website: `https://serenity.work180.com/${locale}/`,
    tinymce: {
        apiKey: tinyMceApiKey
    },
    toggles: {
        users: true,
        applications: true
    },
    headerColor: '#e6c345',
    environment: 'staging'
};

const prodConfig = {
    auth: {
        domain: 'work180.au.auth0.com',
        clientId: 'HoCs4J7Wy4EOO8wRGio60F3a5bOMC60e',
        allowedCallbackUrl: `${prodUrl}/loading?region=${region}`,
        allowedWebOrigins: prodUrl,
        allowedLogoutUrl: prodUrl
    },
    api: {
        name: 'production.work180.api',
        url: 'https://api.work180.co/'
    },
    website: `https://work180.com/${locale}/`,
    tinymce: {
        apiKey: tinyMceApiKey
    },
    toggles: {
        users: true,
        applications: true
    },
    headerColor: '#e31c79',
    environment: 'production'
};

/* eslint-disable indent */
const config = process.env.REACT_APP_BUILD_ENVIRONMENT === 'production' ? prodConfig : process.env.REACT_APP_BUILD_ENVIRONMENT === 'staging' ? stagingConfig : devConfig;

export default config;
