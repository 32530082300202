import React, { Fragment } from 'react';

import { ViewAll } from '../../hocs';
import { InlinePageTitle, SearchPaginationRow, UsersTable } from '../../molecules';
import { Button, EmptyState } from '../../atoms';
import { useAuthorization } from '../../auth';

const Users = props => {
    const { isClient } = useAuthorization();
    const { data, history, location, handleGetAll } = props;
    const { results, ...dataLessResults } = data;

    return (
        <Fragment>
            <InlinePageTitle title="Users">
                <Button to="/users/add" icon="Plus" aria-label="Add new">
                    New
                </Button>
            </InlinePageTitle>
            {data && data.total ? (
                <Fragment>
                    <SearchPaginationRow
                        {...dataLessResults}
                        currentResults={results.length}
                        history={history}
                        location={location}
                        handlePagination={handleGetAll}
                        includeEmployerSearch={!isClient}
                    />
                    <UsersTable results={results} />
                </Fragment>
            ) : (
                <EmptyState screen="Users" />
            )}
        </Fragment>
    );
};

export default ViewAll(Users)({ domain: 'Users' });
