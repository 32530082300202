import React from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';

import { Button, Icon } from '../../atoms';
import { truncatedStyles } from '../../styles';

const UserMenu = () => {
    const { user, logout, isAuthenticated } = useAuth0();

    if (!isAuthenticated) return null;

    const { name, nickname, picture } = user;

    return (
        <Container>
            <Account>
                <Avatar>{picture ? <img src={picture} alt={name} /> : <Icon icon="User" />}</Avatar>
                <Username>Hi {nickname || name}</Username>
            </Account>
            <SignOutButton onClick={logout} filled="invisible" icon="SignOutAlt" size="mini" aria-label="Logout" />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    margin-left: 0.5rem;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
`;

const Account = styled.div`
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.875rem;
    color: #ffffff;
    max-width: 13.5rem;

    &:focus {
        outline: none;
    }

    @media (min-width: 480px) {
        padding: 0.25rem;
        border-radius: 1rem;

        /*
        ----- Re-add hover/focus style once Account is hyperlinked -----
        &:hover,
        &:focus {
            background-color: rgba(255, 255, 255, 0.2);
        }
        */
    }

    @media (min-width: 480px) and (max-width: 768px) {
        max-width: 8.5rem;
    }
`;

const Avatar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-color: #d8d8d8;
    color: #ffffff;
    border-radius: 50%;
    overflow: hidden;
    width: 1.5rem;
    height: 1.5rem;

    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

const Username = styled.span`
    ${truncatedStyles};
    margin: 0 1rem 0 0.5rem;
`;

const SignOutButton = styled(Button)`
    color: #ffffff;
    font-size: 1rem;
    margin-left: 0.375rem;
    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
`;

export default UserMenu;
