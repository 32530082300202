import React from 'react';
import styled from 'styled-components';

import { Link } from '../../atoms';
import config from '../../config';
import { useAuthorization } from '../../auth';

const Dashboard = () => {
    const { isClient } = useAuthorization();

    let menuItems = [
        { link: '/employers/all', label: 'Employers' },
        { link: '/jobs/all', label: 'Jobs' },
        { link: '/applications/all', label: 'Applications' },
        { link: '/users/all', label: 'Users' },
        { link: '/widgets/all', label: 'Widgets' },
        { link: '/jobs/analysis', label: 'JobAd180' }
    ];
    if (isClient) {
        menuItems = menuItems.filter(x => x.label !== 'Employers');
    }
    if (isClient || !config.toggles.users) {
        menuItems = menuItems.filter(x => x.label !== 'Users');
    }
    if (isClient) {
        menuItems = menuItems.filter(x => x.label !== 'Widgets');
    }

    return (
        <>
            <p>You&rsquo;re in the WORK180 portal.</p>
            <p>From here you can manage:</p>
            <ul>
                {menuItems.map(x => (
                    <Li key={x.link}>
                        <Link to={x.link}>{x.label}</Link>
                    </Li>
                ))}
            </ul>
            <p>
                If you think there is a feature missing, let us know at{' '}
                <Link to="mailto:feedback@work180.co" isExternal>
                    feedback@work180.co
                </Link>
            </p>
        </>
    );
};

const Li = styled.li`
    line-height: 1.8;
`;

export default Dashboard;
