import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Field, ErrorMessage, Button, Spinner, SuccessMessage } from '../../atoms';
import { Form, useFormState } from 'informed';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';

import { ApiClientContext } from '../../services';
import { useData } from '../../hooks';

const CvCaptureApplication = ({ id }) => {
    const { apiClient } = useContext(ApiClientContext);
    const { data: initialData, isLoading } = useData(apiClient.getApplication, id, {}, () => id !== undefined);
    const [applicationData, setApplicationData] = useState(initialData.pipeline || null);
    const [apiSaveError, setApiSaveError] = useState(null);
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const formState = useFormState();

    const nextStepsOptions = [
        { id: 'Proceed To Call', name: 'Proceed To Call' },
        { id: 'Reject', name: 'Reject' }
    ];

    const {
        user: { name: userName }
    } = useAuth0();

    function updateAfterSave(data) {
        setApplicationData(data);
        setIsSaved(true);
    }

    function handleSubmit() {
        const updatedValues = { ...applicationData, modifiedBy: userName.toString() };
        useData.apiCall(apiClient.updateApplication, updateAfterSave, setApiSaveError, setSaveIsLoading)(id, updatedValues);
    }

    function setFormValue(name, value) {
        setApplicationData(prevState => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        setApplicationData(initialData.pipeline);
    }, [initialData]);

    if (isLoading) return <Spinner />;

    return (
        <Fragment key={id}>
            <Form onSubmit={handleSubmit}>
                <Details>
                    <Field
                        label="Alignment Score"
                        name="alignmentScore"
                        hideOptionalLabel
                        initialValue={applicationData?.alignmentScore?.toString() || ''}
                        fieldType="text"
                        errors={
                            formState.touched &&
                            formState.touched.alignmentScore &&
                            formState.errors &&
                            formState.errors.alignmentScore && [formState.errors.alignmentScore]
                        }
                        onValueChange={newValue => setFormValue('alignmentScore', newValue)}
                    />
                    <Field
                        label="Next Steps"
                        name="nextSteps"
                        hideOptionalLabel
                        initialValue={applicationData?.nextSteps}
                        errors={formState.touched && formState.touched.nextSteps && formState.errors && formState.errors.nextSteps && [formState.errors.nextSteps]}
                        fieldType="select"
                        onValueChange={newValue => setFormValue('nextSteps', newValue)}
                        options={nextStepsOptions}
                    />
                    <Field
                        label="Rejection Reason"
                        name="rejectReason"
                        initialValue={applicationData?.rejectReason || ''}
                        errors={
                            formState.touched && formState.touched.rejectReason && formState.errors && formState.errors.rejectReason && [formState.errors.rejectReason]
                        }
                        fieldType="text"
                        onValueChange={newValue => setFormValue('rejectReason', newValue)}
                    />
                    <Field
                        label="Follow Up Email Sent?"
                        name="emailSent"
                        initialValue={applicationData?.emailSent}
                        errors={formState.touched && formState.touched.emailSent && formState.errors && formState.errors.emailSent && [formState.errors.emailSent]}
                        fieldType="checkbox"
                        onValueChange={newValue => setFormValue('emailSent', newValue)}
                        hideOptionalLabel={true}
                    />
                    <Button type="submit" disabled={saveIsLoading} theme="success">
                        Save
                    </Button>
                    {isSaved && <SuccessMessage message={'Save successful'} />}
                </Details>
            </Form>
            {apiSaveError && <ErrorMessage errors={[apiSaveError.message]} />}
        </Fragment>
    );
};

const Details = styled.div`
    padding: 1.5rem 1rem 1.5rem 7.5rem;
    border-bottom: 1px solid #f1f1f1;
`;

export default CvCaptureApplication;
