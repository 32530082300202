import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Icon } from '../../atoms';

const iconSizes = {
    small: '0.85rem',
    medium: '1.2rem',
    large: '2rem'
};

const Tooltip = ({ text, iconSize, style, textStyle, icon }) => {
    return (
        <Container style={style}>
            <Icon icon={icon} style={{ fontSize: iconSizes[iconSize], color: 'black' }} />
            <Text style={textStyle}>{text}</Text>
        </Container>
    );
};

const Text = styled.span`
    position: absolute;
    box-sizing: border-box;
    min-width: 200px;
    max-width: 400px;
    top: 50%;
    left: 100%;
    text-align: center;
    padding: 10px;
    margin-left: 20px;
    font-size: 0.75rem;
    font-weight: 400;
    color: #212121;
    border-radius: 4px;
    border: solid 1px #e9e9e9;
    background-color: #fafafa;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.11);
    transform: translate(0, -50%);
    z-index: 50;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s;

    &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        top: 50%;
        right: 100%;
        transform: translate(50%, -50%) rotate(-45deg);
        background-color: #fafafa;
        box-shadow: -2px -1px 0px 0px rgba(0, 0, 0, 0.11);
    }
`;

const Container = styled.div`
    display: inline;
    position: relative;
    align-items: center;
    vertical-align: middle;
    font-size: 0.75rem;
    color: black;
    margin: 0 5px;

    &:hover {
        ${Text} {
            visibility: visible;
            opacity: 1;
        }
    }
`;

Tooltip.defaultProps = {
    iconSize: 'small',
    icon: 'InfoCircle',
    showIcon: true
};

Tooltip.propTypes = {
    text: PropTypes.string,
    iconSize: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
    icon: PropTypes.string.isRequired,
    textStyle: PropTypes.object,
    style: PropTypes.object
};

export default Tooltip;
