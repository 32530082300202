import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowRight,
    faAward,
    faCalendar,
    faCheck,
    faChevronCircleLeft,
    faChevronCircleRight,
    faChevronDown,
    faChevronUp,
    faCircle,
    faCopy,
    faEnvelopeOpenText,
    faExclamationCircle,
    faExternalLinkAlt,
    faEye,
    faEyeSlash,
    faInfoCircle,
    faPencilAlt,
    faPlus,
    faPlusCircle,
    faMinusCircle,
    faSearch,
    faSignOutAlt,
    faSpinner,
    faTimes,
    faTrashAlt,
    faUser,
    faDownload,
    faSearchPlus,
    faSearchMinus,
    faGripLines,
    faQuoteRight,
    faFileAlt,
    faFilm,
    faArrowCircleUp,
    faMars,
    faMarsDouble,
    faNeuter,
    faVenus,
    faVenusDouble,
    faExclamationTriangle,
    faSyncAlt
} from '@fortawesome/free-solid-svg-icons';

const Icon = ({ icon, ...rest }) => {
    const availableIcons = {
        ArrowRight: faArrowRight,
        ArrowCircleUp: faArrowCircleUp,
        Award: faAward,
        Calendar: faCalendar,
        Check: faCheck,
        ChevronCircleLeft: faChevronCircleLeft,
        ChevronCircleRight: faChevronCircleRight,
        ChevronDown: faChevronDown,
        ChevronUp: faChevronUp,
        Circle: faCircle,
        Copy: faCopy,
        Download: faDownload,
        EnvelopeOpenText: faEnvelopeOpenText,
        ExclamationCircle: faExclamationCircle,
        ExclamationTriangle: faExclamationTriangle,
        ExternalLinkAlt: faExternalLinkAlt,
        Eye: faEye,
        EyeSlash: faEyeSlash,
        FileAlt: faFileAlt,
        Film: faFilm,
        GripLines: faGripLines,
        InfoCircle: faInfoCircle,
        PencilAlt: faPencilAlt,
        Plus: faPlus,
        PlusCircle: faPlusCircle,
        MinusCircle: faMinusCircle,
        QuoteRight: faQuoteRight,
        Search: faSearch,
        SearchPlus: faSearchPlus,
        SearchMinus: faSearchMinus,
        SignOutAlt: faSignOutAlt,
        Spinner: faSpinner,
        SyncAlt: faSyncAlt,
        Times: faTimes,
        TrashAlt: faTrashAlt,
        User: faUser,
        GenderMale: faMars,
        GenderStronglyMale: faMarsDouble,
        GenderNeutral: faNeuter,
        GenderFemale: faVenus,
        GenderStronglyFemale: faVenusDouble
    };

    return <FontAwesomeIcon icon={availableIcons[icon]} {...rest} />;
};

Icon.defaultProps = {
    icon: 'Spinner'
};

Icon.propTypes = {
    /** FontAwesome icon string without the 'fa' */
    icon: PropTypes.string.isRequired
};

export default Icon;
