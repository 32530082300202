import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Form, TextArea } from 'informed';
import styled from 'styled-components';
import { Redirect } from 'react-router';
import { match } from 'react-router-dom';

import { ErrorView, View } from '../index';
import { Button, Loading, Label, Spinner, ErrorMessage, Field, SuccessMessage } from '../../atoms';
import { InlinePageTitle, Panel } from '../../molecules';
import { useData } from '../../hooks';
import { ApiClientContext } from '../../services';

type ParamProps = {
    employerId: string;
    region: string;
};

type JobAutomationProps = {
    names: string;
    computedMatch: match<ParamProps>;
};

type JobAutomation = {
    id: string;
    isDisabled: boolean;
    provider: string;
    scrapingEnabled: boolean;
    feedEnabled: boolean;
    configuration: object;
    feedUrl: string;
    isJobAdder: boolean;
    jobAdderActas: string;
    applicationEmail: string[];
    isSmartScraper: boolean;
    smartScraperConfiguration: SmartScraperConfiguration;
};

type SmartScraperConfiguration = {
    variables: Array<object>;
    mappings: Array<object>;
};

const JobAutomation: FC<JobAutomationProps> = props => {
    const employerId = props.computedMatch.params.employerId;
    const region = props.computedMatch.params.region;
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [isSendingRun, setIsSendingRun] = useState(false);
    const [apiError, setApiError] = useState({ message: null });
    const [apiSaveError, setSaveError] = useState({ message: null });
    const [apiRunError, setRunError] = useState({ message: null });
    const [apiRunTriggered, setApiRunTriggered] = useState(false);
    const [dryRunId, setDryRunId] = useState(null);
    const { apiClient } = useContext(ApiClientContext);

    const { data: initialValue, isLoading } = useData(apiClient.getJobAutomation, employerId, {});
    const { data: employer } = useData(apiClient.getEmployer, employerId, {});

    const [jobAutomation, setJobAutomation] = useState<JobAutomation>(initialValue);

    useEffect(() => {
        setJobAutomation(initialValue);
    }, [initialValue, setJobAutomation]);

    function setFormValue(checked: boolean) {
        setIsSaved(false);
        setJobAutomation({ ...jobAutomation, isDisabled: checked });
    }

    function apiRunStarted() {
        setApiRunTriggered(true);
    }

    function updateJobAutomation(data: JobAutomation) {
        setJobAutomation(data);
        setIsSaved(true);
    }

    const handleSubmit = useCallback(() => {
        // At the moment only has the functionality for enable/disabling
        const submitJobAutomation = useData.apiCall(apiClient.toggleJobAutomationStatus, updateJobAutomation, setSaveError, setIsSaving);
        submitJobAutomation(jobAutomation.id, jobAutomation.isDisabled);
    }, [apiClient.toggleJobAutomationStatus, jobAutomation.id, jobAutomation.isDisabled]);

    const runJobAutomation = useData.apiCall(() => apiClient.runJobAutomation(employerId), apiRunStarted, setRunError, setIsSendingRun);

    const submitForDryRun = useCallback(
        values => {
            if (values.jobAutomation) {
                const formValues = JSON.parse(values.jobAutomation);

                const submitFormValues = useData.apiCall(apiClient.sendJobAutomationDryRun, setDryRunId, setApiError, setIsApiLoading);

                submitFormValues(formValues);
            }
        },
        [apiClient.sendJobAutomationDryRun]
    );

    if (dryRunId) {
        return (
            <Redirect
                push={true}
                to={{
                    pathname: `/${region}/job-automations/${employerId}/dry-run/${dryRunId}`
                }}
            />
        );
    }

    if (isLoading) {
        return <Loading />;
    }

    const title = 'Job Automation';
    const initialValuePrettyJsonString = JSON.stringify(initialValue, undefined, 4);
    const errors = apiError && apiError.message ? [apiError.message] : [];

    return (
        <>
            <ErrorView errors={errors} />
            <InlinePageTitle title={title}>
                <ButtonRow>
                    <Button
                        theme="primary"
                        onClick={runJobAutomation}
                        isExternal={false}
                        to={undefined}
                        size="primary"
                        filled="primary"
                        icon={undefined}
                        isSentenceCase={false}
                    >
                        Start Live Run {isSendingRun && <Spinner />}
                    </Button>
                    {!apiRunError && <ErrorMessage errors={errors} />}
                    {apiRunTriggered && <SuccessMessage message="Api run has started" />}
                </ButtonRow>
            </InlinePageTitle>
            <Panel title={`Job Automation for '${employer.slug}'`}>
                <Form onSubmit={handleSubmit}>
                    <Field
                        label="Disable"
                        name="disableJobAutomation"
                        extraDetail={'Should this job automation not be running?'}
                        initialValue={jobAutomation.isDisabled}
                        displayValueAs={<span>{jobAutomation.isDisabled ? 'Yes' : 'No'}</span>}
                        fieldType="checkbox"
                        initialRenderType="value"
                        isTogglingEnabled
                        onValueChange={(newValue: boolean) => setFormValue(newValue)}
                        invalid={!!errors}
                    />
                    <StyledButton type="submit" theme="success">
                        Update {isSaving && <Spinner />}
                    </StyledButton>
                    {!apiSaveError && <ErrorMessage errors={errors} />}
                    {isSaved && <SuccessMessage message="Job automation has been saved" />}
                </Form>
            </Panel>
            <Panel title={`Testing environment for '${employer.slug}'`}>
                <Form onSubmit={submitForDryRun}>
                    <Label htmlFor="jobAutomationJson" label="Serialized JSON" labelFirst isInline />
                    <StyledTextArea id="jobAutomationJson" rows="30" field="jobAutomation" initialValue={initialValuePrettyJsonString} />
                    {apiError && <ErrorMessage errors={errors} />}
                    <StyledButton type="submit" theme="success">
                        Start Dry Run {isApiLoading && <Spinner />}
                    </StyledButton>
                </Form>
            </Panel>
        </>
    );
};

const ButtonRow = styled.div`
    a:first-child {
        margin-right: 0.5rem;
    }
`;

const StyledButton = styled(Button)`
    margin: 2rem 0;
`;

const StyledTextArea = styled(({ field, ...rest }) => <TextArea field={field} {...rest} />)`
    width: 100%;
`;

const DatabaseDrivenMessage: React.FC<{ isDatabase: boolean }> = ({ isDatabase }) => {
    if (isDatabase) return <SmallMessage>This job automation is database driven, any changes you make will be live.</SmallMessage>;
    else return <SmallMessage>This job automation is read only.</SmallMessage>;
};

const SmallMessage = styled.div`
    font-size: 0.75rem;
    font-weight: 500;
    color: #999999;
    margin-bottom: 0.5rem;
`;

export default JobAutomation;
