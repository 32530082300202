import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
    label: string;
    labelFirst?: boolean;
    markAsOptional?: boolean;
    isInline?: boolean;
    fullWidth?: boolean;
    useColumnLayout?: boolean;
}

const Label: FC<LabelProps> = ({ label, markAsOptional = false, isInline = false, fullWidth, labelFirst, useColumnLayout = false, children, ...rest }) => {
    return (
        <StyledLabel isInline={isInline} fullWidth={fullWidth} useColumnLayout={useColumnLayout} {...rest}>
            {!!labelFirst && children}
            <Description isInline={isInline} labelFirst={labelFirst}>
                {label}
                {markAsOptional && <Optional> (Optional)</Optional>}
            </Description>
            {!labelFirst && children}
        </StyledLabel>
    );
};

const Description = styled.span<Partial<LabelProps>>`
    font-weight: 600;
    line-height: 1.2;
    align-self: flex-start;
    flex-shrink: 0;
    font-family: 'Montserrat', sans-serif;
    padding: ${props => (props.labelFirst ? '0.8125rem 0 0.8125rem 0.5rem' : '0.8125rem 0')};
    box-sizing: border-box;
    min-height: 2.5rem;
    position: relative;
`;

const Optional = styled.span`
    opacity: 0.375;
    text-transform: capitalize;
    font-weight: 500;
`;

const rowLayoutStyles = css<Partial<LabelProps>>`
    align-items: center;
    ${Description} {
        width: ${props => (props.fullWidth ? '100%' : '9.5rem')};
    }
`;

const columnLayoutStyles = css`
    flex-direction: column;
`;

const StyledLabel = styled.label<Partial<LabelProps>>`
    display: ${props => (props.isInline ? 'inline-flex' : 'flex')};
    flex-grow: ${props => (props.isInline ? 0 : 1)};
    margin-bottom: 0.25rem;
    font-size: 0.875rem;

    @media (min-width: 30rem) {
        ${props => (props.useColumnLayout ? columnLayoutStyles : rowLayoutStyles)};
    }

    ${props =>
        props.isInline &&
        css`
            align-items: center;
        `}

    ${props =>
        !props.isInline &&
        css`
            @media (max-width: 30rem) {
                ${columnLayoutStyles};
            }
        `}
`;

export default Label;
