import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import JSONPretty from 'react-json-pretty';
import { match } from 'react-router-dom';
// @ts-ignore
import JSONPrettyMon from 'react-json-pretty/dist/monikai';

import { View } from '../index';
import { InlinePageTitle } from '../../molecules';
import { ErrorMessage, Spinner } from '../../atoms';
import { ApiClientContext } from '../../services';
import { useData } from '../../hooks';

type ParamProps = {
    employerId: string;
    id: string;
};

type DryRunResultProps = {
    names: string;
    computedMatch: match<ParamProps>;
};

type Data = {
    success: boolean;
    result: Array<any>;
    isComplete: boolean;
    errorMessage: string;
};

const DryRunResult: FC<DryRunResultProps> = props => {
    const title = 'Job Automation Dry Run Result';
    const dryRunId = props.computedMatch.params.id;
    const employerId = props.computedMatch.params.employerId;

    const [isDryRunning, setIsDryRunning] = useState(true);
    const [data, setData] = useState<Data | null>(null);
    const [error, setError] = useState<string | null>(null);

    const { apiClient } = useContext(ApiClientContext);

    const { data: employer } = useData(apiClient.getEmployer, employerId, {});

    const finishPolling = (data: Data) => {
        if (data.isComplete) {
            if (data.success) {
                setData(data);
            } else {
                setError(data.errorMessage);
            }
            setIsDryRunning(false);
        }
    };

    useEffect(() => {
        const pollApi = () => {
            useData.apiCall(apiClient.getJobAutomationDryRunResult, finishPolling)(dryRunId);
        };

        const id = setInterval(pollApi, 1000);

        if (data || error) {
            clearInterval(id);
        }

        return () => {
            clearInterval(id);
        };
    }, [apiClient.getJobAutomationDryRunResult, data, dryRunId, error]);

    return (
        <>
            <InlinePageTitle title={title} />
            <h2>{employer.slug}</h2>
            <p>DryRunId: {dryRunId}</p>
            {isDryRunning && (
                <RunningMessage>
                    <span>Dry Run is still going</span>
                    <Spinner />
                </RunningMessage>
            )}
            {error && <ErrorMessage errors={[error]} />}
            {data && <JSONPretty data={data} theme={JSONPrettyMon} mainStyle="white-space: pre-wrap;" />}
        </>
    );
};

const RunningMessage = styled.div`
    color: #dc2579;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;

    span {
        margin-right: 10px;
    }
`;

export default DryRunResult;
