import React from 'react';
import PropTypes from 'prop-types';

const EmptyState = ({ screen }) => {
    return <p>No {screen} currently available.</p>;
};

EmptyState.propTypes = {
    screen: PropTypes.string.isRequired
};

export default EmptyState;
