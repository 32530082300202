import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { REGIONS } from '../constants';

const AuthorizationContext = createContext();

const AuthorizationProvider = ({ children }) => {
    const { getIdTokenClaims, isAuthenticated } = useAuth0();
    const [claims, setClaims] = useState({});
    const [idToken, setIdToken] = useState({});
    const [isRetrievingClaims, setIsRetrievingClaims] = useState(true);

    useEffect(() => {
        async function setIdTokenClaims() {
            const idTokenClaims = await getIdTokenClaims();
            setClaims(idTokenClaims);

            if (idTokenClaims) {
                setIdToken(idTokenClaims.__raw);
            }
            setIsRetrievingClaims(false);
        }
        setIdTokenClaims();
    }, [getIdTokenClaims, isAuthenticated]);

    const value = useMemo(() => ({ claims, isRetrievingClaims, idToken }), [claims, isRetrievingClaims, idToken]);

    return <AuthorizationContext.Provider value={value}>{children}</AuthorizationContext.Provider>;
};

const useAuthorization = () => {
    const context = useContext(AuthorizationContext);
    if (!context) {
        throw new Error(`useAuthorization must be used within a AuthorizationProvider`);
    }
    const { claims, isRetrievingClaims, idToken } = context;
    const isClient = doesUserBelongToGroup(claims, 'client');
    const isSuper = doesUserBelongToGroup(claims, 'super');
    const client = getClient(claims);

    const allowedRegions = useMemo(() => {
        if (isClient) {
            const regions = [];
            const isAuClient = doesUserBelongToGroup(claims, 'client:au');
            const isUkClient = doesUserBelongToGroup(claims, 'client:uk');
            const isUsClient = doesUserBelongToGroup(claims, 'client:us');

            if (isAuClient) {
                regions.push('au');
            }
            if (isUkClient) {
                regions.push('uk');
            }
            if (isUsClient) {
                regions.push('us');
            }
            return regions;
        }
        return REGIONS.map(regionObject => regionObject.id);
    }, [claims, isClient]);

    return { claims, isClient, client, allowedRegions, isLoadingAuthorization: isRetrievingClaims, isSuper, idToken };
};

const doesUserBelongToGroup = (claims, group) => {
    if (!claims) {
        return false;
    }
    const groups = claims['https://auth.work180.co/claims/groups'];
    if (groups) {
        return groups.includes(group);
    }
    return false;
};

const getClient = claims => {
    if (!claims) {
        return undefined;
    }

    const allowedClients = claims['https://auth.work180.co/claims/allowedClients'];
    if (allowedClients && allowedClients.length > 0) {
        return allowedClients[0].company;
    }
    return undefined;
};

export { useAuthorization, AuthorizationProvider };
