import React, { FC } from 'react';
import styled from 'styled-components';

type InlinePageSubtitleProps = {
    title: string;
};

const InlinePageTitle: FC<InlinePageSubtitleProps> = ({ title, children, ...rest }) => {
    return (
        <Wrapper {...rest}>
            <h1>{title}</h1>
            {!!children && <div>{children}</div>}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;

    h1 {
        margin-right: 0.5rem;
    }

    > * {
        margin-bottom: 1.5rem;
    }
`;

export default InlinePageTitle;
