import React from 'react';
import Icon from '../../atoms/Icon';
import styled from 'styled-components';

const InfoBanner: React.FC = ({ children }) => {
    return (
        <Banner>
            <div className="infoWrapper">
                <Icon icon="InfoCircle" />
                <div className="infoText">{children}</div>
            </div>
        </Banner>
    );
};

const Banner = styled.div`
    background-color: #ffc845;
    padding: 10px;
    box-shadow: 9px 10px 14px -10px rgba(0, 0, 0, 0.52);

    .infoWrapper {
        display: flex;
    }

    .infoText {
        padding-left: 5px;
    }

    a {
        color: #dc2579;
    }
`;

export default InfoBanner;
