/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
    CheckboxInput,
    CheckboxGroupInput,
    Combobox,
    DatePicker,
    DateTimePicker,
    EmailInput,
    ErrorMessage,
    Icon,
    Label,
    LinkInput,
    PasswordInput,
    RadioGroupInput,
    SelectInput,
    SelectInputObject,
    Spinner,
    TextInput,
    TagInput
} from '../../atoms';
import { resetButton, truncatedStyles } from '../../styles';

class Field extends Component {
    state = {
        renderType: null,
        isSubmitting: false
    };

    handleSwitchToField = e => {
        e.preventDefault();
        this.setState({
            renderType: 'field'
        });
    };

    handleSwitchToValue = e => {
        e.preventDefault();
        this.setState({
            renderType: 'value'
        });
    };

    handleSubmitSwitchToValue = () => {
        const { handleSubmit, invalid } = this.props;
        if (!handleSubmit || invalid) {
            return;
        }

        this.setState({ isSubmitting: true }, () => {
            handleSubmit().then(() => {
                setTimeout(() => {
                    this.setState({
                        isSubmitting: false,
                        renderType: 'value'
                    });
                }, 500);
            });
        });
    };

    renderField = () => {
        const { isSubmitting } = this.state;
        const {
            label,
            name,
            initialValue,
            includeBefore,
            includeAfter,
            required,
            errors,
            invalid,
            displayValueAs,
            extraDetail,
            fieldType,
            initialRenderType,
            isTogglingEnabled,
            handleSubmit,
            renderInlineField,
            isInline,
            dateProps,
            onValueChange,
            ...rest
        } = this.props;

        const fieldProps = {
            name,
            initialValue,
            required,
            errors,
            onValueChange,
            ...rest
        };
        const submitOrUndefined = isTogglingEnabled ? (onValueChange ? this.handleSwitchToValue : this.handleSubmitSwitchToValue) : undefined;

        let inputComponent;
        switch (fieldType) {
            case 'checkbox':
                inputComponent = <CheckboxInput {...fieldProps} onBlur={submitOrUndefined} />;
                break;
            case 'checkboxGroup':
                inputComponent = <CheckboxGroupInput {...fieldProps} onChange={submitOrUndefined} />;
                break;
            case 'combobox':
                inputComponent = <Combobox {...fieldProps} onBlur={submitOrUndefined} />;
                break;
            case 'date':
                inputComponent = <DatePicker {...fieldProps} {...dateProps} />;
                break;
            case 'datetime':
                inputComponent = <DateTimePicker {...fieldProps} {...dateProps} isTogglingEnabled={isTogglingEnabled} onBlur={submitOrUndefined} />;
                break;
            case 'email':
                inputComponent = <EmailInput {...fieldProps} onBlur={submitOrUndefined} />;
                break;
            case 'password':
                inputComponent = <PasswordInput {...fieldProps} onBlur={submitOrUndefined} />;
                break;
            case 'radio':
                inputComponent = <RadioGroupInput {...fieldProps} onBlur={submitOrUndefined} />;
                break;
            case 'link':
                inputComponent = <LinkInput {...fieldProps} onBlur={submitOrUndefined} />;
                break;
            case 'select':
                inputComponent = <SelectInput {...fieldProps} onBlur={submitOrUndefined} />;
                break;
            case 'selectobject':
                inputComponent = <SelectInputObject {...fieldProps} onChange={submitOrUndefined} />;
                break;
            case 'text':
                inputComponent = <TextInput {...fieldProps} onBlur={submitOrUndefined} />;
                break;
            case 'number':
                inputComponent = <TextInput {...fieldProps} type="number" onBlur={submitOrUndefined} />;
                break;
            case 'tag':
                inputComponent = <TagInput {...fieldProps} field={name} onBlur={submitOrUndefined} />;
                break;
            default:
                inputComponent = <TextInput {...fieldProps} onBlur={submitOrUndefined} />;
        }

        return (
            <ContainerRow>
                {includeBefore && <Before>{includeBefore}</Before>}
                <InputWrapper>
                    {inputComponent}
                    {!!renderInlineField && renderInlineField()}
                    {isSubmitting && <Spinner style={{ marginLeft: '0.5rem' }} />}
                </InputWrapper>
                {includeAfter && <After>{includeAfter}</After>}
            </ContainerRow>
        );
    };

    renderValue = () => {
        const { initialValue, includeBefore, includeAfter, displayValueAs, isTogglingEnabled, truncated, renderInlineField } = this.props;
        const renderedValue = displayValueAs ? (
            <span>
                {includeBefore && <Before>{includeBefore}</Before>}
                {displayValueAs}
                {!!renderInlineField && renderInlineField()}
                {includeAfter && <After>{includeAfter}</After>}
            </span>
        ) : (
            <span>
                {includeBefore && <Before>{includeBefore}</Before>}
                {initialValue}
                {!!renderInlineField && renderInlineField()}
                {includeAfter && <After>{includeAfter}</After>}
            </span>
        );

        if (!isTogglingEnabled) {
            return renderedValue;
        }

        return (
            <Button type="button" onClick={e => this.handleSwitchToField(e)} truncated={truncated}>
                {!!initialValue || !!displayValueAs ? renderedValue : <EmptyValue>click to add</EmptyValue>}
                <Icon icon="PencilAlt" />
            </Button>
        );
    };

    componentDidMount() {
        const { initialRenderType } = this.props;
        this.setState({ renderType: initialRenderType });
    }

    componentDidUpdate(prevProps) {
        // Used by Storybook to update renderType using knobs
        const { initialRenderType } = this.props;
        if (initialRenderType !== prevProps.initialRenderType) {
            this.setState({ renderType: initialRenderType });
        }
    }

    render() {
        const { label, required, errors, extraDetail, fieldType, handleSubmit, hideOptionalLabel, isInline, ...rest } = this.props;
        const { renderType } = this.state;
        const fieldTypesThatShouldGrow = ['text', 'select', 'combobox'];
        const labelShouldGrow = (renderType === 'field' && fieldTypesThatShouldGrow.includes(fieldType)) || renderType === 'value';

        const containerColumn = (
            <ContainerColumn isInline={isInline}>
                {renderType === 'field' && this.renderField()}
                {renderType === 'value' && this.renderValue()}
                {!!extraDetail && <ExtraDetail>{extraDetail}</ExtraDetail>}
                <ErrorMessage errors={errors} />
            </ContainerColumn>
        );

        if (label) {
            return (
                <Label label={label} markAsOptional={!required && !hideOptionalLabel} inline={!labelShouldGrow} labelFirst={!!isInline} {...rest}>
                    {containerColumn}
                </Label>
            );
        }

        return containerColumn;
    }
}

const ContainerRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

const ContainerColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: ${props => (props.isInline ? 0 : 1)};
    margin-left: ${props => (props.isInline ? 0.5 : 0)}em;
`;

const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const ExtraDetail = styled.span`
    font-size: 0.75rem;
    font-weight: 500;
    color: #999999;
    margin-bottom: 0.5rem;
`;

const Button = styled.button`
    ${resetButton};
    text-align: left;
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-height: 2.5rem;

    &:hover {
        svg {
            opacity: 1;
        }
    }

    &:focus {
        outline: none;
        h2,
        span {
            border-bottom: 1px solid #ffc845;
        }
        svg {
            opacity: 1;
        }
    }

    > span {
        margin: 0;
        word-break: break-all ${// eslint-disable-next-line prettier/prettier
    props => props.truncated &&
                `
            ${truncatedStyles};
            width: 10rem;
            min-width: calc(100% - 1.5rem);
        `};
    }

    svg {
        margin-left: 0.5rem;
        font-size: 0.75rem;
        opacity: 0;
    }
`;

const Before = styled.span`
    margin-right: 0.5rem;
    white-space: nowrap;
`;

const After = styled.span`
    margin-left: 0.5rem;
    white-space: nowrap;
`;

const EmptyValue = styled.span`
    opacity: 0.5;
`;

Field.defaultProps = {
    required: false,
    initialRenderType: 'field',
    fieldType: 'text',
    isTogglingEnabled: false,
    isInline: false
};

Field.propTypes = {
    /** Label that gets assigned to the field. If provided, this will get displayed in the UI */
    label: PropTypes.string,
    /** name attribute that is passed to the field */
    name: PropTypes.string.isRequired,
    /** Is this a required field? This is used to provide a visual indicator and to validate the field */
    required: PropTypes.bool,
    /** Validation errors, can be client or server side */
    errors: PropTypes.arrayOf(PropTypes.string),
    /** If the value should display as something other than <span>{field.value}</span> */
    displayValueAs: PropTypes.node,
    /** Additional info to be included with a field */
    extraDetail: PropTypes.node,
    /** Type of form field */
    fieldType: PropTypes.oneOf([
        'checkbox',
        'checkboxGroup',
        'combobox',
        'date',
        'datetime',
        'email',
        'password',
        'radio',
        'link',
        'select',
        'selectobject',
        'text',
        'number',
        'tag'
    ]).isRequired,
    /** Show field or value to start with? */
    initialRenderType: PropTypes.oneOf(['field', 'value']).isRequired,
    /** Can the field be toggled between showing the field and value? */
    isTogglingEnabled: PropTypes.bool.isRequired,
    /** Function to run on submit */
    handleSubmit: PropTypes.func,
    /** True if the form is in an invalid state. Prevents the form from being submitted */
    invalid: PropTypes.bool,
    /** True if the 'optional' label should not be shown. */
    hideOptionalLabel: PropTypes.bool,
    /** Inline field to render beside an existing field */
    renderInlineField: PropTypes.func,
    /** True if the field is inline and should be displayed accordingly. */
    isInline: PropTypes.bool,
    /** Properties to configure the date field type */
    dateProps: PropTypes.object,
    initialValue: PropTypes.any,
    includeBefore: PropTypes.any,
    includeAfter: PropTypes.any,
    truncated: PropTypes.bool,
    onValueChange: PropTypes.func
};

export default Field;
