import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Tbody } from '../../molecules';
import { Button, Td, Th } from '../../atoms';
import { usePathForRegion } from '../../hooks';

const WidgetsTable = ({ results, queryString, ...rest }) => {
    const redirectLocation = usePathForRegion(`/widgets/all${queryString}`);

    return (
        <Container {...rest}>
            <Table>
                <thead>
                    <tr>
                        <Th colSpan="1">Partner</Th>
                        <Th colSpan="2">Title</Th>
                    </tr>
                </thead>
                <Tbody>
                    {results.map(widget => {
                        const widgetLinkProps = {
                            pathname: `/widgets/${widget.id}`,
                            state: { redirectLocation }
                        };
                        return (
                            <tr key={widget.id}>
                                <Td to={widgetLinkProps} primary="true">
                                    {widget.clientUrl}
                                </Td>
                                <Td to={widgetLinkProps}>{widget.title}</Td>
                                <BtnCell>
                                    <Button to={widgetLinkProps} size="mini" filled="border">
                                        Edit
                                    </Button>
                                </BtnCell>
                            </tr>
                        );
                    })}
                </Tbody>
            </Table>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const BtnCell = styled(Td)`
    width: 5rem;

    > * {
        padding: 0 1rem;
    }
`;

WidgetsTable.propTypes = {
    results: PropTypes.arrayOf(PropTypes.any),
    queryString: PropTypes.string
};

export default WidgetsTable;
