import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CustomSelectObject } from '../../atoms';
import { toTitleCase } from '../../helpers';

class SelectInputObject extends Component {
    validate = value => {
        const { label, name } = this.props;
        const fieldName = label || toTitleCase(name);

        return value ? null : `The ${fieldName} field is required.`;
    };

    render() {
        const { name, required, ...rest } = this.props;
        const validationProps = required && {
            validate: this.validate,
            validateOnChange: true,
            validateOnMount: true
        };

        return <CustomSelectObject field={name} {...validationProps} {...rest} />;
    }
}

SelectInputObject.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool
};

export default SelectInputObject;
