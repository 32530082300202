import React, { Component } from 'react';
import { asField } from 'informed';
import PropTypes from 'prop-types';
import { SingleDatePicker } from 'react-dates';

import './injectStyles';

class DatePicker extends Component {
    state = {
        focused: false
    };

    handleChange = date => {
        const { fieldApi } = this.props;
        fieldApi.setValue(date);
    };

    onFocusChange = ({ focused }) => {
        this.setState({ focused });
    };

    render() {
        const { fieldState, ...rest } = this.props;
        const { focused } = this.state;
        const date = fieldState.value;

        return <SingleDatePicker id="single_date_picker" date={date} focused={focused} onDateChange={this.handleChange} onFocusChange={this.onFocusChange} {...rest} />;
    }
}

const AsDatePickerField = asField(DatePicker);

const DatePickerField = ({ name, ...props }) => {
    return <AsDatePickerField field={name} {...props} />;
};

DatePickerField.propTypes = {
    name: PropTypes.string.isRequired,
    hideKeyboardShortcutsPanel: PropTypes.bool,
    displayFormat: PropTypes.string,
    numberOfMonths: PropTypes.number,
    showClearDate: PropTypes.bool
};

DatePickerField.defaultProps = {
    hideKeyboardShortcutsPanel: true,
    displayFormat: 'DD/MM/YYYY',
    numberOfMonths: 1,
    showClearDate: true
};

export default DatePickerField;
