import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import config from '../config';

const AuthenticationProvider = ({ children }) => {
    const domain = config.auth.domain;
    const clientId = config.auth.clientId;
    const audience = config.api.name;

    const history = useHistory();

    const onRedirectCallback = appState => {
        history.push(appState.returnTo || window.location.pathname);
    };
    const redirectUri =
        config.auth.allowedCallbackUrl +
        (window.location.pathname !== '/' ? `&redirectTo=${encodeURIComponent(window.location.pathname)}${window.location.search}` : window.location.search);

    return (
        <Auth0Provider domain={domain} clientId={clientId} audience={audience} redirectUri={redirectUri} onRedirectCallback={onRedirectCallback}>
            {children}
        </Auth0Provider>
    );
};

export default AuthenticationProvider;
