import { Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { withRouter } from 'react-router';
import styled, { css } from 'styled-components';

import { Button, ErrorWrapper, Icon, Loading, Tab, TabList } from '../../atoms';
import config from '../../config';
import { CONTENT_BLOCK_STATUS } from '../../constants';
import { ApproveBenefitsProvider, useData, usePathForRegion } from '../../hooks';
import { ImageUpload, Panel } from '../../molecules';
import { ApiClientContext } from '../../services/Api';
import PolicyDocuments from '../SelfService/PolicyDocuments';
import EmployerBenefitsWithApproval from './EmployerBenefitsWithApproval';

const apiCall = useData.apiCall;

const useContentBlocksData = slug => {
    const { apiClient } = useContext(ApiClientContext);
    const { getEmployerPageContentBlocks, publishEmployerPageContentBlocks, orderEmployerPageContentBlocks } = apiClient;
    const { data, setData, isLoading, error: loadError } = useData(getEmployerPageContentBlocks, slug, []);
    const [apiError, setApiError] = useState(null);
    const [isApiLoading, setApiLoading] = useState(false);

    const contentBlocks = useMemo(
        () =>
            data
                .sort((a, b) => {
                    if (a.order < b.order) return -1;
                    if (a.order > b.order) return 1;
                    return 0;
                })
                .reduce(
                    (acc, current) => {
                        const section = current.sidebar ? 'sidebar' : 'body';
                        if (acc[current.pageTab]) {
                            acc[current.pageTab][section].push(current);
                        }

                        return acc;
                    },
                    {
                        about: {
                            body: [],
                            sidebar: []
                        },
                        benefits: {
                            body: [],
                            sidebar: []
                        },
                        jobs: {
                            body: [],
                            sidebar: []
                        }
                    }
                ),
        [data]
    );

    const hasDraftContentBlocks = useMemo(() => data.some(c => c.status === CONTENT_BLOCK_STATUS.indexOf('Draft')), [data]);

    const setContentBlockOrder = apiCall(
        data => {
            return orderEmployerPageContentBlocks(data.employerSlug, data.pageTab, data.sidebar, data.blockIds);
        },
        setData,
        setApiError,
        setApiLoading
    );

    const publishContentBlocks = apiCall(
        () => {
            return publishEmployerPageContentBlocks(slug);
        },
        setData,
        setApiError,
        setApiLoading
    );

    const updateContentBlock = apiCall(
        (blockId, payload) => {
            return apiClient.updateEmployerPageContentBlock(blockId, payload);
        },
        () => {},
        setApiError,
        setApiLoading
    );

    return {
        contentBlocks,
        hasDraftContentBlocks,
        isLoading,
        isApiLoading,
        setContentBlocks: setData,
        setContentBlockOrder,
        publishContentBlocks,
        updateContentBlock,
        error: loadError || apiError
    };
};

const pageTabs = [
    {
        key: 'about',
        name: 'About',
        link: '',
        blocks: ['body', 'sidebar']
    },
    {
        key: 'benefits',
        name: 'Benefits',
        link: '/benefits',
        blocks: ['body', 'sidebar', 'benefits']
    },
    {
        key: 'jobs',
        name: 'Jobs',
        link: '/jobs',
        blocks: ['body']
    }
];

const EmployerPage = ({
    data = {},
    files = {},
    employerId,
    slug,
    handleHeroImageUpload,
    handleAboutImageUpload,
    handleRemoveHeroImageProps,
    handleRemoveAboutImageProps,
    handleJobHeroImageUpload,
    handleRemoveJobHeroImageProps,
    history,
    location,
    userName
}) => {
    const params = new URLSearchParams(location.search);
    const initialPageTab = params.get('pageTab');
    const { selectedTabIndex, setSelectedTabIndex, tabs } = TabList.useTabList(pageTabs, initialPageTab);
    const { contentBlocks, hasDraftContentBlocks, isLoading, isApiLoading, setContentBlockOrder, updateContentBlock, publishContentBlocks, error } = useContentBlocksData(
        slug
    );
    const { apiClient } = useContext(ApiClientContext);
    const { getEmployerFiles, uploadEmployerFile, removeEmployerFile, downloadEmployerFile } = apiClient;

    const addBlockPath = usePathForRegion(`/employers/${employerId}/content-blocks/add`);

    const handleAddBlock = useCallback(
        (tab, sidebar) => {
            history.push({
                pathname: addBlockPath,
                search: `?employerSlug=${slug}&pageTab=${tab}&sidebar=${!!sidebar}`
            });
        },
        [addBlockPath, history, slug]
    );

    const editBlockPath = usePathForRegion(`/employers/${employerId}/content-blocks/`);

    const handleEditBlock = useCallback(
        (blockId, tab) => {
            history.push({
                pathname: `${editBlockPath}${blockId}`,
                search: `?employerSlug=${slug}&pageTab=${tab}`
            });
        },
        [editBlockPath, history, slug]
    );

    const reorder = (destinationBlocks, source, destination) => {
        const [removed] = destinationBlocks.splice(source.index, 1);
        destinationBlocks.splice(destination.index, 0, removed);

        // return the block ids in the new order
        return destinationBlocks.map(item => item.id);
    };

    const onDragEnd = useCallback(
        result => {
            const { destination, source } = result;

            // dropped outside the list
            if (!destination) {
                return;
            }

            //dropped on same column
            if (source.droppableId === destination.droppableId) {
                const [tab, section] = source.droppableId.split('-');
                const destinationBlocks = contentBlocks[tab][section];
                const blockIds = reorder(destinationBlocks, source, destination);

                setContentBlockOrder({ employerSlug: slug, pageTab: tab, sidebar: section === 'sidebar', blockIds });
            }

            // dropped on different column
            else {
                const [startTab, startSection] = source.droppableId.split('-');
                const sourceBlocks = contentBlocks[startTab][startSection];

                const [endTab, endSection] = destination.droppableId.split('-');
                const destinationBlocks = contentBlocks[endTab][endSection];

                const [removed] = sourceBlocks.splice(source.index, 1);

                const payload = {
                    ...removed,
                    modifiedBy: userName,
                    employerSlug: slug,
                    sidebar: endSection === 'sidebar',
                    pageTab: endTab
                };
                updateContentBlock(removed.id, payload);

                destinationBlocks.splice(destination.index, 0, removed);
            }
        },
        [contentBlocks, setContentBlockOrder, slug, updateContentBlock, userName]
    );

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Panel title="Employer Page">
            <ErrorWrapper error={error}>
                <ImageUpload
                    handleFileUpload={handleHeroImageUpload}
                    handleFileRemove={handleRemoveHeroImageProps}
                    currentImage={data.heroImage}
                    uploadedFilename={files.heroImageUploadInfo ? files.heroImageUploadInfo.name : ''}
                    labelText="Banner image"
                    uploadButtonText="Choose image"
                    editButtonText="Edit image"
                    dialogTitle="Edit image"
                    dialogConfirmLabel="Save image"
                    previewImageAlt="Banner Image"
                    showPreview
                    aspectRatio={5 / 3}
                    fileSizeLimit={600000}
                    infoText={'This image is used in the header of the v2 employer pages.'}
                />
                <ImageUpload
                    handleFileUpload={handleAboutImageUpload}
                    handleFileRemove={handleRemoveAboutImageProps}
                    currentImage={data.aboutImage}
                    uploadedFilename={files.aboutImageUploadInfo ? files.aboutImageUploadInfo.name : ''}
                    labelText="About image"
                    uploadButtonText="Choose image"
                    editButtonText="Edit image"
                    dialogTitle="Edit image"
                    dialogConfirmLabel="Save image"
                    previewImageAlt="About Image"
                    showPreview
                    aspectRatio={5 / 3}
                    fileSizeLimit={100000}
                    infoText={'This image is used in the about section for v2 employer pages.'}
                />
                <ImageUpload
                    handleFileUpload={handleJobHeroImageUpload}
                    handleFileRemove={handleRemoveJobHeroImageProps}
                    currentImage={data.jobHeroImage}
                    uploadedFilename={files.jobHeroImageUploadInfo ? files.jobHeroImageUploadInfo.name : ''}
                    labelText="Job Banner image"
                    uploadButtonText="Choose image"
                    editButtonText="Edit image"
                    dialogTitle="Edit image"
                    dialogConfirmLabel="Save image"
                    previewImageAlt="Banner Image"
                    showPreview
                    aspectRatio={10 / 2}
                    fileSizeLimit={600000}
                    infoText={
                        'This image is used on job pages for clients on the v2 employer pages. This image will also update the image everywhere for clients on the v1 pages.'
                    }
                />
                <TabList>
                    {tabs.map((tab, index) => (
                        <Tab key={tab.key} onSelect={() => setSelectedTabIndex(index)} id={tab.key} isSelected={index === selectedTabIndex}>
                            {tab.name}
                        </Tab>
                    ))}
                </TabList>
                <Pane>
                    {tabs.map((tab, index) => (
                        <Pane
                            key={tab.key}
                            id={`panel-${tab.key}`}
                            role="tabpanel"
                            aria-labelledby={tab.name}
                            aria-hidden={index !== selectedTabIndex}
                            display={index === selectedTabIndex ? 'block' : 'none'}
                        >
                            {tab.blocks.includes('benefits') && (
                                <>
                                    <EmployerBenefitsContainer>
                                        <ApproveBenefitsProvider id={employerId} employer={data} approver={userName}>
                                            <EmployerBenefitsWithApproval />
                                        </ApproveBenefitsProvider>
                                    </EmployerBenefitsContainer>
                                    <PolicyDocuments
                                        id={employerId}
                                        getApi={getEmployerFiles}
                                        uploadApi={uploadEmployerFile}
                                        removeApi={removeEmployerFile}
                                        downloadApi={downloadEmployerFile}
                                    />
                                </>
                            )}
                            <DragDropContext onDragEnd={onDragEnd}>
                                {tab.blocks.includes('body') && (
                                    <ContentSection
                                        headerText="Body content blocks"
                                        addButtonText="Add Body Block"
                                        blocks={contentBlocks[tab.key].body}
                                        handleAddBlock={handleAddBlock}
                                        handleEditBlock={handleEditBlock}
                                        employerId={employerId}
                                        tab={tab}
                                    />
                                )}
                                {tab.blocks.includes('sidebar') && (
                                    <ContentSection
                                        headerText="Sidebar content blocks"
                                        addButtonText="Add Sidebar Block"
                                        blocks={contentBlocks[tab.key].sidebar}
                                        handleAddBlock={handleAddBlock}
                                        handleEditBlock={handleEditBlock}
                                        employerId={employerId}
                                        tab={tab}
                                        sidebar
                                    />
                                )}
                            </DragDropContext>
                        </Pane>
                    ))}
                </Pane>
                <PageActionsWrapper>
                    <Button filled="border" to={`${config.website}for-women/employer/preview/${employerId}${pageTabs[selectedTabIndex].link}`} isExternal>
                        Preview
                    </Button>
                    {hasDraftContentBlocks && (
                        <Button onClick={publishContentBlocks} disabled={isApiLoading}>
                            Publish
                        </Button>
                    )}
                </PageActionsWrapper>
            </ErrorWrapper>
        </Panel>
    );
};

EmployerPage.propTypes = {
    data: PropTypes.object,
    files: PropTypes.object,
    employerId: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    handleHeroImageUpload: PropTypes.func.isRequired,
    handleJobHeroImageUpload: PropTypes.func.isRequired,
    handleAboutImageUpload: PropTypes.func.isRequired,
    handleRemoveHeroImageProps: PropTypes.shape({ idForRemoval: PropTypes.string, apiAction: PropTypes.func, setData: PropTypes.func }).isRequired,
    handleRemoveJobHeroImageProps: PropTypes.shape({ idForRemoval: PropTypes.string, apiAction: PropTypes.func, setData: PropTypes.func }).isRequired,
    handleRemoveAboutImageProps: PropTypes.shape({ idForRemoval: PropTypes.string, apiAction: PropTypes.func, setData: PropTypes.func }).isRequired,
    history: PropTypes.object,
    location: PropTypes.object,
    userName: PropTypes.string.isRequired
};

EmployerPage.defaultProps = {
    data: {},
    files: {}
};

const getLabel = block => {
    switch (block.contentType) {
        case 'text':
            return block.content.name;
        case 'quote':
            return block.content.quoteeName;
        case 'video':
            return block.content.heading;
        case 'employeeprofile':
            return block.content.heading;
        case 'endorsedemployer':
            return block.content.name;
        case 'endorsements':
            return block.content.heading;
        case 'event':
            return block.content.name;
        case 'jobalert':
            return 'Job alert';
        default:
            return '';
    }
};

const contentIcons = {
    text: 'FileAlt',
    quote: 'QuoteRight',
    video: 'Film',
    employeeprofile: 'User',
    endorsedemployer: 'Award',
    endorsements: 'Award',
    event: 'Calendar',
    jobalert: 'EnvelopeOpenText'
};

// Drag and drop styles
const getItemStyle = (isDragging, draggableStyle) => ({
    display: 'flex',
    userSelect: 'none',
    padding: 16,
    boxShadow: isDragging ? '0px 0px 4px 2px #f4f4f4' : 'none',
    background: '#ffffff',
    marginBottom: 4,
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#d8d8d8' : '#f1f1f1',
    minHeight: 58,
    padding: 8
});

const ContentSection = ({ headerText, addButtonText, blocks, handleAddBlock, handleEditBlock, sidebar, tab }) => {
    return (
        <ContentSectionWrapper>
            <ContentSectionLeft>
                <ContentTitle>{headerText}</ContentTitle>
            </ContentSectionLeft>
            <ContentSectionRight>
                {blocks.length > 0 && (
                    <ContentBlockPlaceholder>
                        <Icon icon="InfoCircle" /> Reordering blocks will affect your published page
                    </ContentBlockPlaceholder>
                )}
                <ContentSectionHeader>
                    <ContentTitle leftColumn>Type</ContentTitle>
                    <ContentTitle rightColumn>Name</ContentTitle>
                </ContentSectionHeader>
                <ContentSectionBody>
                    <Droppable droppableId={`${tab.key}-${sidebar ? 'sidebar' : 'body'}`}>
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                {blocks.map((block, index) => (
                                    <Draggable key={block.id} draggableId={block.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                onClick={() => handleEditBlock(block.id, tab.key)}
                                                onKeyDown={e => e.keyCode === 13 && handleEditBlock(block.id, tab.key)}
                                                role="button"
                                                tabIndex={0}
                                            >
                                                <ContentTitle leftColumn>
                                                    {contentIcons[block.contentType] && <Icon icon={contentIcons[block.contentType]} />}
                                                    {!contentIcons[block.contentType] && block.ContentType}
                                                </ContentTitle>
                                                <ContentBlockLabel>
                                                    <span>
                                                        {block.status === CONTENT_BLOCK_STATUS.indexOf('Draft') && (
                                                            <Draft>
                                                                <Icon icon="Circle" title="Unpublished changes" />
                                                            </Draft>
                                                        )}
                                                        <ContentBlockLabelText>{getLabel(block)}</ContentBlockLabelText>
                                                    </span>
                                                    <Icon icon="GripLines" style={{ color: '#a7a7a7' }} />
                                                </ContentBlockLabel>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {blocks.length === 0 && <ContentBlockPlaceholder>None created yet. Go ahead and add one!</ContentBlockPlaceholder>}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </ContentSectionBody>
                <div>
                    <Button filled="border" size="mini" type="button" onClick={() => handleAddBlock(tab.key, sidebar)}>
                        {addButtonText}
                    </Button>
                </div>
            </ContentSectionRight>
        </ContentSectionWrapper>
    );
};

ContentSection.propTypes = {
    headerText: PropTypes.string.isRequired,
    addButtonText: PropTypes.string.isRequired,
    blocks: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, contentTypes: PropTypes.string })),
    handleAddBlock: PropTypes.func.isRequired,
    handleEditBlock: PropTypes.func.isRequired,
    tab: PropTypes.shape({ key: PropTypes.string, name: PropTypes.string }).isRequired,
    sidebar: PropTypes.bool
};

ContentSection.defaultProps = {
    blocks: [],
    sidebar: false
};

const ContentSectionWrapper = styled.div`
    display: flex;
    margin-bottom: 2rem;
    width: 100%;
`;

const ContentSectionHeader = styled.div`
    display: flex;
    flex-direction: row;
`;

const ContentSectionBody = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
`;

const ContentSectionLeft = styled.div`
    width: 30%;
`;

const ContentSectionRight = styled.div`
    width: 70%;
`;

const leftColumnStyle = css`
    flex: 0;
    min-width: 50px;
    ${ContentSectionHeader} & {
        min-width: 66px;
    }
`;

const rightColumnStyle = css`
    flex: 1;
`;

const ContentTitle = styled.div`
    font-weight: 600;
    ${props => props.leftColumn && leftColumnStyle}
    ${props => props.rightColumn && rightColumnStyle}
`;

const ContentBlockLabel = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${rightColumnStyle}
`;

const ContentBlockLabelText = styled.span`
    &:hover {
        cursor: pointer;
        text-decoration: underline;
        font-weight: 600;
    }
`;

const Draft = styled.span`
    color: #0890a8;
    font-weight: 400;
    margin-right: 0.2rem;
`;

const ContentBlockPlaceholder = styled.span`
    color: #a7a7a7;
    margin-bottom: 1rem;
    display: block;
`;

const PageActionsWrapper = styled.div`
    text-align: end;

    button:last-child {
        margin-left: 1rem;
    }
`;

const EmployerBenefitsContainer = styled.div`
    padding-bottom: 30px;
    border-bottom: 0.0625rem solid #e1e1e1;
    margin-bottom: 20px;
`;

export default withRouter(EmployerPage);
