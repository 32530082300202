import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Checkbox } from 'informed';
import StyledCheckbox from '../StyledCheckbox';

class CheckboxInput extends Component {
    render() {
        const { initialValue, name, errors, ...rest } = this.props;
        return (
            <Wrapper>
                <HiddenInput initialValue={initialValue} field={name} errors={errors} aria-invalid={!!errors && !!errors.length} tabIndex="0" {...rest} />
                <StyledCheckbox />
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    position: relative;

    input[type='checkbox'] {
        &:focus + div::before {
            content: '';
        }

        &:checked + div > svg {
            display: block;
        }
    }
`;

const HiddenInput = styled(Checkbox)`
    position: absolute;
    left: -1rem;
    opacity: 0;
`;

CheckboxInput.propTypes = {
    name: PropTypes.string.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    initialValue: PropTypes.any
};

export default CheckboxInput;
