import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { EmployerBenefitsStatement, StatusUpdateCategory } from '../../molecules';
import { Icon } from '../../atoms';

const EmployerBenefitsCategory = ({ category, formState, handleSubmit, isAllExpanded }) => {
    const [isExpanded, setIsExpanded] = useState(isAllExpanded);

    useEffect(() => {
        setIsExpanded(isAllExpanded);
    }, [isAllExpanded]);

    const toggleExpand = () => setIsExpanded(!isExpanded);

    return (
        <>
            <CategoryHeader onClick={toggleExpand}>
                <CategoryContent>
                    <CategoryTitle>{category.category}</CategoryTitle>
                    <Icon icon={isExpanded ? 'ChevronUp' : 'ChevronDown'} />
                </CategoryContent>
                <StatusUpdateCategory show={!isExpanded} category={category} formState={formState} />
            </CategoryHeader>

            <EmployerBenefitsStatementWrapper hide={isExpanded}>
                {category.statements.map(statement => (
                    <EmployerBenefitsStatement key={statement.id} formState={formState} handleSubmit={handleSubmit} isExpanded={isExpanded} statement={statement} />
                ))}
            </EmployerBenefitsStatementWrapper>
        </>
    );
};

const CategoryContent = styled.div`
    flex-grow: 4;
    display: flex;
    justify-content: space-between;
`;

const CategoryHeader = styled.div.attrs({
    role: 'row'
})`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    padding-top: 15px;
    padding-bottom: 15px;
`;

const CategoryTitle = styled.h4`
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    display: inline-block;
`;

const EmployerBenefitsStatementWrapper = styled.div.attrs({
    role: 'rowgroup'
})`
    display: ${props => (props.hide ? 'block' : 'none')};
    margin-top: 20px;
`;

EmployerBenefitsCategory.propTypes = {
    category: PropTypes.any,
    formState: PropTypes.any,
    handleSubmit: PropTypes.any,
    isAllExpanded: PropTypes.any
};

export default EmployerBenefitsCategory;
