import React from 'react';
import { Icon } from '../../atoms';
import styled, { keyframes } from 'styled-components';

const Spinner = ({ ...rest }) => {
    return <SpinnerIcon {...rest} />;
};

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const SpinnerIcon = styled(Icon).attrs({
    icon: 'Spinner'
})`
    opacity: 0.5;
    animation: ${rotate} 2s linear infinite;
`;

export default Spinner;
