import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useData, useEmployerBenefits } from './index';
import { ApiClientContext } from '../services';
import { EmployerBenefitsProvider } from './useEmployerBenefits';

const CompleteBenefitsContext = createContext();

const CompleteBenefitsProvider = ({ children, id }) => {
    const [complete, setComplete] = useState(false);
    const [completeError, setCompleteError] = useState(null);
    const [completeLoading, setCompleteLoading] = useState(false);
    const { publicApiClient: apiClient } = useContext(ApiClientContext);

    const value = useMemo(
        () => ({
            complete,
            setComplete,
            completeError,
            setCompleteError,
            completeLoading,
            setCompleteLoading
        }),
        [complete, completeError, completeLoading]
    );

    return (
        <EmployerBenefitsProvider id={id} getApi={apiClient.getEmployerBenefitsSelfService} saveApi={apiClient.saveEmployerBenefitsSelfService} isSelfService={true}>
            <CompleteBenefitsContext.Provider value={value}>{children}</CompleteBenefitsContext.Provider>
        </EmployerBenefitsProvider>
    );
};

const useCompleteBenefits = () => {
    const { benefits, id } = useEmployerBenefits();
    const { publicApiClient: apiClient } = useContext(ApiClientContext);

    const completeContext = useContext(CompleteBenefitsContext);
    if (completeContext === undefined) {
        throw new Error('useCompleteBenefits must be used within a CompleteBenefitsContext');
    }
    const { complete, setComplete, completeError, setCompleteError, completeLoading, setCompleteLoading } = completeContext;

    const allResponses = useMemo(() => {
        if (!benefits) {
            return [];
        }
        const main = benefits.flatMap(x => x.statements).flatMap(x => x.responses);
        const followup = benefits
            .flatMap(x => x.statements)
            .flatMap(x => x.followUpStatements)
            .flatMap(x => x.responses);
        return [...main, ...followup];
    }, [benefits]);

    const canSaveOrCompleteBenefits = useMemo(() => {
        if (!allResponses.length) {
            return false;
        }
        return allResponses.some(response => response.status === 'Draft');
    }, [allResponses]);

    const completeBenefits = useCallback(() => {
        if (!canSaveOrCompleteBenefits) {
            return null;
        }
        const responseIds = allResponses.map(x => x.id);
        const payload = { responses: responseIds };
        useData.apiCall(apiClient.completeEmployerBenefitsSelfService, () => setComplete(true), setCompleteError, setCompleteLoading)(id, payload);
    }, [canSaveOrCompleteBenefits, allResponses, apiClient.completeEmployerBenefitsSelfService, setCompleteError, setCompleteLoading, id, setComplete]);

    return useMemo(
        () => ({
            complete,
            completeError,
            completeLoading,
            completeBenefits,
            canSaveOrCompleteBenefits
        }),
        [complete, completeError, completeLoading, completeBenefits, canSaveOrCompleteBenefits]
    );
};

export { CompleteBenefitsProvider, useCompleteBenefits };
