import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const HamburgerButton = ({ toggleState, ...rest }) => {
    return (
        <Btn onClick={() => toggleState('isSidebarVisible')} {...rest}>
            <Bar />
        </Btn>
    );
};

const Btn = styled.button`
    background: none;
    border: none;
    padding: 0;
    margin-right: 1rem;
    width: 1.75rem;
    cursor: pointer;

    &:focus {
        outline: none;

        span {
            &,
            &:before,
            &:after {
                background-color: #ffc845;
            }
        }
    }
`;

const Bar = styled.span`
    &,
    &:before,
    &:after {
        display: block;
        height: 0.125rem;
        background-color: #ffffff;
        content: '';
    }

    &:before {
        transform: translateY(-0.625rem);
    }

    &:after {
        transform: translateY(0.5rem);
    }
`;

HamburgerButton.propTypes = {
    toggleState: PropTypes.func.isRequired
};

export default HamburgerButton;
