/* eslint-disable @typescript-eslint/no-unused-vars */
/* Import TinyMCE */
import tinymce from 'tinymce';

/* Default icons are required. After that, import custom icons if applicable */
import 'tinymce/icons/default';

/* Required TinyMCE components */
import 'tinymce/themes/silver';
import 'tinymce/models/dom';

/* Import a skin (can be a custom skin instead of the default) */
import 'tinymce/skins/ui/oxide/skin.css';

/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';

/* Import premium plugins */
/* NOTE: Download separately and add these to /src/plugins */
/* import './plugins/checklist/plugin'; */
/* import './plugins/powerpaste/plugin'; */
/* import './plugins/powerpaste/js/wordimport'; */

/* content UI CSS is required */
import contentUiSkinCss from 'tinymce/skins/ui/oxide/content.css';

/* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
import contentCss from 'tinymce/skins/content/default/content.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { asField } from 'informed';

import config from '../../config';
import { DEFAULT_FILE_SIZE_LIMIT } from '../../constants';
const Wysiwyg = asField(({ fieldState, fieldApi, ...props }) => {
    const { value } = fieldState;
    const { setValue, setTouched } = fieldApi;
    const {
        onChange,
        onBlur,
        initialValue,
        forwardedRef,
        required,
        errors,
        handleBlur,
        handleSubmit,
        handleEditorChange,
        handleFileUpload,
        plugins,
        toolbar,
        ...rest
    } = props;

    return (
        <Editor
            apiKey={config.tinymce.apiKey}
            value={value || initialValue || ''}
            ref={forwardedRef}
            onEditorChange={e => {
                setValue(e);
                if (handleEditorChange) handleEditorChange();
            }}
            onBlur={() => {
                setTouched();
                if (handleBlur) handleBlur();
            }}
            init={{
                min_height: 500,
                plugins,
                toolbar,
                menubar: true,
                browser_spellcheck: true,
                image_title: true,
                image_adv_tab: true,
                images_upload_handler(blobInfo, successCb, errorCb) {
                    const blob = blobInfo.blob();
                    const size = blob.size;

                    if (size <= DEFAULT_FILE_SIZE_LIMIT) {
                        handleFileUpload(blobInfo.base64(), blobInfo.filename(), blob.type)
                            .then(
                                location => successCb(location),
                                error => errorCb(error)
                            )
                            .catch(error => errorCb(error));
                    } else {
                        errorCb('Your image is too large. Please optimise the file size and re-upload.');
                    }
                }
            }}
            style={{ width: '100%' }}
            {...rest}
        />
    );
});

Wysiwyg.propTypes = {
    initialValue: PropTypes.string,
    plugins: PropTypes.string,
    toolbar: PropTypes.string
};

Wysiwyg.defaultProps = {
    plugins: 'lists link image media imagetools paste fullscreen code table',
    toolbar:
        'undo redo | formatselect | bold italic blockquote | alignleft aligncenter alignright | numlist bullist | table | link unlink image media | removeformat | pastetext | fullscreen'
};

export default Wysiwyg;
