import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { asField } from 'informed';

import { Icon, Link } from '../../atoms';
import { resetUl } from '../../styles';

class SelectInputObject extends Component {
    containerRef = React.createRef();
    labelRef = React.createRef();

    state = {
        isOpen: false,
        searchText: ''
    };

    toggleMenu = value => {
        this.setState(
            {
                isOpen: value,
                searchText: ''
            },
            () => {
                const { onBlur, onSearch, errors } = this.props;
                const { isOpen, searchText } = this.state;
                if (!isOpen && !errors && onBlur) {
                    onBlur();
                }
                onSearch && onSearch(searchText);
            }
        );
    };

    handleSelection = optionId => {
        const { fieldApi } = this.props;
        const { setValue } = fieldApi;
        setValue(optionId);
        this.toggleMenu(false);
    };

    handleMultiSelection = optionId => {
        const { fieldState, fieldApi } = this.props;
        const { value } = fieldState;
        const { setValue } = fieldApi;

        let newValue = !value ? [] : value;
        const isExisting = newValue.includes(optionId);

        if (isExisting) {
            newValue = newValue.filter(o => o !== optionId);
        } else {
            newValue = [...newValue, optionId];
        }
        this.setState({ searchText: '' });
        this.toggleMenu(false);
        setValue(newValue);
    };

    handleChange = optionId => {
        const { multiple } = this.props;
        if (multiple) {
            this.handleMultiSelection(optionId);
        } else {
            this.handleSelection(optionId);
        }
    };

    handleSearch = e => {
        this.setState({ searchText: e.target.value });
        this.props.onSearch && this.props.onSearch(e.target.value);
    };

    isSelected = optionId => {
        const { fieldState, multiple } = this.props;
        const { value } = fieldState;

        if (!value) {
            return false;
        }

        if (multiple) {
            return value.includes(optionId);
        }

        return value === optionId;
    };

    renderLabel = () => {
        const { fieldState, label, multiple, multipleText, displayNameAsLabel, options } = this.props;
        const { value } = fieldState;

        const renderValue =
            displayNameAsLabel && value ? options.filter(x => (Array.isArray(value) ? value.includes(x.id) : value === x.id)).map(x => x.name) : value && value.id;

        if (!renderValue || !renderValue.length) {
            return (
                <span>
                    Choose {label ? label.toLowerCase() : ''}
                    {multiple && multipleText && <span style={{ color: '#999999' }}> {multipleText}</span>}
                </span>
            );
        }

        if (multiple) {
            return (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {renderValue.map(v => {
                        return (
                            <Tag
                                key={v}
                                onClick={e => {
                                    // Prevent the menu from being toggled when clicking on a tag
                                    e.stopPropagation();
                                    this.handleChange(v);
                                }}
                            >
                                {v}
                                <Icon icon="Times" style={{ marginLeft: '8px' }} />
                            </Tag>
                        );
                    })}
                </div>
            );
        }

        return Array.isArray(renderValue) ? renderValue.join(',') : renderValue;
    };

    // Auto-select the first option if the id or name matches
    handleKeyDown = e => {
        const { searchText } = this.state;
        const { options } = this.props;
        if (e.key === 'Enter') {
            if (searchText && options.length > 0) {
                const lowerSearchText = searchText.toLowerCase();
                if (lowerSearchText === options[0].id.toLowerCase() || lowerSearchText === options[0].name.toLowerCase()) {
                    this.handleChange(options[0].id);
                }
            }
            // Prevent automatic form submission
            e.preventDefault();
        }
    };

    handleClickOutside = e => {
        const isOutsideClick = this.containerRef.current ? !this.containerRef.current.contains(e.target) : false;
        if (this.state.isOpen && isOutsideClick) {
            this.toggleMenu(false);
        }
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    render() {
        const { isOpen } = this.state;
        const { onSearch, searchPlaceholder, options, disabled, errors } = this.props;

        return (
            <Container innerRef={this.containerRef}>
                <Label innerRef={this.labelRef} onClick={() => this.toggleMenu(!isOpen)} errors={errors} disabled={disabled}>
                    {this.renderLabel()}
                    <Icon icon={isOpen ? 'ChevronUp' : 'ChevronDown'} />
                </Label>
                <Ul isOpen={isOpen}>
                    {!!onSearch && (
                        <SearchBox>
                            <Input value={this.state.searchText} onChange={this.handleSearch} placeholder={searchPlaceholder} onKeyDown={this.handleKeyDown} />
                        </SearchBox>
                    )}
                    {options.map(option => {
                        return (
                            <Li key={option.id} onClick={() => this.handleChange(option)}>
                                <Option isSelected={this.isSelected(option)}>
                                    {option.name}
                                    {option.status ? option.status : null}
                                    {option.url ? (
                                        <Link to={option.url} isExternal target="_blank">
                                            link
                                        </Link>
                                    ) : null}
                                </Option>
                            </Li>
                        );
                    })}
                </Ul>
            </Container>
        );
    }
}

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
`;

const Label = styled.div`
    cursor: hover;
    font-family: 'Open Sans';
    margin: 0;
    padding: 0 1rem;
    min-height: 40px;
    font-size: 0.875rem;
    line-height: 1.125rem;
    background-color: ${props => (props.disabled ? '#F1F1F1' : '#FFFFFF')};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')}
    border: 1px solid #e1e1e1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    box-sizing: border-box;
    max-width: 100%;
    text-align: left;

    ${props => props.errors && props.errors.length && 'border-color: #000000'};

    &:focus {
        border-color: #ffc845;
        outline: none;
    }
`;

const SearchBox = styled.li`
    margin: 1rem;
`;

const Input = styled.input`
    font-family: 'Open Sans';
    padding: 0.6875rem 1rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    background-color: #ffffff;
    height: 2.5rem;
    width: 100%;

    &:focus {
        border-color: #ffc845;
        outline: none;
    }
`;

const Tag = styled.span`
    display: flex;
    align-items: center;
    background: #ffc845;
    color: #484848;
    padding: 4px;
    border-radius: 8px;
    margin: 6px 4px 6px 0;

    &:hover {
        cursor: pointer;
        color: #000000;
    }
`;

const Ul = styled.ul`
    ${resetUl};
    padding: 0.25rem 0;
    border: 0.0625rem solid #e1e1e1;
    border-top: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    background: #ffffff;
    max-height: 13.75rem;
    overflow: auto;
    ${props => !props.isOpen && 'display: none'};

    &:focus {
        border-color: #ffc845;
    }
`;

const Li = styled.li`
    display: flex;
`;

const Option = styled.span`
    cursor: pointer;
    font-family: 'Open Sans';
    font-size: 0.875rem;
    text-align: left;
    flex-grow: 1;
    padding: 0.6rem 1rem;
    margin: 0.125rem 0.25rem;
    border: 0.0625rem solid transparent;

    ${props => props.isSelected && 'background-color: #FFC845'};

    &:focus {
        border-color: #ffc845;
        outline: none;
    }

    &:hover {
        background-color: rgba(255, 200, 69, 0.5);
    }
`;

SelectInputObject.propTypes = {
    field: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
        })
    ).isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    multiple: PropTypes.bool,
    multipleText: PropTypes.string,
    onSearch: PropTypes.func,
    searchPlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
    fieldState: PropTypes.object,
    fieldApi: PropTypes.object,
    onBlur: PropTypes.func,
    displayNameAsLabel: PropTypes.bool,
    label: PropTypes.string
};

SelectInputObject.defaultProps = {
    multipleText: '(up to 3)'
};

export default asField(SelectInputObject);
