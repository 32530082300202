import { css } from 'styled-components';

export const resetUl = css`
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const resetButton = css`
    background: transparent;
    border: none;
    border-radius: 0;
    cursor: pointer;
    padding: 0;
    font-family: inherit;
    font-size: inherit;
`;

export const disabledStyles = css`
    color: #e9e9e9;
    pointer-events: none;
`;

export const truncatedStyles = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
