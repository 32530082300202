import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Scope } from 'informed';
import styled from 'styled-components';

import { Field } from '../../atoms';
import { combineErrors, validateUrl } from '../../helpers';

const VideoContentBlockFields = ({ errors, formState, isSubmitting }) => {
    const formErrors = {};
    const contentTouched = formState.touched.content || {};
    const contentErrors = formState.errors.content;
    if (contentErrors) {
        formErrors.heading = combineErrors(errors.Heading, contentErrors.heading, contentTouched.heading);
        formErrors.videoUrl = combineErrors(errors.VideoUrl, contentErrors.videoUrl, contentTouched.videoUrl);
        formErrors.intro = combineErrors(errors.Intro, contentErrors.intro, contentTouched.intro);
    }

    return (
        <Fragment>
            <Scope scope="content">
                <Field label="Heading" name="heading" required disabled={isSubmitting} errors={formErrors.heading} fieldType="text" />
                <Field
                    label="Video URL"
                    name="videoUrl"
                    includeBefore={<Url>e.g. http://video-url.com</Url>}
                    required
                    disabled={isSubmitting}
                    errors={formErrors.videoUrl}
                    validate={validateUrl}
                    fieldType="text"
                />
                <Field label="Video intro" name="intro" required disabled={isSubmitting} errors={formErrors.intro} fieldType="text" rows={4} />
            </Scope>
        </Fragment>
    );
};

VideoContentBlockFields.propTypes = {
    formState: PropTypes.object.isRequired,
    errors: PropTypes.object,
    isSubmitting: PropTypes.bool
};

VideoContentBlockFields.defaultProps = {
    isSubmitting: false,
    errors: {}
};

const Url = styled.span`
    opacity: 0.5;
    margin-right: -0.5rem;
`;

export default VideoContentBlockFields;
