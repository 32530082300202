import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text } from 'informed';

import { toTitleCase } from '../../helpers';

class EmailInput extends Component {
    validateRequiredField = (value = '') => {
        const { label, name } = this.props;
        const fieldName = label || toTitleCase(name);
        return value.trim() ? undefined : `The ${fieldName} field is required.`;
    };

    validateEmailField = (value = '') => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(value) ? ['Please enter a valid email address'] : undefined;
    };

    validate = value => {
        return this.validateRequiredField(value) || this.validateEmailField(value);
    };

    render() {
        const { name, initialValue, errors, required, ...rest } = this.props;
        const mainProps = {
            type: 'email',
            field: name,
            initialValue,
            errors
        };
        const validationProps = required && {
            validate: this.validate,
            validateOnChange: true,
            validateOnMount: true
        };

        return <Input {...mainProps} aria-invalid={!!errors && !!errors.length} {...validationProps} {...rest} />;
    }
}

const Input = styled(Text)`
    font-family: 'Open Sans';
    padding: 0.6875rem 1rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    border: 1px solid #e1e1e1;
    flex-grow: 1;
    box-sizing: border-box;
    max-width: 100%;
    height: 2.5rem;

    ${props => props.errors && props.errors.length && 'border-color: #000000'};

    &:focus {
        border-color: #ffc845;
        outline: none;
    }
`;

EmailInput.propTypes = {
    /** used to create the form state */
    name: PropTypes.string.isRequired,
    /** used to style the input as invalid */
    errors: PropTypes.arrayOf(PropTypes.string),
    /** is this a required input? */
    required: PropTypes.bool,
    label: PropTypes.string,
    initialValue: PropTypes.any
};

export default EmailInput;
