import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Tbody } from '../../molecules';
import { Button, Td, Th } from '../../atoms';
import { truncatedStyles } from '../../styles';
import { Icon } from 'evergreen-ui/commonjs/icon';
import { usePathForRegion } from '../../hooks';
import { Icon as CustomIcon } from '../../atoms';
import { GENDER_BIAS } from '../../constants';

const JobsTable = ({ results, queryString, ...rest }) => {
    const redirectLocation = usePathForRegion(`/jobs/all${queryString}`);

    return (
        <Container {...rest}>
            <Table>
                <thead>
                    <tr>
                        <Th colSpan="2">Job</Th>
                        <Th colSpan="2">Employer</Th>
                        <Th colSpan="1">Location</Th>
                        <Th colSpan="2">Gender Bias</Th>
                    </tr>
                </thead>
                <Tbody>
                    {results.map(job => {
                        const jobLinkProps = {
                            pathname: `/jobs/${job.id}`,
                            state: { redirectLocation }
                        };

                        let location = job.locations.length > 3 ? 'Multiple locations' : job.locations && job.locations.map(item => item.label).join(', ');

                        const employerUrl = job.employer && job.employer.id && `/employers/${job.employer.id}`;
                        return (
                            <tr key={job.id}>
                                <Td to={jobLinkProps}>{!!job.featured && <Icon icon="star" color="warning"></Icon>}</Td>
                                <Td to={jobLinkProps} primary="true">
                                    {job.title}
                                    {!!job.reference && (
                                        <Smaller>
                                            Reference Number {job.reference} {job.status === 1 ? ' - ' : ''}
                                        </Smaller>
                                    )}
                                    {job.status === 1 && <Smaller>Expired</Smaller>}
                                </Td>
                                <Td to={employerUrl}>{job.employer && job.employer.name}</Td>
                                <Td to={employerUrl} tabIndex="-1">
                                    {job.employer && job.employer.logo && (
                                        <Logo>
                                            <img src={job.employer.logo.split(' ').join('%20')} alt={`${job.employer.name} logo`} />
                                        </Logo>
                                    )}
                                </Td>
                                <Td to={jobLinkProps}>{location}</Td>
                                {!job.genderBias && <Td>&nbsp;</Td>}
                                {job.genderBias && (
                                    <Td>
                                        <CustomIcon icon={GENDER_BIAS[job.genderBias.coding]} />
                                    </Td>
                                )}
                                <BtnCell>
                                    <Button to={jobLinkProps} size="mini" filled="border">
                                        Edit
                                    </Button>
                                </BtnCell>
                            </tr>
                        );
                    })}
                </Tbody>
            </Table>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const Logo = styled.div`
    width: 4rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 4rem;
        max-height: 3rem;
    }
`;

const BtnCell = styled(Td)`
    width: 5rem;

    > * {
        padding: 0 1rem;
    }
`;

const Smaller = styled.div`
    ${truncatedStyles};
    font-size: 0.75rem;
    font-weight: 500;
    color: #999999;
    max-width: 14rem;
`;

JobsTable.propTypes = {
    results: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            reference: PropTypes.string,
            locations: PropTypes.array,
            employer: PropTypes.object
        })
    ),
    queryString: PropTypes.string,
    isExpired: PropTypes.bool
};

export default JobsTable;
