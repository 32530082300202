import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'cropperjs';
import styled from 'styled-components';
import 'cropperjs/dist/cropper.css';

import Icon from '../Icon';
import Placeholder from '../Placeholder';

class ImageCropper extends Component {
    _imgRef = React.createRef();
    _cropperInstance;

    // Track the zoom ratio from cropper to keep the range slider in sync
    state = { zoomRatio: 0 };

    componentDidMount() {
        this.initCropper();
    }

    componentWillUnmount() {
        this._cropperInstance && this._cropperInstance.destroy();
        this._imgRef && this._imgRef.current && this._imgRef.current.removeEventListener('ready', this.handleImageReady);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.imageSrc !== this.props.imageSrc) {
            this.initCropper();
        }
    }

    initCropper() {
        const { aspectRatio, guides, zoomOnWheel } = this.props;
        this._cropperInstance && this._cropperInstance.destroy();

        // Set the initial zoom ratio to match the image data
        this._imgRef.current && this._imgRef.current.addEventListener('ready', this.handleImageReady);

        this._cropperInstance = new Cropper(this._imgRef.current, {
            guides,
            autoCropArea: 1,
            viewMode: 0,
            zoomOnWheel,
            aspectRatio
        });
    }

    handleImageReady = () => {
        const imageData = this._cropperInstance.getImageData();
        const zoomRatio = imageData.width / imageData.naturalWidth;
        this.props.setImageReady(true);
        this.setState({ zoomRatio });
    };

    handleZoom = e => {
        if (this.props.imageReady) {
            this._cropperInstance && this._cropperInstance.zoomTo(e.target.value);
            this.setState({ zoomRatio: e.target.value });
        }
    };

    render() {
        const { imageSrc, viewportShape, imageReady } = this.props;
        const { zoomRatio } = this.state;

        return (
            <React.Fragment>
                <Container viewportShape={viewportShape}>
                    <CropImageContainer imageReady={imageReady}>
                        <CropImage innerRef={this._imgRef} src={imageSrc} style={{ visibility: imageReady ? 'visible' : 'hidden' }} />
                        {!imageReady && <Placeholder />}
                    </CropImageContainer>
                </Container>
                {imageReady && (
                    <ZoomContainer>
                        <Icon icon="SearchMinus" size="sm" color="#66788a" />
                        <InputRange onChange={this.handleZoom} value={zoomRatio} />
                        <Icon icon="SearchPlus" size="sm" color="#66788a" />
                    </ZoomContainer>
                )}
            </React.Fragment>
        );
    }
}

// State hook for managing image crop data
function useImageCropper() {
    return useState({ imageURL: null, imageType: null, name: '' });
}

ImageCropper.useImageCropper = useImageCropper;

ImageCropper.defaultProps = {
    viewportShape: 'square',
    guides: false,
    zoomOnWheel: false,
    aspectRatio: null
};

ImageCropper.propTypes = {
    /** Url or data url of the image to crop */
    imageSrc: PropTypes.string.isRequired,
    /** Show a square or circle viewport as a cropping guide */
    viewportShape: PropTypes.oneOf(['square', 'circle']),
    /** Show positional guides in the cropping viewport */
    guides: PropTypes.bool,
    /** Enable or Disable using the mousewheel to zoom */
    zoomOnWheel: PropTypes.bool,
    /** Restrict the viewport to a specific aspect ratio */
    aspectRatio: PropTypes.number,
    imageReady: PropTypes.bool,
    setImageReady: PropTypes.func
};

const Container = styled.div`
    overflow: hidden;
    padding: 16px;

    .cropper-modal {
        background: transparent;
    }

    .cropper-crop-box {
        box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2000px 2000px;
        border-radius: ${props => (props.viewportShape === 'circle' ? '50%' : '0')};
    }

    .cropper-line {
        background-color: #ffc845;
    }

    .cropper-point {
        background-color: #ffc845;
        height: 6px;
        width: 6px;
    }

    .cropper-view-box {
        outline: 2px solid #ffc845;
        outline-color: rgba(255, 200, 69, 0.75);
        border-radius: ${props => (props.viewportShape === 'circle' ? '50%' : '0')};
    }

    @media (min-width: 1200px) {
        .cropper-point.point-se {
            height: 8px;
            opacity: 0.75;
            width: 8px;
        }
    }
`;

const CropImage = styled.img`
    max-width: 100%;
`;

const CropImageContainer = styled.div`
    position: relative;
    min-height: 200px;
    max-height: 300px;
`;

const ZoomContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 16px auto 0 auto;
    max-width: 300px;
`;

const InputRange = styled.input.attrs({
    type: 'range',
    min: 0.1,
    max: 10,
    step: 0.1
})`
    width: 100%;
    margin-right: 16px;
    margin-left: 16px;
`;

export default ImageCropper;
