import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Scope } from 'informed';

import { Label, Field, TextEditorInput } from '../../atoms';
import { combineErrors } from '../../helpers';

const TextContentBlockFields = ({ errors, formState, isSubmitting, textEditorRef, handleFileUpload }) => {
    const formErrors = {};
    const contentTouched = formState.touched.content || {};
    const contentErrors = formState.errors.content;
    if (contentErrors) {
        formErrors.name = combineErrors(errors.Name, contentErrors.name, contentTouched.name);
        formErrors.textContent = combineErrors(errors.TextContent, contentErrors.textContent, contentTouched.textContent);
    }

    return (
        <Fragment>
            <Scope scope="content">
                <Field
                    label="Name"
                    name="name"
                    required
                    disabled={isSubmitting}
                    errors={formErrors.name}
                    fieldType="text"
                    extraDetail="Name is an internal reference. It is not displayed on the employer page."
                />
                <Label label="Content" useColumnLayout required />
                <TextEditorInput
                    forwardedRef={textEditorRef}
                    name="textContent"
                    label="Content"
                    required
                    disabled={isSubmitting}
                    errors={formErrors.textContent}
                    handleFileUpload={handleFileUpload}
                />
            </Scope>
        </Fragment>
    );
};

TextContentBlockFields.propTypes = {
    formState: PropTypes.object.isRequired,
    errors: PropTypes.object,
    isSubmitting: PropTypes.bool,
    textEditorRef: PropTypes.object,
    handleFileUpload: PropTypes.func
};

TextContentBlockFields.defaultProps = {
    handleFileUpload: () => {},
    isSubmitting: false,
    errors: {}
};

export default TextContentBlockFields;
