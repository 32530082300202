import React, { Fragment, useContext, useState } from 'react';
import { Panel } from '../../molecules';
import { Button, Label } from '../../atoms';
import PropTypes from 'prop-types';
import { useData } from '../../hooks';
import { ApiClientContext } from '../../services';
import { useAuthorization } from '../../auth';

const EmployerSelfService = props => {
    const { slug } = props;
    const { apiClient } = useContext(ApiClientContext);
    const { isSuper } = useAuthorization();
    const [selfServiceLink, setSelfServiceLink] = useState('');
    const response = useData(apiClient.getEmployerSelfServiceLink, slug, null);

    if (response.data !== null && selfServiceLink === '') {
        setSelfServiceLink(response.data);
    }

    const generateSSL = () => {
        apiClient.createEmployerSelfServiceLink(slug);
        window.location.reload(false);
    };

    return (
        <Panel title="Self Service Link">
            {!selfServiceLink && (
                <Label label="Self Service Link">
                    <p>No Self service link has been generated</p>
                </Label>
            )}
            {selfServiceLink && (
                <Label label="Self Service Link">
                    <a href={selfServiceLink}>{selfServiceLink}</a>
                </Label>
            )}
            {isSuper && !selfServiceLink && (
                <Fragment>
                    <Button type="submit" onClick={generateSSL}>
                        Generate SSL
                    </Button>
                </Fragment>
            )}
        </Panel>
    );
};

EmployerSelfService.propTypes = {
    slug: PropTypes.string.isRequired,
    data: PropTypes.string
};

EmployerSelfService.defaultProps = {
    data: {}
};

export default EmployerSelfService;
