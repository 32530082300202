import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { UserMenu, RegionMenu } from '../../molecules';
import { Logo, HamburgerButton } from '../../atoms';
import config from '../../config';

const Header = ({ toggleState, includeUserMenu, region, isClient, ...rest }) => {
    return (
        <Banner>
            <Group>
                <HamburgerButton toggleState={toggleState} />
                <Logo />
            </Group>
            <EnvironmentName environment={config.environment}> {config.environment} </EnvironmentName>
            <Group>
                <RegionMenu region={region} isClient={isClient} {...rest} />
                {includeUserMenu && <UserMenu {...rest} />}
            </Group>
        </Banner>
    );
};

const EnvironmentName = styled.div`
    color: #ffffff;
    text-transform: uppercase;
    display: ${props => (props.environment === 'production' ? 'none' : 'block')};
`;

const Banner = styled.header.attrs({
    role: 'banner'
})`
    background-color: ${config.headerColor};
    position: fixed;
    top: 0;
    z-index: 5;
    width: 100%;
    height: 3rem;
    padding: 0 1rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::after {
        content: normal;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #e31c79;
        box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.2);
        z-index: -1;
    }
`;

const Group = styled.div`
    display: flex;
`;

Header.propTypes = {
    toggleState: PropTypes.func.isRequired,
    region: PropTypes.string.isRequired,
    logout: PropTypes.func.isRequired,
    includeUserMenu: PropTypes.bool,
    isClient: PropTypes.bool
};

export default Header;
