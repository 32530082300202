import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { asField } from 'informed';
import StyledCheckbox from '../StyledCheckbox';

const CheckboxGroupInputField = ({ fieldState, fieldApi, errors, options, onChange, disabled }) => {
    const handleChange = optionId => {
        const { value } = fieldState;
        const { setValue } = fieldApi;

        let newValue = !value ? [] : value;
        const isChecked = newValue.includes(optionId);

        if (isChecked) {
            newValue = newValue.filter(option => option !== optionId);
        } else {
            newValue = [...newValue, optionId];
        }
        setValue(newValue);
        onChange();
    };

    const isChecked = optionId => {
        const { value } = fieldState;

        if (!value) {
            return false;
        }

        return value.includes(optionId);
    };

    return (
        <Group>
            {options.map(option => (
                <label key={option.id}>
                    <Wrapper>
                        <HiddenInput
                            disabled={disabled}
                            type="checkbox"
                            aria-invalid={!!errors && !!errors.length}
                            tabIndex="0"
                            checked={isChecked(option.id)}
                            onChange={() => handleChange(option.id)}
                        />
                        <StyledCheckbox disabled={disabled} />
                    </Wrapper>
                    <OptionName>{option.name}</OptionName>
                </label>
            ))}
        </Group>
    );
};

const Wrapper = styled.div`
    position: relative;

    input[type='checkbox'] {
        &:focus + div::before {
            content: '';
        }

        &:checked + div > svg {
            display: block;
        }
    }
`;

const HiddenInput = styled.input`
    position: absolute;
    left: -1rem;
    opacity: 0;
`;

const Group = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 450px;
    width: 100%;

    @media (min-width: 30rem) {
        margin-top: 0.5rem;
    }

    > label {
        width: 50%;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
    }
`;

const OptionName = styled.span`
    margin-left: 0.5rem;
    font-size: 14px;
`;

CheckboxGroupInputField.propTypes = {
    field: PropTypes.string.isRequired,
    fieldState: PropTypes.object,
    fieldApi: PropTypes.object,
    errors: PropTypes.array,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })).isRequired
};

const AsCheckboxGroupInputField = asField(CheckboxGroupInputField);

/**
 * CheckboxGroupInput
 * Wraps the field component to make it compatible with Informed
 */
const CheckboxGroupInput = ({ name, required, ...rest }) => {
    const validate = value => {
        return !value || value.length === 0 ? `The ${name} field is required` : undefined;
    };
    const validationProps = required && {
        validate,
        validateOnChange: true,
        validateOnMount: true
    };
    return <AsCheckboxGroupInputField field={name} {...rest} {...validationProps} />;
};

CheckboxGroupInput.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })).isRequired,
    required: PropTypes.bool
};

export default CheckboxGroupInput;
