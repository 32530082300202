import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Scope } from 'informed';
import styled from 'styled-components';
import memoize from 'memoize-one';
import { throttle } from 'throttle-debounce';

import ViewEditAdd from '../../hocs/ViewEditAdd';
import { Button, ErrorMessage, Label, TextInput, SelectInput, WidgetCodeSample } from '../../atoms';
import { CheckboxInputGroup } from '../../molecules';
import { ApiClientContext } from '../../services';

class Widget extends Component {
    state = {
        isSubmitted: false,
        data: this.props.data,
        employers: [],
        locations: [],
        workTypes: [],
        industries: [],
        levelOfExperiences: []
    };

    static contextType = ApiClientContext;

    componentDidMount() {
        this.context.apiClient.getAllJobFilters().then(({ data }) => {
            if (data) {
                this.setState({
                    workTypes: data.workTypes,
                    industries: data.industries,
                    levelOfExperiences: data.levelOfExperiences
                });
            }
        });
    }

    handleLocationSearch = throttle(1000, value => {
        if (value) {
            const queryStr = `locationDescription=${value}&region=${this.props.region}`;
            this.context.apiClient.getSuggestedJobLocations(queryStr).then(({ data }) => {
                this.setState({
                    locations: data
                        .filter(d => d.selectValue !== 'Remote')
                        .map(d => {
                            const name = d.selectValue || d.description;
                            return { id: name, name };
                        })
                });
            });
        } else {
            this.setState({ locations: [] });
        }
    });

    setFormApi = formApi => {
        this.formApi = formApi;
    };

    handleSubmit = data => {
        data = {
            ...data,
            Query: {
                ...data.Query,
                Region: this.props.region
            }
        };
        const { data: propData, isExisting } = this.props;

        if (!isExisting) {
            this.context.apiClient
                .addWidget(data)
                .then(() => {
                    this.setState({
                        isSubmitted: true,
                        data
                    });
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                });
        } else {
            this.context.apiClient
                .updateWidget(propData.id, data)
                .then(response => {
                    this.setState({ data: response.data });
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                });
        }
    };

    handleEmployerSearch = throttle(1000, value => {
        this.context.apiClient.getAllEmployers(1, `&search=${value}`).then(({ data }) => {
            if (data && data.results) {
                this.setState({ employers: data.results.map(e => ({ id: e.slug, name: e.name })) });
            }
        });
    });

    handleReset = () => {
        this.formApi.reset();
        this.setState({
            isSubmitted: false,
            data: {}
        });
    };

    sortAscending = memoize(items =>
        items.sort((a, b) => {
            const lowerA = a.toLowerCase();
            const lowerB = b.toLowerCase();
            if (lowerA < lowerB) {
                return -1;
            }

            if (lowerA > lowerB) {
                return 1;
            }

            return 0;
        })
    );

    validatePartner = value => {
        return value && ['/', ':'].some(c => value.indexOf(c) !== -1) ? 'The Partner field can not contain "/" or ":".' : undefined;
    };

    validateNumber = value => {
        return /[^[0-9]/.test(value) || value == 0 ? 'The Number of Jobs to show field must be a valid number greater than 0.' : undefined;
    };

    render() {
        const { isSubmitted, employers, locations, workTypes, industries, levelOfExperiences, data } = this.state;
        const { isExisting } = this.props;
        const jobSortOptions = [
            { id: 'score_desc', name: 'Most relevant' },
            { id: 'date_desc', name: 'Date (newest to oldest)' },
            { id: 'date_asc', name: 'Date (oldest to newest) ' }
        ];

        return (
            <Fragment>
                {isExisting && <h1>Update widget</h1>}
                {!isExisting && <h1>Create a new widget</h1>}

                <Form onSubmit={this.handleSubmit} getApi={this.setFormApi}>
                    {({ formState }) => (
                        <Fragment>
                            <Label label="Partner">
                                <ContainerColumn>
                                    {!isExisting && <TextInput initialValue={data.clientUrl} name="ClientUrl" validate={this.validatePartner} validateOnBlur required />}
                                    {isExisting && <span>{data.clientUrl}</span>}
                                    {formState.touched.ClientUrl && formState.errors.ClientUrl && formState.errors.ClientUrl.length > 0 && (
                                        <ErrorMessage errors={formState.values.ClientUrl ? [formState.errors.ClientUrl] : ['The Partner field is required.']} />
                                    )}
                                </ContainerColumn>
                            </Label>
                            <Label label="Title">
                                <TextInput initialValue={data.title} name="Title" />
                            </Label>
                            <Scope scope="Query">
                                <Label label="Keyword">
                                    <TextInput initialValue={data.query && data.query.keyword} name="Keyword" />
                                </Label>
                                <Label label="Location">
                                    <SelectInput
                                        initialValue={data.query && data.query.location}
                                        name="Location"
                                        options={locations}
                                        onSearch={this.handleLocationSearch}
                                        searchPlaceholder="Search for a location"
                                    />
                                </Label>
                                <Label label="Employers">
                                    <SelectInput
                                        name="EmployerSlugs"
                                        initialValue={data.query && data.query.employerSlugs}
                                        multiple
                                        multipleText="(multiple)"
                                        options={employers}
                                        onSearch={this.handleEmployerSearch}
                                        searchPlaceholder="Search for an employer"
                                    />
                                </Label>
                            </Scope>
                            <Label label="Number of Jobs to show">
                                <ContainerColumn>
                                    <TextInput initialValue={`${data.jobCount}` || '3'} name="JobCount" validate={this.validateNumber} validateOnBlur required />
                                    {formState.touched.JobCount && formState.errors.JobCount && formState.errors.JobCount.length > 0 && (
                                        <ErrorMessage
                                            errors={formState.values.JobCount ? [formState.errors.JobCount] : ['The Number of Jobs to show field is required.']}
                                        />
                                    )}
                                </ContainerColumn>
                            </Label>
                            <Scope scope="Query">
                                <Label label="Sort Jobs">
                                    <SelectInput name="sortOrder" initialValue={data.sortOrder || jobSortOptions[0].id} options={jobSortOptions} displayNameAsLabel />
                                </Label>
                            </Scope>
                            <FormRow>
                                <Scope scope="Query.Filters">
                                    <FormColumn>
                                        <h4>Work Types</h4>
                                        <CheckboxInputGroup
                                            field="WorkTypes"
                                            initialValue={data.query && data.query.filters && data.query.filters.workTypes}
                                            options={this.sortAscending(Object.keys(workTypes))}
                                        />
                                    </FormColumn>
                                    <FormColumn>
                                        <h4>Industries</h4>
                                        <CheckboxInputGroup
                                            field="Industries"
                                            options={this.sortAscending(industries.map(x => x.id))}
                                            initialValue={data.query && data.query.filters && data.query.filters.industries}
                                        />
                                    </FormColumn>
                                    <FormColumn>
                                        <h4>Levels Of Experience</h4>
                                        <CheckboxInputGroup
                                            field="LevelOfExperiences"
                                            options={this.sortAscending(Object.keys(levelOfExperiences))}
                                            initialValue={data.query && data.query.filters && data.query.filters.levelOfExperiences}
                                        />
                                    </FormColumn>
                                </Scope>
                            </FormRow>
                            {!isSubmitted && (
                                <Button type="submit" disabled={formState.invalid} theme="success">
                                    Submit
                                </Button>
                            )}
                            {(isSubmitted || isExisting) && (
                                <Fragment>
                                    <WidgetCodeSample
                                        domain={data.ClientUrl || data.clientUrl}
                                        region={(data.Query && data.Query.Region) || (data.query && data.query.region)}
                                    />
                                    {!isExisting && (
                                        <Button
                                            type="button"
                                            onClick={() => this.handleReset()}
                                            style={{
                                                marginTop: '1rem'
                                            }}
                                        >
                                            Add another widget
                                        </Button>
                                    )}
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </Form>
            </Fragment>
        );
    }
}

Widget.propTypes = {
    region: PropTypes.string.isRequired,
    isExisting: PropTypes.bool,
    data: PropTypes.shape({ employers: PropTypes.shape({ results: PropTypes.object }) })
};

const FormRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    @media (max-width: 30rem) {
        flex-direction: column;
    }
`;

const FormColumn = styled.div`
    width: 33.33333%;
    margin: 1rem 0.5rem 0.5rem 0.5rem;
    @media (max-width: 30rem) {
        width: 100%;
    }
`;

const ContainerColumn = styled.div`
    width: 100%;
`;

export default ViewEditAdd(Widget)({ domain: 'Widget' });
