import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import React from 'react';
import { withRouter } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';

import {
    Applications,
    Dashboard,
    DryRunResult,
    Employer,
    EmployerContentBlock,
    Employers,
    Job,
    JobAdAnalysis,
    JobAnalytics,
    JobAutomation,
    JobAutomations,
    Jobs,
    SelfService,
    SelfServiceSaved,
    User,
    Users,
    Widget,
    Widgets
} from './components';
import { Loading } from './atoms';
import { REGION_PARAM_WHITELIST, REGIONS } from './constants';
import { ProtectedRoute, ProtectedRedirect, useAuthorization } from './auth';
import EmployerPerkPage from './components/EmployerPerks/EmployerPerk';

const App = props => {
    const { isLoadingAuthorization } = useAuthorization();
    const { history, isLoading } = props;

    const ProtectedRouteProps = {
        exact: true,
        history
    };

    if (isLoading || isLoadingAuthorization) {
        return <Loading />;
    }

    return (
        <Switch>
            <ProtectedRedirect exact from="/" to="/au" />
            <Route
                path={`/:regionlessurl(employers|reports|jobs|applications|users|widgets)/(.*)?`}
                render={({ location: { pathname, search } }) => {
                    const region = REGIONS[0].id;
                    return <Redirect to={`/${region}${pathname}${search}`} />;
                }}
            />
            <Route
                path="/lite"
                render={({ match: { path } }) => (
                    <Switch>
                        <Route path={`${path}/saved`} component={SelfServiceSaved} key="saved" />
                        <Route path={`${path}/sent`} component={SelfServiceSaved} key="sent" />
                        <Route path={`${path}/:id`} component={SelfService} />
                    </Switch>
                )}
            />
            <Route
                path={`/${REGION_PARAM_WHITELIST}`}
                render={({ match: { path } }) => (
                    <Switch>
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/`} component={Dashboard} />
                        <Redirect exact from={`${path}/employers`} to={`${path}/employers/all`} />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/employers/all`} component={Employers} />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/employers/add`} component={Employer} key="add" />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/employers/:id`} component={Employer} key="id" />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/employers/:id/content-blocks/add`} component={EmployerContentBlock} key="id" />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/employers/:id/content-blocks/:blockId`} component={EmployerContentBlock} key="blockId" />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/employers/:id/employer-perk/new`} component={EmployerPerkPage} key="new" />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/employers/:id/employer-perk/:perkId`} component={EmployerPerkPage} key="perkId" />
                        <Redirect exact from={`${path}/jobs`} to={`${path}/jobs/all`} />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/jobs/all`} component={Jobs} />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/jobs/add`} component={Job} key="add" />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/jobs/analytics`} component={JobAnalytics} key="analytics" />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/jobs/analysis`} component={JobAdAnalysis} key="jobAnalysis" />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/jobs/:id`} component={Job} key="id" />
                        <Redirect exact from={`${path}/applications`} to={`${path}/applications/all`} />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/applications/all`} component={Applications} />
                        <Redirect exact from={`${path}/users`} to={`${path}/users/all`} />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/users/all`} component={Users} />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/users/add`} component={User} key="add" />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/users/:id`} component={User} key="id" />
                        <Redirect exact from={`${path}/widgets`} to={`${path}/widgets/all`} />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/widgets/all`} component={Widgets} />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/widgets/add`} component={Widget} key="add" />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/widgets/:id`} component={Widget} key="id" />
                        <Redirect exact from={`${path}/job-automations`} to={`${path}/job-automations/all`} />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/job-automations/all`} component={JobAutomations} />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/job-automations/:employerId`} component={JobAutomation} key="jobAnalysis" />
                        <ProtectedRoute {...ProtectedRouteProps} path={`${path}/job-automations/:employerId/dry-run/:id`} component={DryRunResult} />
                        <Redirect from={`${path}/*`} to="/" />
                    </Switch>
                )}
            />
        </Switch>
    );
};

export default withAuth0(withRouter(App));
