import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Tab = ({ children, isSelected, onSelect }) => {
    return (
        <StyledTab onClick={onSelect} isSelected={isSelected}>
            {children}
        </StyledTab>
    );
};

Tab.propTypes = {
    onSelect: PropTypes.func,
    isSelected: PropTypes.bool,
    children: PropTypes.node
};

Tab.defaultProps = {
    onSelect: () => {}
};

const StyledTab = styled.li`
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 0;
    margin: 0 1rem;
    font-size: 1.25rem;
    font-weight: 600;

    &:first-child {
        margin-left: 0;
    }

    &:hover {
        border-bottom: 2px solid #000000;
        cursor: pointer;
    }

    ${props =>
        props.isSelected &&
        css`
            border-bottom: 2px solid #000000;
        `}
`;

export default Tab;
