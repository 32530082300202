import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PaginationButton } from '../../atoms';

class Pagination extends Component {
    handleClick = direction => {
        const { currentPage, handlePagination } = this.props;

        const newPage = direction === 'Right' ? currentPage + 1 : currentPage - 1;

        handlePagination(newPage);
    };

    render() {
        const { currentPage, totalPages, currentResults, totalResults, ...rest } = this.props;
        const startCount = currentPage > 1 ? 50 * (currentPage - 1) + 1 : 1;
        const endCount = currentPage > 1 ? currentResults + 50 * (currentPage - 1) : currentResults;

        return (
            <Container {...rest}>
                <div>
                    <PaginationButton direction="Left" disabled={currentPage === 1} handleClick={this.handleClick} />
                    <PaginationButton direction="Right" disabled={currentPage >= totalPages} handleClick={this.handleClick} />
                </div>
                <Count>
                    {startCount} - {endCount} of {totalResults} results
                </Count>
            </Container>
        );
    }
}

const Container = styled.div`
    padding: 0 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-shrink: 0;
    box-sizing: border-box;

    @media (min-width: 60rem) {
        flex-direction: column;
    }

    @media (max-width: 60rem) {
        width: 100%;
    }

    button:first-of-type {
        margin-right: 0.5rem;
    }
`;

const Count = styled.span`
    font-family: 'Montserrat', sans-serif;
    font-size: 0.75rem;
    font-weight: 500;
    color: #999999;

    @media (max-width: 60rem) {
        order: -1;
    }
`;

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    currentResults: PropTypes.number.isRequired,
    totalResults: PropTypes.number.isRequired,
    handlePagination: PropTypes.func.isRequired
};

export default Pagination;
