import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'evergreen-ui';

import { Button, ErrorMessage, ImageCropper } from '../../atoms';
import { Dialog } from '../../molecules';

class ImageCropDialog extends Component {
    _cropperRef = React.createRef();

    state = { imageReady: false };

    componentDidUpdate(prevProps) {
        if (this.props.imageSrc && this.props.imageSrc !== prevProps.imageSrc) {
            this.setImageReady(false);
        }
    }

    handleConfirm = () => {
        const { handleConfirm, imageName, imageQuality, imageType } = this.props;
        if (this._cropperRef && this._cropperRef.current && this._cropperRef.current._cropperInstance && this.state.imageReady) {
            this._cropperRef.current._cropperInstance.getCroppedCanvas().toBlob(
                blob => {
                    blob.name = imageName;
                    handleConfirm(blob);
                },
                imageType,
                imageQuality
            );
        }
    };

    setImageReady = ready => {
        this.setState({ imageReady: ready });
    };

    render() {
        const { isShown, handleCloseComplete, title, confirmLabel, imageSrc, isSaving, viewportShape, aspectRatio, error } = this.props;
        const { imageReady } = this.state;

        return (
            <Pane>
                <Dialog
                    isShown={isShown}
                    title={title}
                    onCloseComplete={handleCloseComplete}
                    confirmLabel={confirmLabel}
                    shouldCloseOnOverlayClick={false}
                    onConfirm={this.handleConfirm}
                    hasFooter={false}
                >
                    {({ close }) => (
                        <Pane>
                            {!imageReady && (
                                <div style={{ textAlign: 'center' }}>
                                    <h4>Loading image...</h4>
                                    <p>If the image doesn&apos;t load, please upload a new image to crop.</p>
                                </div>
                            )}
                            {!!imageSrc && (
                                <React.Fragment>
                                    <ImageCropper
                                        imageSrc={imageSrc}
                                        ref={this._cropperRef}
                                        setImageReady={this.setImageReady}
                                        imageReady={imageReady}
                                        viewportShape={viewportShape}
                                        aspectRatio={aspectRatio}
                                    />
                                    <Pane display="flex" paddingTop={16} paddingLeft={16} justifyContent="flex-end">
                                        <div>
                                            <Button filled="border" style={{ marginRight: '0.5rem' }} onClick={close}>
                                                Cancel
                                            </Button>
                                            {imageReady && (
                                                <Button onClick={this.handleConfirm} disabled={isSaving}>
                                                    {isSaving ? 'Saving...' : confirmLabel}
                                                </Button>
                                            )}
                                        </div>
                                    </Pane>
                                    {!!error && <ErrorMessage errors={[error]} />}
                                </React.Fragment>
                            )}
                        </Pane>
                    )}
                </Dialog>
            </Pane>
        );
    }
}

ImageCropDialog.defaultProps = {
    title: 'Edit Image',
    confirmLabel: 'Save Image',
    isSaving: false,
    imageType: 'jpg',
    imageQuality: 0.9,
    viewportShape: 'square',
    aspectRatio: null
};

ImageCropDialog.propTypes = {
    /** Shows or hides the dialog */
    isShown: PropTypes.bool.isRequired,
    /** Callback used when the confirm button is clicked */
    handleConfirm: PropTypes.func.isRequired,
    /** Callback used when the cancel button is clicked */
    handleCloseComplete: PropTypes.func.isRequired,
    /** Url or data url of image to crop */
    imageSrc: PropTypes.string,
    /** The image file type */
    imageType: PropTypes.string,
    /** Quality level between 0 and 1 */
    imageQuality: PropTypes.number,
    /** Text to show in the header of the dialog */
    title: PropTypes.string,
    /** Text to show on the confirm button */
    confirmLabel: PropTypes.string,
    /** True when the image is being saved */
    isSaving: PropTypes.bool,
    /** Render the crop viewport as a square or circle */
    viewportShape: PropTypes.oneOf(['square', 'circle']),
    /** Restrict the viewport to a specific aspect ratio */
    aspectRatio: PropTypes.number,
    imageName: PropTypes.string,
    error: PropTypes.string
};

export default ImageCropDialog;
