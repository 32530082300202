const setupOptions = statement => {
    let options = [];
    if (statement.options && statement.options.length > 0) {
        if (['Dropdown', 'PercentageFields'].includes(statement.responseInputType) && statement.useYearsOptions) {
            options = getDynamicYearOptions(statement.options);
        } else {
            options = statement.options.map(option => ({ id: option.name, name: option.name, order: option.order }));
        }
    }
    return options;
};

const getDynamicYearOptions = options => {
    const currentYear = new Date().getFullYear();
    const optionsList = [];
    options.forEach(option => {
        const name = option.name;
        if (name.toLowerCase() === 'current') {
            optionsList.push({ id: currentYear.toString(), name: currentYear.toString() });
        }
        const operatorMatch = name.match(/[\\+-]/);
        const numberMatch = name.match(/\d+/);
        if (operatorMatch && operatorMatch.length && numberMatch && numberMatch.length) {
            for (let i = 1; i <= numberMatch[0]; i++) {
                const newYear = operatorMatch[0] === '-' ? currentYear - i : currentYear + i;
                optionsList.push({ id: newYear.toString(), name: newYear.toString() });
            }
        }
    });
    return optionsList.sort((a, b) => compare(a.name, b.name));
};

const compare = (a, b) => {
    if (!a) return 1;
    if (!b) return -1;

    // sort numbers
    const numA = !isNaN(a);
    const numB = !isNaN(b);
    if (numA && numB) {
        return Number(a) - Number(b);
    }
    if (numA) return -1;
    if (numB) return 1;

    // sort words
    return a.localeCompare(b);
};

export { setupOptions };
