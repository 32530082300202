import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Loading } from '../atoms';
import { useRegion } from '../hooks';
import { useAuthorization } from './useAuthorization';
import { REGION_PARAM_WHITELIST } from '../constants';
import View from '../components/View';

const ProtectedRoute = ({ component: Component, ...args }) => {
    const { regionFromRoute } = useRegion();
    const { allowedRegions, isClient } = useAuthorization();
    const { path, history, computedMatch, ...rest } = args;

    useEffect(() => {
        if (!allowedRegions.includes(regionFromRoute)) {
            const currentPathWithRegionRegex = computedMatch.path;
            history.push(currentPathWithRegionRegex.replace(REGION_PARAM_WHITELIST, allowedRegions[0]));
        }
    }, [allowedRegions, computedMatch.path, history, regionFromRoute]);

    const ComponentWithAuth = withAuthenticationRequired(Component, {
        onRedirecting: () => <Loading />
    });
    return (
        <Route
            render={() => (
                <View isClient={isClient} region={regionFromRoute} history={history} computedMatch={computedMatch} {...rest}>
                    <ComponentWithAuth history={history} region={regionFromRoute} computedMatch={computedMatch} {...rest} />
                </View>
            )}
            path={path}
        />
    );
};

export default ProtectedRoute;
