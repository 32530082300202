import React from 'react';
import styled from 'styled-components';

const SelfServiceHeader = ({ children, employer, text }) => {
    return (
        <Header>
            {children}
            {!children && (
                <>
                    <Logo src={employer && employer.logo} alt="Logo" />
                    <H1>{text}</H1>
                </>
            )}
        </Header>
    );
};

const Header = styled.div`
    display: flex;
    align-items: center;
    margin: 35px 0;
`;

const H1 = styled.h1`
    font-size: 28px;
    color: #212121;
    display: inline-block;
    font-weight: 400;
    margin: 0;
`;

const Logo = styled.img`
    max-width: 4rem;
    max-height: 3rem;
    margin-right: 20px;
`;

export default SelfServiceHeader;
