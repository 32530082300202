import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CustomSelect } from '../../atoms';
import { toTitleCase } from '../../helpers';

class SelectInput extends Component {
    validate = (value = '') => {
        const { label, name, multiple } = this.props;
        const fieldName = label || toTitleCase(name);
        const hasValue = multiple ? !!value.length : !!value.trim();
        return hasValue ? undefined : `The ${fieldName} field is required.`;
    };

    render() {
        const { name, required, ...rest } = this.props;
        const validationProps = required && {
            validate: this.validate,
            validateOnChange: true,
            validateOnMount: true
        };

        return <CustomSelect field={name} {...validationProps} {...rest} />;
    }
}

SelectInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    multiple: PropTypes.bool
};

export default SelectInput;
