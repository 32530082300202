import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { usePathForRegion } from '../../hooks';

const Link = ({ to, isExternal, hoverTheme, children, ...rest }) => {
    const target = usePathForRegion(to || '/');
    if (isExternal) {
        return (
            <Fragment>
                {hoverTheme === 'outline' ? (
                    <AnchorWithOutline href={to} {...rest}>
                        {children}
                    </AnchorWithOutline>
                ) : (
                    <AnchorWithUnderline href={to} {...rest}>
                        {children}
                    </AnchorWithUnderline>
                )}
            </Fragment>
        );
    }
    return (
        <Fragment>
            {hoverTheme === 'outline' ? (
                <LinkWithOutline to={target} {...rest}>
                    {children}
                </LinkWithOutline>
            ) : (
                <LinkWithUnderline to={target} {...rest}>
                    {children}
                </LinkWithUnderline>
            )}
        </Fragment>
    );
};

const underlineStyles = css`
    border-bottom: 0.0625rem solid currentColor;

    &:hover,
    &:focus {
        outline: none;
        opacity: 0.5;
    }
`;

const outlineStyles = css`
    &:hover,
    &:focus {
        outline: none;

        > * {
            outline: 1px solid currentColor;
            outline-offset: 3px;
        }
    }
`;

const AnchorWithUnderline = styled.a`
    ${underlineStyles};
`;

const AnchorWithOutline = styled.a`
    ${outlineStyles};
`;

const LinkWithUnderline = styled(RouterLink)`
    ${underlineStyles};
`;

const LinkWithOutline = styled(RouterLink)`
    ${outlineStyles};
`;

Link.defaultProps = {
    isExternal: false,
    hoverTheme: 'underline'
};

Link.propTypes = {
    /** Link to address */
    to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            pathname: PropTypes.string,
            search: PropTypes.string
        })
    ]).isRequired,
    /** Use <a> tag for external links, otherwise use <Link> provided by react-router-dom */
    isExternal: PropTypes.bool,
    /** Hover and focus style theme. "underline" is applied to the Link, whereas "outline" is applied to the child of Link */
    hoverTheme: PropTypes.oneOf(['underline', 'outline']),
    /** Link text */
    children: PropTypes.node.isRequired
};

export default Link;
