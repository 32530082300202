import React, { Fragment, useContext, useRef, useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { Form, Scope } from 'informed';
import styled from 'styled-components';

import { Button, ErrorWrapper, Field, TextInput, Loading } from '../../atoms';
import { ImageUpload, Panel } from '../../molecules';
import { combineErrors, validateUrl } from '../../helpers';
import { ApiClientContext } from '../../services';
import { useData } from '../../hooks';

const apiCall = useData.apiCall;

const useEndorsementData = props => {
    const { apiClient } = useContext(ApiClientContext);
    const { data, setData, isLoading, error } = useData(apiClient.getAllEndorsements, [], null);
    const [apiError, setError] = useState(null);

    const addEndorsement = apiCall(
        payload => apiClient.addEndorsement(payload),
        endorsement => setData([...data, endorsement]),
        setError
    );
    const updateEndorsement = apiCall(
        (id, payload) => apiClient.updateEndorsement(id, payload),
        endorsement => {
            // Update the cached endorsement
            const index = data.findIndex(e => e.id === endorsement.id);
            setData([...data.slice(0, index), endorsement, ...data.slice(index + 1)]);
        },
        setError
    );

    const sortFunction = (a, b) => {
        const lowerA = a.name ? a.name.toLowerCase() : '';
        const lowerB = b.name ? b.name.toLowerCase() : '';
        if (lowerA < lowerB) return -1;
        if (lowerA > lowerB) return 1;
        return 0;
    };

    const sortedEndorsements = useMemo(() => (data ? data.sort(sortFunction) : []), [data]);

    return {
        endorsements: sortedEndorsements,
        addEndorsement,
        updateEndorsement,
        isLoading,
        errors: error || apiError || props.errors
    };
};

const EndorsementsContentBlockFields = ({ formState, isSubmitting, ...rest }) => {
    const { endorsements, addEndorsement, updateEndorsement, isLoading, errors } = useEndorsementData(rest);
    const formErrors = {};
    const contentTouched = formState.touched.content || {};
    const contentErrors = formState.errors.content;
    if (contentErrors) {
        formErrors.heading = combineErrors(errors.Heading, contentErrors.heading, contentTouched.heading);
        formErrors.endorsements = combineErrors(errors.Endorsements, contentErrors.endorsements, contentTouched.endorsements);
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Fragment>
            <Scope scope="content">
                <Field label="Heading" name="heading" required disabled={isSubmitting} errors={formErrors.heading} fieldType="text" />
                <Field
                    label="Endorsements"
                    name="endorsements"
                    options={endorsements}
                    required
                    disabled={isSubmitting}
                    errors={formErrors.endorsements}
                    fieldType="checkboxGroup"
                />
            </Scope>
            <ManageEndorsements {...rest} allEndorsements={endorsements} addEndorsement={addEndorsement} updateEndorsement={updateEndorsement} errors={errors} />
        </Fragment>
    );
};

const ManageEndorsements = ({ portalRef, allEndorsements, addEndorsement, updateEndorsement, errors, ...rest }) => {
    const [show, setShow] = useState(null);
    const [uploadedFilename, setUploadedFilename] = useState(null);

    const formApi = useRef(null);
    const handleFileUpload = (b64, file) => {
        return rest.handleFileUpload(b64, file.name, file.type, file, responseData => {
            if (formApi && formApi.current) {
                formApi.current.setValue('logoUrl', responseData.url);
                setUploadedFilename(file.name);
            }
        });
    };
    const handleSubmit = formValues => {
        const data = { ...formValues };
        if (formValues.id) {
            updateEndorsement(data.id, data).then(() => setShow(null));
        } else {
            addEndorsement(data).then(() => setShow(null));
        }

        setShow(null);
    };

    return (
        <ErrorWrapper error={errors}>
            <ManageContainer>
                <div>
                    {!show && (
                        <Fragment>
                            <Button type="button" filled="border" size="mini" onClick={() => setShow('add')}>
                                Add
                            </Button>{' '}
                            <Button type="button" filled="border" size="mini" onClick={() => setShow('manage')}>
                                Manage
                            </Button>
                        </Fragment>
                    )}
                </div>

                {/* Forms can't be nested in other forms, so use a portal */}
                {show !== null &&
                    createPortal(
                        <Panel title={show === 'add' ? 'Add endorsement' : 'Manage endorsements'}>
                            <Form onSubmit={handleSubmit} getApi={api => (formApi.current = api)}>
                                {({ formState, formApi }) => {
                                    const endorsement = formState.values.id ? allEndorsements.find(e => e.id === formState.values.id) : {};
                                    const combinedErrors = {};
                                    const touched = formState.touched || {};
                                    const formErrors = formState.errors;
                                    if (formErrors) {
                                        combinedErrors.name = combineErrors(formErrors.Name, formErrors.name, touched.name);
                                        combinedErrors.altTag = combineErrors(formErrors.AltTag, formErrors.altTag, touched.altTag);
                                        combinedErrors.summary = combineErrors(formErrors.Summary, formErrors.summary, touched.summary);
                                        combinedErrors.url = combineErrors(formErrors.Url, formErrors.url, touched.url);
                                        combinedErrors.logoUrl = combineErrors(formErrors.LogoUrl, formErrors.logoUrl, touched.logoUrl);
                                    }

                                    return (
                                        <Fragment>
                                            {show === 'manage' && <Field label="Endorsement" name="id" fieldType="select" required options={allEndorsements} />}
                                            {(show === 'add' || !!formState.values.id) && (
                                                <Fragment key={show || ''}>
                                                    <Field
                                                        label="Name"
                                                        name="name"
                                                        initialValue={endorsement ? endorsement.name : ''}
                                                        required
                                                        errors={combinedErrors.name}
                                                        fieldType="text"
                                                    />
                                                    <Field
                                                        label="Logo alt tag"
                                                        name="altTag"
                                                        initialValue={endorsement ? endorsement.altTag : ''}
                                                        required
                                                        errors={combinedErrors.altTag}
                                                        fieldType="text"
                                                    />
                                                    <Field
                                                        label="Summary"
                                                        name="summary"
                                                        initialValue={endorsement ? endorsement.summary : ''}
                                                        required
                                                        errors={combinedErrors.summary}
                                                        fieldType="text"
                                                        rows={3}
                                                    />
                                                    <Field
                                                        label="URL"
                                                        name="url"
                                                        initialValue={endorsement ? endorsement.url : ''}
                                                        required
                                                        validate={validateUrl}
                                                        errors={combinedErrors.url}
                                                        fieldType="text"
                                                    />
                                                    {/* Hidden input to capture logoUrl value in form */}
                                                    <TextInput
                                                        name="logoUrl"
                                                        initialValue={endorsement ? endorsement.logoUrl : ''}
                                                        required
                                                        errors={combinedErrors.logoUrl}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <ImageUpload
                                                        handleFileUpload={handleFileUpload}
                                                        currentImage={formState.values.logoUrl ? formState.values.logoUrl : ''}
                                                        uploadedFilename={uploadedFilename || ''}
                                                        labelText="Logo"
                                                        uploadButtonText="Choose logo"
                                                        editButtonText="Edit logo"
                                                        dialogTitle="Edit logo"
                                                        dialogConfirmLabel="Save logo"
                                                        previewImageAlt="Endorsement logo"
                                                        showPreview
                                                        aspectRatio={1 / 1}
                                                    />
                                                </Fragment>
                                            )}
                                            <div>
                                                <Button type="button" size="mini" onClick={formApi.submitForm} disabled={formState.invalid}>
                                                    Save
                                                </Button>{' '}
                                                <Button type="button" filled="invisible" size="mini" onClick={() => setShow(null)}>
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Fragment>
                                    );
                                }}
                            </Form>
                        </Panel>,
                        portalRef.current
                    )}
            </ManageContainer>
        </ErrorWrapper>
    );
};

const ManageContainer = styled.div`
    margin-top: 1rem;
    @media (min-width: 30rem) {
        margin-left: 9.5rem;
    }
`;

EndorsementsContentBlockFields.propTypes = {
    formState: PropTypes.object.isRequired,
    errors: PropTypes.object,
    isSubmitting: PropTypes.bool
};

EndorsementsContentBlockFields.defaultProps = {
    isSubmitting: false,
    errors: {}
};

export default EndorsementsContentBlockFields;
