import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Icon } from '../../atoms';
import { disabledStyles, resetButton } from '../../styles';

const PaginationButton = ({ direction, disabled, handleClick, ...rest }) => {
    return (
        <Button onClick={() => handleClick(direction)} disabled={disabled} {...rest}>
            <Icon icon={`ChevronCircle${direction}`} />
        </Button>
    );
};

const Button = styled.button`
    ${resetButton};
    font-size: 1.25rem;
    color: #ffc845;
    transition: opacity 0.3s ease;
    ${props => props.disabled && disabledStyles};

    &:hover {
        opacity: 0.75;
        transition: opacity 0.3s ease;
    }

    &:focus {
        outline: 1px solid;
        outline-offset: 2px;
    }
`;

PaginationButton.propTypes = {
    direction: PropTypes.string,
    disabled: PropTypes.bool,
    handleClick: PropTypes.func
};

export default PaginationButton;
