import React from 'react';
import styled from 'styled-components';

import { Icon } from '../../atoms';

const SuccessMessage: React.FC<{ message: string }> = ({ message }) => {
    return (
        <div role="region" aria-live="assertive">
            {!!message && (
                <Success>
                    <Icon icon="Check" style={{ marginRight: '0.25rem' }} />
                    {message}
                </Success>
            )}
        </div>
    );
};

const Success = styled.span`
    font-size: 0.75rem;
    padding: 0.25rem 0 0.5rem;
    display: inline-flex;
    align-items: center;
`;

export default SuccessMessage;
