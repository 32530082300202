import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const NavItem = ({ isChild, children, to, ...rest }) => {
    const navProps = {
        exact: true,
        to,
        activeStyle: {},
        ...rest
    };

    const isActiveRefresh = () => {
        if (to === window.location.pathname) {
            window.location.reload();
        }
    };

    if (isChild) {
        return (
            <ChildItem onClick={isActiveRefresh} {...navProps}>
                {children}
            </ChildItem>
        );
    }

    return <Item {...navProps}>{children}</Item>;
};

const activeStyle = css`
    background-color: #e9e9e9;
    font-weight: 600;

    &::after {
        content: '';
        width: 0.25rem;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: #e31c79;
    }
`;

const navItemStyles = css`
    position: relative;
    font-size: 0.875rem;
    font-weight: 500;
    text-decoration: none;
    color: #000000;
    padding: 1rem;
    display: flex;

    &[aria-current] {
        ${activeStyle};
    }

    &:focus {
        background: #e1e1e1;
        outline: none;
    }
`;

const Item = styled(NavLink)`
    ${navItemStyles};
    text-transform: uppercase;
`;

const ChildItem = styled(NavLink)`
    ${navItemStyles};
    padding-left: 2rem;
`;

NavItem.propTypes = {
    isChild: PropTypes.bool,
    children: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired
};

export default NavItem;
