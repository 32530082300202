import { toTitleCase } from '../../helpers';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Text } from 'informed';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Button from '../Button';

class LinkInput extends Component {
    validate = (value = '') => {
        const { label, name, validate, required } = this.props;
        const fieldName = label || toTitleCase(name);

        if (required && (!value || !value.trim())) {
            return `The ${fieldName} field is required.`;
        }

        if (validate) {
            return !value ? undefined : validate(value);
        }
    };

    render() {
        const { name, initialValue, errors, required, validate, disableButton, overrideUrl, ...rest } = this.props;
        const mainProps = {
            field: name,
            initialValue,
            errors
        };
        const validationProps = (required || validate) && {
            validate: this.validate,
            validateOnChange: true,
            validateOnMount: true
        };
        const url = overrideUrl ? overrideUrl : initialValue;

        return (
            <Input>
                <SingleRowInput {...mainProps} aria-invalid={!!errors && !!errors.length} {...validationProps} {...rest} />
                <GotToLink url={url} disabled={!url ? true : disableButton} />
            </Input>
        );
    }
}

const GotToLink = ({ url, disabled }) => {
    return (
        <Button className="link-input-button" isExternal={true} disabled={disabled} to={url}>
            <Icon icon="ExternalLinkAlt" />
        </Button>
    );
};

const Input = styled.div`
    display: flex;
    width: 100%;

    .link-input-button {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        border-radius: 0 0.5rem 0.5rem 0;
        height: ${`calc(1.375rem + 1.125rem)`};
    }
`;

const inputStyles = css`
    font-family: 'Open Sans';
    padding: 0.6875rem 1rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    width: 100%;
    color: ${props => (props.disabled ? '#bcbcbc' : '#000000')};
    background-color: ${props => (props.disabled ? '#F1F1F1' : '#FFFFFF')};
    cursor: ${props => (props.disabled ? 'default' : 'auto')}
    height: ${`calc(1.375rem + 1.125rem)`};
    ${props => props.errors && props.errors.length && 'border-color: #000000'};

    &:focus {
        border-color: #ffc845;
        outline: none;
    }
`;

const SingleRowInput = styled(Text)`
    ${inputStyles};
`;

LinkInput.propTypes = {
    /** used to create the form state */
    name: PropTypes.string.isRequired,
    /** used to style the input as invalid */
    errors: PropTypes.arrayOf(PropTypes.string),
    /** is this a required input? */
    required: PropTypes.bool,

    label: PropTypes.string,
    validate: PropTypes.any,
    initialValue: PropTypes.any,
    disableButton: PropTypes.bool,
    overrideUrl: PropTypes.string
};

export default LinkInput;
