import React from 'react';
import styled from 'styled-components';

import { Icon } from '../../atoms';

const StyledCheckbox = ({ disabled, style }) => {
    return (
        <StyledInput disabled={disabled} style={style}>
            <Icon icon="Check" />
        </StyledInput>
    );
};

const StyledInput = styled.div`
    width: 1.25rem;
    height: 1.25rem;
    border: 0.0625rem solid #e1e1e1;
    border-radius: 0.125rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;

    color: ${props => (props.disabled ? '#bcbcbc' : '#000000')};
    background-color: ${props => (props.disabled ? '#F1F1F1' : '#FFFFFF')};
    cursor: ${props => (props.disabled ? 'default' : 'auto')};

    > svg {
        display: none;
    }

    &::before {
        position: absolute;
        top: -0.0625rem;
        left: -0.0625rem;
        bottom: -0.0625rem;
        right: -0.0625rem;
        border: 0.125rem solid #ffc845;
        border-radius: 0.125rem;
    }
`;

export default StyledCheckbox;
