import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Panel } from '../../molecules';
import { ApiClientContext } from '../../services';
import { useData, useRegion } from '../../hooks';
import { Button, ErrorMessage, Icon, Label, SelectInput, Spinner } from '../../atoms';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Form } from 'informed';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../../config';
import { EmployerFeatureModelList } from '../EmployerFeatures/EmployerFeatures';

type EmployerPerksListModel = {
    id: string;
    employerId: string;
    title: string;
    icon: { src: string; alt: string };
    order: number;
};

interface EmployerPerksProps {
    employerId: string;
    employerPerksEnabled: boolean;
}

type PerkEditedOrder = {
    id: string;
    order: number;
};

type ReorderEmployerPerksModel = {
    employerPerksOrder: string[];
    modifiedBy: string;
};

const EmployerPerks: FC<EmployerPerksProps> = ({ employerId, employerPerksEnabled }) => {
    const { apiClient } = useContext(ApiClientContext);
    const {
        user: { name }
    } = useAuth0();
    const { data: initialData, isLoading, error } = useData(apiClient.getEmployerPerks, employerId, [], () => employerId !== undefined);
    const [employerPerks, setEmployerPerks] = useState<EmployerPerksListModel[]>(initialData);
    const [featureIsEnabled, setFeatureIsEnabled] = useState(employerPerksEnabled);
    const [updateOrder, setUpdateOrder] = useState(false);
    const [showPerkLimitReachedMessage, setShowPerkLimitReachedMessage] = useState(false);
    const [perkLimitReached, setPerkLimitReached] = useState(false);
    const [editedOrder, setEditedOrder] = useState<PerkEditedOrder[]>([]);
    const [apiError, setApiError] = useState<{ message: string } | null>(null);
    const [reorderIsLoading, setReorderIsLoading] = useState<boolean>(false);
    const [featureToggleIsLoading, setFeatureToggleIsLoading] = useState<boolean>(false);
    const [featureApiError, setFeatureApiError] = useState<{ message: string } | null>(null);
    const [availableOrderOptions, setAvailableOrderOptions] = useState<{ id: string; name: string }[]>([]);
    const { regionFromRoute } = useRegion();
    const history = useHistory();

    function reOrderFunctionality() {
        if (!updateOrder) return setUpdateOrder(true);
        else {
            const notEditedPerks = employerPerks.filter(p => editedOrder.find(e => e.id === p.id) === undefined).map(p => ({ id: p.id, order: p.order }));

            const allOrders: PerkEditedOrder[] = [...notEditedPerks, ...editedOrder];

            const orderGuids: string[] = allOrders.sort((a, b) => a.order - b.order).map(e => e.id);

            const requestModel: ReorderEmployerPerksModel = { employerPerksOrder: orderGuids, modifiedBy: name };
            useData.apiCall(apiClient.reorderEmployerPerks, setEmployerPerks, setApiError, setReorderIsLoading)(employerId, requestModel);
        }
    }

    function addPerk() {
        if (perkLimitReached) return setShowPerkLimitReachedMessage(true);
        else {
            history.push(`/${regionFromRoute}/employers/${employerId}/employer-perk/new?order=${employerPerks.length}`);
        }
    }

    function resetReorder() {
        setEditedOrder([]);
        setUpdateOrder(false);
    }

    function addOrUpdate<T>(array: T[], newItem: T, predicate: (item: T) => boolean): T[] {
        const index = array.findIndex(predicate);
        if (index >= 0) {
            return array.map(x => (predicate(x) ? newItem : x));
        }
        return [...array, newItem];
    }

    function updateEditedOrder(id: string, newOrder: string) {
        return setEditedOrder((prev: PerkEditedOrder[]) => {
            return addOrUpdate(prev, { id: id, order: parseInt(newOrder) }, (item: PerkEditedOrder) => item.id === id);
        });
    }

    function calculateAvailableOrderOptions(employerPerks: EmployerPerksListModel[], editedOrder: PerkEditedOrder[]) {
        const indexedOptions = employerPerks.map((_perk, index) => {
            const indexString = index.toString();
            return { id: indexString, name: indexString };
        });

        const removePotentialDuplicates = indexedOptions.filter(o => {
            const editedOrderDuplicates = editedOrder.filter(p => p.order === parseInt(o.id));
            return editedOrderDuplicates.length < 1;
        });

        return removePotentialDuplicates.length === 0 ? indexedOptions : removePotentialDuplicates;
    }

    function toggleFeature() {
        const changeEnabledTo = !employerPerksEnabled;

        const body: EmployerFeatureModelList = {
            updatedBy: name,
            employerFeatureModels: [
                {
                    featureName: 'employer-perks',
                    enabled: changeEnabledTo
                }
            ]
        };

        useData.apiCall(apiClient.updateEmployerFeatures, () => setFeatureIsEnabled(changeEnabledTo), setFeatureApiError, setFeatureToggleIsLoading)(employerId, body);
    }

    const updateAfterDelete = useCallback(
        (id: string) => {
            const updateEmployerPerks = employerPerks.filter(p => p.id !== id);
            setEmployerPerks(updateEmployerPerks);
            setPerkLimitReached(updateEmployerPerks.length >= 6);
        },
        [employerPerks]
    );

    useEffect(() => {
        setEmployerPerks(initialData);
    }, [initialData]);

    useEffect(() => {
        setPerkLimitReached(employerPerks.length >= 6);
        setUpdateOrder(false);
    }, [employerPerks]);

    useEffect(() => {
        const calculatedAvailableOrderOptions = calculateAvailableOrderOptions(employerPerks, editedOrder);
        setAvailableOrderOptions(calculatedAvailableOrderOptions);
    }, [editedOrder, employerPerks]);

    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null;

        if (showPerkLimitReachedMessage) {
            timeout = setTimeout(() => {
                setShowPerkLimitReachedMessage(false);
            }, 5000);
        }
        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [showPerkLimitReachedMessage]);

    if (isLoading)
        return (
            <Panel title="Employer Perks">
                Loading the employer perks <Spinner />
            </Panel>
        );
    return (
        <Panel title="Employer Perks">
            <SectionInfo>
                <p>
                    Employer Perks display on an employer&apos;s jobs and application pages as a way to encourage jobseekers to click apply.
                    {!featureIsEnabled &&
                        ' You can set up the Employer perks here and then click the enable button below when you are ready for them to be viewed by jobseekers.'}
                </p>
                {!featureIsEnabled && (
                    <p>
                        To preview employer perks before live, use the following templated url:
                        <ul>
                            <li>
                                {config.website}for-women/job/<strong>[jobId]</strong>?previewEmployerPerks=true
                            </li>
                        </ul>
                    </p>
                )}
            </SectionInfo>
            <Form>
                {employerPerks.map((perk: EmployerPerksListModel) => (
                    <EmployerPerk
                        key={perk.id}
                        employerPerk={perk}
                        updateOrder={updateOrder}
                        updateEditedOrder={updateEditedOrder}
                        availableOrders={availableOrderOptions}
                        updateAfterDelete={updateAfterDelete}
                        currentUser={name}
                    />
                ))}
            </Form>
            <ButtonRow multipleButtons={employerPerks.length > 1}>
                {updateOrder && (
                    <Button onClick={resetReorder} filled="border">
                        Reset Order
                    </Button>
                )}
                {employerPerks.length > 1 && (
                    <Button onClick={reOrderFunctionality} filled={updateOrder ? 'filled' : 'border'}>
                        {updateOrder ? (
                            reorderIsLoading ? (
                                <span>
                                    Saving order <Spinner />
                                </span>
                            ) : (
                                'Save order'
                            )
                        ) : (
                            'Reorder Perks'
                        )}
                    </Button>
                )}
                <Button onClick={addPerk} disabled={updateOrder}>
                    Add Perk
                </Button>
            </ButtonRow>
            {apiError && <ErrorMessage errors={[apiError.message]} />}
            {showPerkLimitReachedMessage && <ErrorMessage errors={['Only 6 perks can be added, please remove one to add more.']} />}
            <FeatureButtonRow>
                <Button onClick={toggleFeature} theme={'success'}>
                    {featureIsEnabled ? 'Disable' : 'Enable'} Feature {featureToggleIsLoading && <Spinner />}
                </Button>
                {featureApiError && <ErrorMessage errors={[featureApiError.message]} />}
            </FeatureButtonRow>
        </Panel>
    );
};

interface EmployerPerkRowProp {
    employerPerk: EmployerPerksListModel;
    updateOrder: boolean;
    updateEditedOrder: (id: string, newOrder: string) => void;
    availableOrders: { id: string; name: string }[];
    updateAfterDelete: (perkId: string) => void;
    currentUser: string;
}

const EmployerPerk: FC<EmployerPerkRowProp> = ({ employerPerk, updateOrder, updateEditedOrder, availableOrders, currentUser, updateAfterDelete }) => {
    const { apiClient } = useContext(ApiClientContext);
    const [apiError, setApiError] = useState<{ message: string } | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    function deleteEmployerPerk() {
        useData.apiCall(apiClient.deleteEmployerPerk, () => updateAfterDelete(employerPerk.id), setApiError, setIsLoading)(employerPerk.id, `"${currentUser}"`);
    }

    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null;
        if (apiError) {
            timeout = setTimeout(() => {
                setApiError(null);
            }, 5000);
        }
        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [apiError]);

    return (
        <PerkColumn>
            <PerkRow>
                <img src={employerPerk.icon.src} alt={employerPerk.icon.alt} width={30} height={30} />
                <StyledLabel label={employerPerk.title} />
                {updateOrder && (
                    <SelectInput
                        name="order"
                        initialValue={employerPerk.order.toString()}
                        errors={[]}
                        options={availableOrders}
                        onValueChange={(newValue: string) => updateEditedOrder(employerPerk.id, newValue)}
                    />
                )}
                {!updateOrder && (
                    <PerkButtonRow>
                        <EditButton to={`/employers/${employerPerk.employerId}/employer-perk/${employerPerk.id}`} filled={'border'} theme={'success'}>
                            <StyledPerkIcon icon={'PencilAlt'} /> Edit
                        </EditButton>
                        <Button onClick={deleteEmployerPerk} filled={'border'} theme={'success'}>
                            {isLoading ? (
                                <span>
                                    <Spinner />{' '}
                                </span>
                            ) : (
                                <span>
                                    <StyledPerkIcon icon={'TrashAlt'} /> Del
                                </span>
                            )}
                        </Button>
                    </PerkButtonRow>
                )}
            </PerkRow>
            {apiError && <ErrorMessage errors={[apiError.message]} />}
        </PerkColumn>
    );
};

const StyledLabel = styled(Label)`
    justify-content: space-between;
`;

const EditButton = styled(Button)`
    margin-right: 10px;
`;

const StyledPerkIcon = styled(Icon)`
    margin-right: 8px;
`;

const SectionInfo = styled.div`
    margin-bottom: 1.5rem;
    font-size: 15px;
`;

const PerkRow = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
        margin-right: 10px;
    }
`;

const PerkColumn = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const ButtonRow = styled.div<{ multipleButtons: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => (props.multipleButtons ? 'space-between' : 'flex-end')};
    margin-top: 10px;
`;

const FeatureButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    padding-top: 20px;
    border-top: 0.0625rem solid #e1e1e1;
    margin-top: 20px;
`;

const PerkButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export default EmployerPerks;
