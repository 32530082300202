import React from 'react';
import SelfServiceContainer from './SelfServiceContainer';
import SelfServiceHeader from './SelfServiceHeader';
import { Button } from '../../atoms';
import styled from 'styled-components';

const SelfServiceSaved = props => {
    if (!(props.location.state && props.location.state.employer && props.location.state.authId)) {
        return <SelfServiceContainer>Sorry, you cannot access this page.</SelfServiceContainer>;
    }
    const { employer, authId, title, content } = props.location.state;
    return (
        <SelfServiceContainer contentWidth="36rem">
            <SelfServiceHeader employer={employer} text={title} />
            <div>
                {content.map((paragraph, index) => {
                    return <p key={index}>{paragraph}</p>;
                })}
            </div>
            <StyledButton theme="secondary" to={`/${authId}`}>
                Back to update
            </StyledButton>
        </SelfServiceContainer>
    );
};

const StyledButton = styled(Button)`
    margin-top: 80px;
    float: right;
`;

export default SelfServiceSaved;
