import { useState } from 'react';

/**
 * State hook for managing the visibility of a dialog
 */
const useDialogController = show => {
    const [isShown, setShowDialog] = useState(show);

    return [
        isShown,
        {
            showDialog() {
                setShowDialog(true);
            },
            hideDialog() {
                setShowDialog(false);
            }
        }
    ];
};

export default useDialogController;
