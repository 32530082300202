import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text } from 'informed';

import { Icon } from '../../atoms';
import { toTitleCase } from '../../helpers';
import { resetUl, resetButton } from '../../styles';
import { PASSWORD_REQUIREMENTS } from '../../constants';

class PasswordInput extends Component {
    state = {
        isVisible: false,
        requirements: {
            uppercase: false,
            lowercase: false,
            number: false,
            special: false,
            minimum: false
        }
    };

    validate = (value = '') => {
        const { label, name } = this.props;
        const fieldName = label || toTitleCase(name);
        return value.trim() ? undefined : [`The ${fieldName} field is required.`];
    };

    handleToggleVisibility = () => {
        const { isVisible } = this.state;
        this.setState({
            isVisible: !isVisible
        });
    };

    handlePasswordRequirements = value => {
        const hasUpperCase = /[A-Z]/;
        const hasLowerCase = /[a-z]/;
        const hasNumber = /[0-9]/;
        const hasSpecialChar = /[!@#$%^&*]/;

        this.setState({
            requirements: {
                uppercase: hasUpperCase.test(value),
                lowercase: hasLowerCase.test(value),
                number: hasNumber.test(value),
                special: hasSpecialChar.test(value),
                minimum: value.length >= 8
            }
        });
    };

    render() {
        const { isVisible, requirements } = this.state;
        const { name, initialValue, errors, required, ...rest } = this.props;
        const mainProps = {
            type: isVisible ? 'text' : 'password',
            field: name,
            initialValue,
            errors
        };
        const validationProps = required && {
            validate: this.validate,
            validateOnMount: true,
            validateOnChange: true
        };

        return (
            <Fragment>
                <Input {...mainProps} aria-invalid={!!errors && !!errors.length} onValueChange={this.handlePasswordRequirements} {...validationProps} {...rest} />
                <ShowOrHide onClick={this.handleToggleVisibility} aria-label={isVisible ? 'Hide Password' : 'Show Password'} errors={errors}>
                    <Icon icon={isVisible ? 'EyeSlash' : 'Eye'} />
                </ShowOrHide>
                <PasswordRequirements>
                    {PASSWORD_REQUIREMENTS.map((req, i) => {
                        const met = requirements[req.id];
                        return (
                            <Item key={i} met={met}>
                                <Icon icon={met ? 'Check' : 'Circle'} />
                                {req.name}
                            </Item>
                        );
                    })}
                </PasswordRequirements>
            </Fragment>
        );
    }
}

const Input = styled(Text)`
    font-family: 'Open Sans';
    padding: 0.6875rem 1rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    border: 1px solid #e1e1e1;
    border-right: none;
    flex-grow: 1;
    box-sizing: border-box;
    max-width: 100%;
    height: 2.5rem;

    ${props => props.errors && props.errors.length && 'border-color: #000000'};

    &:focus {
        border-color: #ffc845;
        outline: none;
    }
`;

const ShowOrHide = styled.button.attrs({
    type: 'button'
})`
    ${resetButton};
    height: 2.5rem;
    width: 2.5rem;
    background-color: #ffc845;

    ${props => props.errors && props.errors.length && 'border: 1px solid #000000'};

    &:focus {
        outline: none;
        border: 0.0625rem solid #000000;
    }
`;

const PasswordRequirements = styled.ul`
    ${resetUl};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0.25rem 0;
`;

const Item = styled.li`
    padding: 0.25rem 0;
    width: 100%;
    ${props => props.met && 'opacity: 0.5'};

    @media (min-width: 60rem) {
        width: calc((100% / 2) - 0.5rem);
    }

    @media (min-width: 30rem) and (max-width: 48rem) {
        width: calc((100% / 2) - 0.5rem);
    }

    svg {
        margin-right: 0.5rem;
        color: ${props => (props.met ? '#6ECEB2' : '#ffc845')};
    }
`;

PasswordInput.propTypes = {
    /** used to create the form state */
    name: PropTypes.string.isRequired,
    /** used to style the input as invalid */
    errors: PropTypes.arrayOf(PropTypes.string),
    /** is this a required input? */
    required: PropTypes.bool,
    label: PropTypes.string,
    initialValue: PropTypes.any
};

export default PasswordInput;
