import { pathToRegexp } from 'path-to-regexp';
import { REGION_PARAM, REGIONS } from './constants';

export const getQueryString = search => {
    const params = new URLSearchParams(search);
    let queryStr = '';
    for (let e of params) {
        queryStr += `&${e[0]}=${e[1]}`;
    }
    return queryStr;
};

export const getCookie = key => {
    const cookieWithKey = document.cookie.split('; ').filter(cookie => cookie.includes(key));
    if (!cookieWithKey.length || !cookieWithKey[0].split('=')[1].length) {
        return false;
    }
    return cookieWithKey[0].split('=')[1];
};

export const getRegion = () => {
    const contactSubjectRegexp = pathToRegexp(`/${REGION_PARAM}/(.*)?`);
    const match = contactSubjectRegexp.exec(window.location.pathname);
    if (match && match.length >= 2 && REGIONS.some(region => region.id === match[1])) {
        return match[1];
    }
    return REGIONS[0].id;
};

export const triggerClickOnEnter = e => {
    if (e.keyCode === 13) {
        e.target.click();
    }
};

export const triggerBlurOnEnter = e => {
    if (e.keyCode === 13) {
        e.target.blur();
    }
};

export const toTitleCase = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const validateUrl = value => {
    const protocolRegex = /^https?:\/\//;
    if (!value || !protocolRegex.test(value)) {
        return 'Please include http:// or https:// at the beginning of the URL';
    }

    const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    if (!value || !urlRegex.test(value)) {
        return 'Please enter a valid URL';
    }
};

/**
 * Parse api and form errors to be consumed by the Informed library
 * @param {array | string} apiErrors
 * @param {array | string} formErrors
 * @param {boolean} touched
 */
export const combineErrors = (apiErrors, formErrors, touched) => {
    if (apiErrors) {
        return Array.isArray(apiErrors) ? apiErrors : [apiErrors];
    }

    if (touched && formErrors) {
        return Array.isArray(formErrors) ? formErrors : [formErrors];
    }

    return undefined;
};

export const doesFormObjectHaveError = (formState, field, index) => {
    const errorFieldName = field.replace('.value', '');
    return formState.errors && formState.errors[errorFieldName] && formState.errors[errorFieldName].value && formState.errors[errorFieldName].value[index];
};

export const getFormObjectError = (formState, field, index) => {
    const errorFieldName = field.replace('.value', '');
    return formState.errors[errorFieldName].value[index];
};
