import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Icon } from '../../atoms';

const ErrorMessage = ({ errors }) => {
    return (
        <div role="region" aria-live="assertive" aria-relevant="additions removals">
            {!!errors && !!errors.length && (
                <Error>
                    <Icon icon="ExclamationCircle" style={{ marginRight: '0.25rem' }} />
                    {errors.join(' ')}
                </Error>
            )}
        </div>
    );
};

const Error = styled.span`
    font-size: 0.75rem;
    padding: 0.25rem 0 0.5rem;
    display: inline-flex;
    align-items: center;
`;

ErrorMessage.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string)
};

export default ErrorMessage;
