import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Tbody } from '../../molecules';
import { Button, Td, Th } from '../../atoms';

const UsersTable = ({ results, ...rest }) => {
    return (
        <Container {...rest}>
            <Table>
                <thead>
                    <tr>
                        <Th>Name</Th>
                        <Th>Email</Th>
                        <Th>Role</Th>
                        <Th colSpan="2">Employer</Th>
                    </tr>
                </thead>
                <Tbody>
                    {results.map(user => {
                        const userUrl = `/users/${user.id}`;
                        const employerUrl = user.employer && user.employer.id && `/employers/${user.employer.id}`;
                        return (
                            <tr key={user.id}>
                                <Td to={userUrl} primary="true">
                                    {user.name}
                                </Td>
                                <Td to={userUrl}>{user.emailAddress}</Td>
                                <Td to={userUrl}>{user.role}</Td>
                                <Td to={employerUrl}>
                                    {user.employer && user.employer.logo && (
                                        <Logo>
                                            <img src={user.employer.logo.split(' ').join('%20')} alt={`${user.employer.name} logo`} />
                                        </Logo>
                                    )}
                                    {user.employer && !user.employer.logo && <span>{user.employer.name}</span>}
                                </Td>
                                <BtnCell>
                                    <Button to={userUrl} size="mini" filled="border">
                                        Edit
                                    </Button>
                                </BtnCell>
                            </tr>
                        );
                    })}
                </Tbody>
            </Table>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const Logo = styled.div`
    width: 4rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 4rem;
        max-height: 3rem;
    }
`;

const BtnCell = styled(Td)`
    width: 5rem;

    > * {
        padding: 0 1rem;
    }
`;

UsersTable.propTypes = {
    results: PropTypes.arrayOf(PropTypes.any)
};

export default UsersTable;
