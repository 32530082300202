import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Icon } from '../../atoms';
import { resetButton } from '../../styles';
import { usePathForRegion } from '../../hooks';

const Button = ({
    to = undefined,
    theme = 'primary',
    size = 'primary',
    filled = 'primary',
    icon = undefined,
    isExternal = false,
    children,
    isSentenceCase = false,
    ...rest
}) => {
    const commonProps = {
        theme,
        size,
        filled,
        ...rest
    };

    const pathWithRegion = usePathForRegion(to || '/');
    if (to) {
        const target = isExternal ? to : pathWithRegion;
        if (isExternal) {
            return (
                <StyledExternalLink href={target} target="_blank" {...commonProps}>
                    {!!icon && <Icon icon={icon} style={{ marginRight: '0.5rem' }} />}
                    {children}
                </StyledExternalLink>
            );
        }

        return (
            <StyledLink to={target} {...commonProps}>
                {!!icon && <Icon icon={icon} style={{ marginRight: '0.5rem' }} />}
                {children}
            </StyledLink>
        );
    }

    return (
        <StyledButton isSentenceCase={isSentenceCase} {...commonProps}>
            {!!icon && <Icon icon={icon} style={!!children && { marginRight: '0.5rem' }} />}
            {!!children && children}
        </StyledButton>
    );
};

const themeColours = {
    primary: '#FFC845',
    secondary: '#dc2579',
    success: '#6ECEB2',
    link: 'transparent'
};

const foregroundColours = {
    default: '#000000',
    primary: '#000000',
    secondary: '#FFFFFF',
    success: '#000000',
    link: '#000000'
};

const themeColour = props => themeColours[props.theme];
const foregroundColour = props => (props.filled === 'primary' ? foregroundColours[props.theme] : foregroundColours['default']);
const hoverColour = props => (props.theme === 'link' ? '#dc2579' : 'none');

const defaultSizeStyles = css`
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    height: 2.5rem;
`;

const miniStyles = css`
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    height: 2rem;
`;

const defaultFilledStyles = css`
    background-color: ${themeColour};
    border: 0.125rem solid ${themeColour};

    &:hover,
    &:focus {
        opacity: 0.75;
        color: ${hoverColour};
    }
`;

const borderStyles = css`
    border: 0.125rem solid ${themeColour};

    &:hover,
    &:focus {
        background-color: ${themeColour};
        transition: 0.15s ease-in;
    }
`;

const invisibleStyles = css`
    &:hover,
    &:focus {
        opacity: 1;
        background-color: #ffc845;
    }
`;

const disabledStyles = css`
    opacity: 0.5;
    pointer-events: none;
`;

const baseStyles = css`
    ${props => (props.size === 'mini' ? miniStyles : defaultSizeStyles)};
    ${props => (props.filled === 'invisible' ? invisibleStyles : props.filled === 'border' ? borderStyles : defaultFilledStyles)};

    font-family: Montserrat, sans-serif;
    font-weight: 600;
    text-transform: ${props => (props.isSentenceCase ? 'none' : 'uppercase')};
    color: ${foregroundColour};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    transition: 0.15s ease-in;
    box-sizing: border-box;

    ${props => props.disabled && disabledStyles};

    &:hover,
    &:focus {
        transition: 0.15s ease-in;
        outline: none;
    }
`;

const StyledButton = styled.button`
    ${resetButton};
    ${baseStyles};
`;

const StyledLink = styled(Link)`
    ${baseStyles};
`;

const StyledExternalLink = styled.a`
    ${baseStyles}
`;

Button.defaultProps = {
    theme: 'primary',
    size: 'primary',
    filled: 'primary',
    isExternal: false,
    isSentenceCase: false
};

Button.propTypes = {
    /** if button is a Link, include the 'to' url */
    to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            pathname: PropTypes.string,
            search: PropTypes.string,
            state: PropTypes.object
        })
    ]),
    /** button colour theme */
    theme: PropTypes.oneOf(['primary', 'secondary', 'success', 'link']),
    /** button size */
    size: PropTypes.oneOf(['primary', 'mini']),
    /** button style */
    filled: PropTypes.oneOf(['primary', 'border', 'invisible']),
    /** include an icon */
    icon: PropTypes.string,
    /** Should the link open in a new tab? */
    isExternal: PropTypes.bool,
    /** button text */
    children: PropTypes.node,
    isSentenceCase: PropTypes.bool
};

export default Button;
