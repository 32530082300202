import React, { useContext } from 'react';
import styled from 'styled-components';
import { Redirect, useParams } from 'react-router';

import SelfServiceContainer from './SelfServiceContainer';
import SelfServiceHeader from './SelfServiceHeader';
import PolicyDocuments from './PolicyDocuments';
import { CompleteBenefitsProvider, useCompleteBenefits, useEmployerBenefits } from '../../hooks';
import { Button, Icon, Loading } from '../../atoms';
import { EmployerBenefits } from '../index';
import { ApiClientContext } from '../../services';

const SelfService = () => {
    let { id: authId } = useParams();

    return (
        <CompleteBenefitsProvider id={authId}>
            <SelfServiceContainer headerContent={<SaveStatus />}>
                <EmployerBenefitsWithCompletion authId={authId} />
            </SelfServiceContainer>
        </CompleteBenefitsProvider>
    );
};

const SAVE_STATUS = {
    saving: {
        message: 'Saving',
        icon: 'SyncAlt',
        color: '#428500'
    },
    saved: {
        message: 'All changes saved',
        icon: 'Check',
        color: '#428500'
    },
    error: {
        message: 'Sorry, error saving',
        icon: 'ExclamationTriangle',
        color: '#f90000'
    }
};

const SaveStatus = () => {
    const { apiError, isApiLoading, isApiSuccessful } = useEmployerBenefits();
    const key = isApiSuccessful ? 'saved' : isApiLoading ? 'saving' : apiError ? 'error' : null;
    if (!key) return null;

    let status = SAVE_STATUS[key];
    if (apiError && apiError.message) {
        status.message = apiError.message;
    }

    return (
        <SaveStatusContainer color={status.color}>
            {status.message} <Icon icon={status.icon} style={{ fontSize: '14px' }} />
        </SaveStatusContainer>
    );
};

const EmployerBenefitsWithCompletion = () => {
    const { id: authId, employer, benefits, areBenefitsLoading, benefitsError, saveBenefits } = useEmployerBenefits();
    const { complete, completeError, completeLoading, canSaveOrCompleteBenefits, completeBenefits } = useCompleteBenefits();
    const { publicApiClient: apiClient } = useContext(ApiClientContext);

    if (benefitsError || completeError) {
        return <SelfServiceHeader>Sorry, something has gone wrong.</SelfServiceHeader>;
    }
    if (areBenefitsLoading || completeLoading) {
        return <Loading />;
    }
    if (!employer || !benefits) {
        return null;
    }

    if (complete) {
        return (
            <Redirect
                to={{
                    pathname: '/lite/sent',
                    state: {
                        employer: employer,
                        authId: authId,
                        content: [
                            'The changes will be live soon. We will contact you when this has happened.',
                            'If we need to discuss any of your answers, we will be in touch.'
                        ],
                        title: 'Thanks for updating your table!'
                    }
                }}
            />
        );
    }

    return (
        <>
            <SelfServiceHeader employer={employer} text="Update your benefits & policies" />
            <Description>
                <p>
                    Research shows that job seekers start researching potential employers 3 months before they actively start looking for a new job. Keeping your Benefits
                    & Policies accurate and up to date will ensure you are attracting the best women to your teams.
                </p>
                <p>
                    Check and edit your Benefits & Policies below. Once you’ve entered all you can, hit <strong>Save & Send. </strong>
                    One of our team members will then check your responses and approve the changes or get in contact with you to discuss.
                </p>
                <Video src="https://www.youtube.com/embed/HTXyVtGjFwc" width="560" height="314" allowFullScreen />
            </Description>
            <EmployerBenefits saveBenefits={saveBenefits} data={benefits} isLoading={areBenefitsLoading} />

            <PolicyDocuments
                id={authId}
                getApi={apiClient.getEmployerFilesSelfService}
                uploadApi={apiClient.uploadEmployerFileSelfService}
                removeApi={apiClient.removeEmployerFileSelfService}
                downloadApi={apiClient.downloadEmployerFileSelfService}
            />

            <Buttons>
                <Button
                    theme="secondary"
                    to={{
                        pathname: '/saved',
                        state: {
                            employer: employer,
                            authId: authId,
                            content: ['You can return to finish updating your page by clicking the link in your email or clicking ‘Back to update’.'],
                            title: 'Table changes saved.'
                        }
                    }}
                    filled="border"
                    style={{ marginRight: '15px' }}
                    disabled={!canSaveOrCompleteBenefits}
                >
                    Save for later
                </Button>
                <Button theme="secondary" onClick={completeBenefits} disabled={!canSaveOrCompleteBenefits}>
                    Save & send
                </Button>
            </Buttons>
        </>
    );
};

const Description = styled.div`
    margin-bottom: 20px;
`;

const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const SaveStatusContainer = styled.div`
    font-size: 0.875rem;
    color: ${props => props.color};
`;

const Video = styled.iframe`
    display: block;
    margin: 0 auto;
`;

export default SelfService;
