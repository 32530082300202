import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Scope, Text, useFormState } from 'informed';
import { doesFormObjectHaveError, getFormObjectError } from '../../helpers';

const RatioFieldsGroup = ({ name: field, initialValue, onChange, disabled }) => {
    const formState = useFormState();
    const valueFieldName = field.replace('.value', '');

    const validate = (value, otherValue) => {
        if (isNaN(value) || otherValue === '' || isNaN(otherValue)) return 'Ratio field must be in right format, eg 60:40';
    };

    const ratioValidation = (ratio1, ratio2) => {
        if (ratio1 || ratio2) {
            return validate(ratio1, ratio2);
        }
    };

    const initialAntecedentValue = initialValue && initialValue.length && initialValue[0].antecedent;
    const initialConsequentValue = initialValue && initialValue.length && initialValue[0].consequent;

    const validateAntecedent = (value, values) =>
        ratioValidation(
            value,
            values[`${valueFieldName}`] &&
                values[`${valueFieldName}`].value &&
                values[`${valueFieldName}`].value.length &&
                values[`${valueFieldName}`].value[0].consequent
        );
    const validateConsequent = (value, values) =>
        ratioValidation(
            value,
            values[`${valueFieldName}`] &&
                values[`${valueFieldName}`].value &&
                values[`${valueFieldName}`].value.length &&
                values[`${valueFieldName}`].value[0].antecedent
        );

    return (
        <div>
            <StyledSpan>Enter a ratio, e.g. 60:40 by 2024</StyledSpan>
            <Label>
                <Scope scope={`${field}[0]`}>
                    <StyledInput
                        disabled={disabled}
                        validate={validateAntecedent}
                        validateOnChange
                        validateOnBlur
                        field="antecedent"
                        initialValue={initialAntecedentValue || ''}
                        onBlur={onChange}
                    />
                    <Words>:</Words>
                    <StyledInput
                        disabled={disabled}
                        validate={validateConsequent}
                        validateOnChange
                        validateOnBlur
                        field="consequent"
                        initialValue={initialConsequentValue || ''}
                        onBlur={onChange}
                    />
                </Scope>
                <Words>by</Words>
            </Label>
            {doesFormObjectHaveError(formState, field, 0) ? (
                <small style={{ color: 'red' }}>{getFormObjectError(formState, field, 0).antecedent || getFormObjectError(formState, field, 0).consequent}</small>
            ) : null}
        </div>
    );
};

const Label = styled.label`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
`;

const Words = styled.span`
    margin: 0 5px;
`;

const StyledInput = styled(Text)`
    font-family: 'Open Sans';
    width: 70px;
    padding: 0.6875rem 1rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    color: ${props => (props.disabled ? '#bcbcbc' : '#000000')};
    background-color: ${props => (props.disabled ? '#F1F1F1' : '#FFFFFF')};
    cursor: ${props => (props.disabled ? 'default' : 'auto')}
    height: ${props => `calc(1.375rem + ${1.125 * props.rows}rem)`};
    ${props => props.errors && props.errors.length && 'border-color: #000000'};

    &:focus {
        border-color: #ffc845;
        outline: none;
    }
`;

const StyledSpan = styled.span`
    margin-bottom: 10px;
    display: block;
`;

RatioFieldsGroup.propTypes = {
    name: PropTypes.string.isRequired,
    initialValue: PropTypes.array,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};

export default RatioFieldsGroup;
