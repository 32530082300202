import React, { Fragment } from 'react';
import styled from 'styled-components';

const StorybookPropsTable = ({ propDefinitions }) => {
    return (
        <Table>
            <thead>
                <tr>
                    <Th>Prop</Th>
                    <Th>Required</Th>
                    <Th>Type</Th>
                    <Th>Default</Th>
                    <Th>Description</Th>
                </tr>
            </thead>
            <tbody>
                {propDefinitions.map(({ property, propType, required, description, defaultValue }) => {
                    return (
                        <tr key={property}>
                            <Td>{property}</Td>
                            <Td>{required ? 'required' : 'optional'}</Td>
                            <Td>
                                {propType.name === 'enum' || propType.name === 'union' ? `One of ${propType.name === 'union' ? 'type' : ''}` : propType.name}
                                {propType.name === 'enum' && (
                                    <Fragment>
                                        <br />
                                        {propType.value.map(option => (
                                            <span key={option.value}> {option.value}</span>
                                        ))}
                                    </Fragment>
                                )}
                                {propType.name === 'union' && (
                                    <Fragment>
                                        <br />
                                        {propType.value.map(option => (
                                            <span key={option.name}> {option.name}</span>
                                        ))}
                                    </Fragment>
                                )}
                            </Td>
                            <Td>{defaultValue}</Td>
                            <Td>{description}</Td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-family: 'Montserrat', sans-serif;
    margin-top: 1rem;
`;

const Th = styled.th`
    background: #fafafa;
    color: #444444;
    text-align: left;
    padding: 0.5rem 0.75rem;
`;

const Td = styled.td`
    border-bottom: 1px solid #eeeeee;
    padding: 1rem 0.75rem;
`;

export default StorybookPropsTable;
