import React, { Fragment, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { Form } from 'informed';
import { throttle } from 'throttle-debounce';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';

import { ViewEditAdd } from '../../hocs';
import { InlinePageTitle, Panel } from '../../molecules';
import { Button, Date, Field, Label, Link, TextEditorInput, TextInput, ErrorMessage, InfoBanner } from '../../atoms';
import { APPLICATION_LINK_TYPES, WORK_TYPES, REMOTE_REGIONS_AU, REMOTE_REGIONS_UK, REMOTE_REGIONS_US, LEVELS_OF_EXPERIENCE } from '../../constants';
import { ApiClientContext } from '../../services';
import { useAuthorization } from '../../auth';
import config from '../../config';
import { useData } from '../../hooks';

const Job = ({ handleSubmit: handleSubmitProp, location, ...props }) => {
    const { isClient, client } = useAuthorization();
    const profile = useAuth0();
    const [employers, setEmployers] = useState([]);
    const [locations, setLocations] = useState([]);
    const { apiClient } = useContext(ApiClientContext);
    const { getDisciplines } = apiClient;
    const { data: disciplineList } = useData(getDisciplines, [], []);
    const formApiRef = useRef();

    const setFormApi = formApi => {
        formApiRef.current = formApi;
    };

    const handleEmployerSearch = throttle(1000, value => {
        apiClient.getAllEmployers(1, `&search=${value}`).then(({ data }) => {
            if (data && data.results) {
                setEmployers(data.results.map(e => ({ id: e.slug, name: e.name })));
            }
        });
    });

    const handleLocationSearch = throttle(1000, value => {
        if (value) {
            const queryStr = `locationDescription=${value}&region=${props.region}`;
            apiClient.getSuggestedJobLocations(queryStr).then(({ data }) => {
                setLocations(
                    data
                        .filter(d => d.selectValue !== 'Remote')
                        .map(d => {
                            const name = d.selectValue || d.description;
                            return { id: name, name };
                        })
                );
            });
        } else {
            setLocations([]);
        }
    });

    const handleSubmit = ({ applicationEmail, sendNotification, ...restValues }) => {
        // Only send email if the application type is email OR work180 and sendNotification is true
        const values = { ...restValues };
        if (values.applicationType === 'email' || (values.applicationType === 'WORK180' && sendNotification)) {
            values.applicationEmail = applicationEmail;
        } else {
            values.applicationEmail = null;
        }

        if (!values.expireAt) {
            values.expireAt = null;
        }

        values.updatedBy = profile.name;

        return handleSubmitProp(values);
    };

    const handleRepost = () => {
        apiClient.repostJob(data.id).then(() => {
            // Refresh page
            window.location = location.pathname;
        });
    };

    const { data, errors, handleDelete, isExisting, isSubmitting, region } = props;

    let remoteOptions = REMOTE_REGIONS_AU;
    if (region === 'uk') {
        remoteOptions = REMOTE_REGIONS_UK;
    } else if (region === 'us') {
        remoteOptions = REMOTE_REGIONS_US;
    }

    const defaultExpireAt = isExisting ? null : moment().add(30, 'days');

    const canEditJobSources = ['JobRestController', 'VolcanicFacadeController', 'ClientApi'];
    const isAutomatedJob = isExisting && !canEditJobSources.includes(data.source);

    return (
        <Fragment>
            <InlinePageTitle title="Job">
                {isExisting && (
                    <HeaderButtons>
                        {data.status === 0 ? (
                            <Button onClick={handleDelete} filled="border">
                                Expire
                            </Button>
                        ) : data.status === 1 && !isAutomatedJob ? (
                            <Button onClick={handleRepost} filled="border">
                                Repost
                            </Button>
                        ) : (
                            <Button disabled filled="border">
                                Expired
                            </Button>
                        )}
                        {data.applicationType === 'WORK180' && (
                            <Button to={`/applications/all?jobId=${data.id}`} filled="border">
                                View applications
                            </Button>
                        )}
                    </HeaderButtons>
                )}
            </InlinePageTitle>
            {isAutomatedJob && (
                <InfoBanner>
                    Hi there, this job was automatically uploaded and cannot be edited on our site. You can review and edit the original source of the job{' '}
                    {data.sourceUrl && (
                        <a className="jobSource" href={data.sourceUrl} target="_blank" rel="noopener noreferrer">
                            here
                        </a>
                    )}
                    {!data.sourceUrl && <span>on your careers site</span>}. Changes made to the original source will be reflected on our site within a few hours. If you
                    need any assistance, our team will be happy to help.
                </InfoBanner>
            )}
            {(!isExisting || (!!data && !!data.title)) && (
                <Form onSubmit={handleSubmit} getApi={setFormApi} disabled={isAutomatedJob} allowEmptyStrings>
                    {({ formState, formApi }) => {
                        return (
                            <Fragment>
                                <p>{isAutomatedJob ? <>&nbsp;</> : 'All fields required unless marked optional'}</p>
                                {isExisting && (
                                    <Field
                                        name="title"
                                        initialValue={data.title}
                                        required
                                        errors={errors.Title || (formState.touched.title && formState.errors.title && [formState.errors.title])}
                                        displayValueAs={<h2>{data.title}</h2>}
                                        fieldType="text"
                                        handleSubmit={() => handleSubmit(formState.values)}
                                        disabled={isAutomatedJob}
                                    />
                                )}
                                <Panel title="Position Details">
                                    {!isExisting && (
                                        <Field
                                            label="Title"
                                            name="title"
                                            initialValue={data.title}
                                            required
                                            errors={errors.Title || (formState.touched.title && formState.errors.title && [formState.errors.title])}
                                            fieldType="text"
                                            handleSubmit={() => handleSubmit(formState.values)}
                                            disabled={isAutomatedJob}
                                        />
                                    )}
                                    <Field
                                        label="Reference Number"
                                        name="reference"
                                        initialValue={data.reference}
                                        errors={errors.Reference || (formState.touched.reference && formState.errors.reference && [formState.errors.reference])}
                                        fieldType="text"
                                        handleSubmit={() => handleSubmit(formState.values)}
                                        disabled={isAutomatedJob}
                                    />
                                    <Field
                                        label="Work Type"
                                        name="workTypes"
                                        initialValue={!!data.workTypes && !!data.workTypes.length && data.workTypes.map(workType => workType.id)}
                                        required
                                        multiple
                                        errors={errors.WorkTypes || (formState.touched.workTypes && formState.errors.workTypes && [formState.errors.workTypes])}
                                        displayValueAs={
                                            <span>
                                                {!!data.workTypes && !!data.workTypes.length
                                                    ? data.workTypes.map(workType => workType.name).join(', ')
                                                    : 'Choose worktype'}
                                            </span>
                                        }
                                        fieldType="select"
                                        options={WORK_TYPES}
                                        handleSubmit={() => handleSubmit(formState.values)}
                                        disabled={isAutomatedJob}
                                    />

                                    <Field
                                        label="Discipline"
                                        name="disciplines"
                                        initialValue={!!data.disciplines && !!data.disciplines.length && data.disciplines.map(discipline => discipline.id)}
                                        required
                                        multiple
                                        errors={errors.Disciplines || (formState.touched.disciplines && formState.errors.disciplines && [formState.errors.disciplines])}
                                        displayValueAs={
                                            <span>
                                                {!!data.disciplines && !!data.disciplines.length
                                                    ? data.disciplines.map(discipline => discipline.name).join(', ')
                                                    : 'Choose discipline'}
                                            </span>
                                        }
                                        fieldType="select"
                                        options={disciplineList}
                                        handleSubmit={() => handleSubmit(formState.values)}
                                        disabled={isAutomatedJob}
                                    />
                                    <Field
                                        label="Location"
                                        name="locations"
                                        validate={(fieldValue, formValues) => {
                                            if (!formValues.isRemote && (!fieldValue || !fieldValue.length || !fieldValue[0])) {
                                                return 'Choose a Location or select the Remote field.';
                                            }
                                        }}
                                        validateOnChange
                                        validateOnBlur
                                        hideOptionalLabel
                                        initialValue={data.locations ? data.locations.map(item => item.sourceText || item.label) : []}
                                        errors={errors.Locations || (formState.touched.locations && formState.errors.locations && [formState.errors.locations])}
                                        fieldType="select"
                                        multiple
                                        multipleText=""
                                        handleSubmit={() => handleSubmit(formState.values)}
                                        options={locations}
                                        onSearch={handleLocationSearch}
                                        searchPlaceholder="Search for a location"
                                        disabled={isAutomatedJob}
                                        displayValueAs={
                                            <span>
                                                {!!data.locations && !!data.locations.length
                                                    ? data.locations.map(item => item.sourceText || item.label).join(', ')
                                                    : 'Choose location'}
                                            </span>
                                        }
                                        renderInlineField={() => (
                                            <Field
                                                label="Flexible"
                                                name="isFlexible"
                                                initialValue={data.isFlexible}
                                                errors={
                                                    errors.isFlexible || (formState.touched.isFlexible && formState.errors.isFlexible && [formState.errors.isFlexible])
                                                }
                                                fieldType="checkbox"
                                                handleSubmit={() => handleSubmit(formState.values)}
                                                isInline={true}
                                                hideOptionalLabel={true}
                                                disabled={isAutomatedJob}
                                            />
                                        )}
                                    />

                                    <Separator>
                                        <span>Or</span>
                                    </Separator>

                                    <Field
                                        label="Remote"
                                        name="isRemote"
                                        initialValue={data.isRemote}
                                        // Force the locations field to revalidate when isRemote changes
                                        onChange={() => {
                                            formApi.setValue('locations', formState.values.locations);
                                            formApi.setTouched('locations');
                                        }}
                                        errors={errors.isRemote || (formState.touched.isRemote && formState.errors.isRemote && [formState.errors.isRemote])}
                                        fieldType="checkbox"
                                        handleSubmit={() => handleSubmit(formState.values)}
                                        hideOptionalLabel={true}
                                        disabled={isAutomatedJob}
                                    />

                                    {formState.values.isRemote === true && (
                                        <Field
                                            label="Remote Region"
                                            name="isRegionLimitedRemote"
                                            initialValue={data.isRegionLimitedRemote}
                                            errors={
                                                errors.isRegionLimitedRemote ||
                                                (formState.touched.isRegionLimitedRemote &&
                                                    formState.errors.isRegionLimitedRemote && [formState.errors.isRegionLimitedRemote])
                                            }
                                            fieldType="radio"
                                            options={remoteOptions}
                                            hideOptionalLabel={true}
                                            handleSubmit={() => handleSubmit(formState.values)}
                                            disabled={isAutomatedJob}
                                        />
                                    )}

                                    <Field
                                        label="Level Of Experience"
                                        name="levelOfExperience"
                                        initialValue={data.levelOfExperience ? data.levelOfExperience.id : null}
                                        displayNameAsLabel
                                        errors={
                                            errors.levelOfExperience ||
                                            (formState.touched.levelOfExperience && formState.errors.levelOfExperience && [formState.errors.levelOfExperience])
                                        }
                                        fieldType="select"
                                        options={LEVELS_OF_EXPERIENCE}
                                        handleSubmit={() => handleSubmit(formState.values)}
                                        disabled={isAutomatedJob}
                                    />

                                    {(!isExisting || data.source === 'JobRestController') && (
                                        <Field
                                            label="Auto Expire"
                                            name="expireAt"
                                            initialValue={data.expireAt ? moment(data.expireAt) : defaultExpireAt}
                                            errors={errors.expireAt || (formState.touched.expireAt && formState.errors.expireAt && [formState.errors.expireAt])}
                                            fieldType="date"
                                            validateOnChange
                                            validateOnBlur
                                            dateProps={{
                                                isOutsideRange: day => moment(day).isSameOrBefore(moment()) || moment(day).isAfter(moment().add(6, 'months')),
                                                readOnly: true
                                            }}
                                            extraDetail={`This job will automatically expire after this date ${!isExisting ? '(defaults to 30 days from today)' : ''}`}
                                            handleSubmit={() => handleSubmit(formState.values)}
                                            disabled={isAutomatedJob}
                                        />
                                    )}
                                    {isExisting && data.expireAt && data.source !== 'JobRestController' && (
                                        <Label label="Expire At">
                                            <Date date={data.expireAt} fromNowDuring={0} />
                                        </Label>
                                    )}
                                </Panel>
                                {isClient && <TextInput name="employerSlug" initialValue={client} style={{ display: 'none' }} />}
                                {!isClient && (
                                    <Panel title="Employer Details">
                                        {!isExisting && (
                                            <Field
                                                label="Employer"
                                                name="employerSlug"
                                                required
                                                errors={
                                                    errors.EmployerSlug ||
                                                    (formState.touched.employerSlug && formState.errors.employerSlug && [formState.errors.employerSlug])
                                                }
                                                fieldType="select"
                                                handleSubmit={() => handleSubmit(formState.values)}
                                                options={employers}
                                                onSearch={handleEmployerSearch}
                                                searchPlaceholder="Search for an employer"
                                                disabled={isAutomatedJob}
                                            />
                                        )}
                                        {isExisting && !data.employer && (
                                            <ErrorMessage
                                                errors={[
                                                    `No employer found with slug "${data.employerSlug}". The employer slug may have been changed without updating the job`
                                                ]}
                                            />
                                        )}
                                        {isExisting && data.employer && (
                                            <Fragment>
                                                <Label label="Name" isInline={true}>
                                                    <Link to={`/employers/${data.employer.id}`}>{data.employer && data.employer.name}</Link>
                                                </Label>
                                                <Link to={`/employers/${data.employer.id}`} hoverTheme="outline">
                                                    <Logo>
                                                        <img src={data.employer && data.employer.logo} alt="Logo" />
                                                    </Logo>
                                                </Link>
                                                <Button
                                                    to={{
                                                        pathname: '/jobs/all',
                                                        search: `?employer=${data.employer.slug}`
                                                    }}
                                                    icon="ArrowRight"
                                                    filled="border"
                                                    style={{ marginBottom: '1rem' }}
                                                >
                                                    View All Jobs
                                                </Button>
                                            </Fragment>
                                        )}
                                    </Panel>
                                )}
                                <Panel title="Job Board Details">
                                    <Field
                                        label="Application"
                                        name="applicationType"
                                        initialValue={data.applicationType}
                                        includeBefore="Receive applications through:"
                                        required
                                        disabled={isAutomatedJob}
                                        errors={
                                            errors.ApplicationType ||
                                            (formState.touched.applicationType && formState.errors.applicationType && [formState.errors.applicationType])
                                        }
                                        displayValueAs={
                                            <span>{data.applicationType && APPLICATION_LINK_TYPES.filter(type => type.id === data.applicationType)[0].name}</span>
                                        }
                                        fieldType="radio"
                                        includeAfter={
                                            formState.values.applicationType === 'WORK180' && (
                                                <InlineField>
                                                    <Field
                                                        label="Send notification of new applications to:"
                                                        name="sendNotification"
                                                        // Set sendNotification to checked if not set
                                                        initialValue={!!(data.applicationType === 'WORK180' && data.applicationEmail)}
                                                        fieldType="checkbox"
                                                        isInline
                                                        hideOptionalLabel
                                                        handleSubmit={() => handleSubmit(formState.values)}
                                                        disabled={isAutomatedJob}
                                                    />
                                                </InlineField>
                                            )
                                        }
                                        options={APPLICATION_LINK_TYPES}
                                        handleSubmit={() => handleSubmit(formState.values)}
                                    />
                                    {formState.values.applicationType === 'external' && (
                                        <Field
                                            label="External Link"
                                            name="applicationLink"
                                            initialValue={data.applicationLink}
                                            required
                                            errors={
                                                errors.ApplicationLink ||
                                                (formState.touched.applicationLink && formState.errors.applicationLink && [formState.errors.applicationLink])
                                            }
                                            fieldType="link"
                                            handleSubmit={() => handleSubmit(formState.values)}
                                            disabled={isAutomatedJob}
                                        />
                                    )}
                                    {(formState.values.applicationType === 'email' ||
                                        (formState.values.applicationType === 'WORK180' && formState.values.sendNotification)) && (
                                        <Field
                                            label="Email"
                                            name="applicationEmail"
                                            extraDetail="Up to 5 emails can be added"
                                            required
                                            // Set user's emails as the default if not set
                                            initialValue={data.applicationEmail ? data.applicationEmail : []}
                                            errors={
                                                errors.ApplicationEmail ||
                                                (formState.touched.applicationEmail && formState.errors.applicationEmail && [formState.errors.applicationEmail])
                                            }
                                            displayValueAs={
                                                <span>
                                                    {!!data.applicationEmail && !!data.applicationEmail.length ? data.applicationEmail.join(', ') : 'Choose emails'}
                                                </span>
                                            }
                                            fieldType="tag"
                                            handleSubmit={() => handleSubmit(formState.values)}
                                            disabled={isAutomatedJob}
                                            invalid={!!formState.applicationEmail}
                                        />
                                    )}
                                    {isExisting && (
                                        <Field
                                            label="Url"
                                            name="slug"
                                            initialValue={data.slug}
                                            includeBefore={
                                                <Url>
                                                    {config.website}/for-women/job/{data.id}/
                                                </Url>
                                            }
                                            required
                                            errors={errors.Slug || (formState.touched.slug && formState.errors.slug && [formState.errors.slug])}
                                            fieldType="link"
                                            handleSubmit={() => handleSubmit(formState.values)}
                                            disabled={isAutomatedJob}
                                            overrideUrl={`${config.website}/for-women/job/${data.id}/`}
                                        />
                                    )}
                                    <Label label="Description" style={{ flexWrap: 'wrap', marginBottom: '1rem' }} required />
                                    <TextEditorInput
                                        name="htmlBody"
                                        label="Description"
                                        initialValue={data.htmlBody}
                                        handleSubmit={() => handleSubmit(formState.values)}
                                        isExisting={isExisting}
                                        required
                                        errors={errors.HtmlBody || (formState.touched.htmlBody && formState.errors.htmlBody && [formState.errors.htmlBody])}
                                        disabled={isAutomatedJob}
                                    />

                                    <Label label="Short Description" style={{ flexWrap: 'wrap', marginBottom: '1rem' }}>
                                        <Field
                                            name="shortDescription"
                                            initialValue={data.shortDescription}
                                            errors={
                                                errors.ShortDescription ||
                                                (formState.touched.shortDescription && formState.errors.shortDescription && [formState.errors.shortDescription])
                                            }
                                            fieldType="text"
                                            rows="3"
                                            handleSubmit={() => handleSubmit(formState.values)}
                                            disabled={isAutomatedJob}
                                        />
                                    </Label>

                                    {!isClient && (
                                        <Field
                                            label="Featured Ad"
                                            name="featured"
                                            initialValue={data.featured}
                                            errors={errors.featured || (formState.touched.featured && formState.errors.featured && [formState.errors.featured])}
                                            fieldType="checkbox"
                                            handleSubmit={() => handleSubmit(formState.values)}
                                            disabled={isAutomatedJob}
                                        />
                                    )}

                                    {isExisting && (
                                        <Fragment>
                                            <Label label="Created">
                                                <Date date={data.added} />
                                            </Label>
                                            <Label label="Updated">
                                                <Date date={data.updated} />
                                            </Label>
                                            <Label label="Updated By">{data.updatedBy}</Label>
                                        </Fragment>
                                    )}
                                </Panel>
                                {!isClient && isExisting && (
                                    <Panel title="Job Admin Info">
                                        <Fragment>
                                            <Label label="Source">{data.source}</Label>
                                        </Fragment>
                                    </Panel>
                                )}

                                {!!data.genderBias && (
                                    <Panel title="JobAd180">
                                        <Fragment>
                                            <Label label="Result">{data.genderBias.codingReadable}</Label>
                                            <Label>{data.genderBias.codingExplanation}</Label>
                                            <Label label="Feminine Words">{data.genderBias.feminineWordMatches.join(', ')}</Label>
                                            <Label label="Masculine Words">{data.genderBias.masculineWordMatches.join(', ')}</Label>
                                        </Fragment>
                                    </Panel>
                                )}
                                <Button type="submit" disabled={isAutomatedJob || formState.invalid || isSubmitting} theme="success">
                                    {isAutomatedJob ? 'Save not available' : !isExisting ? 'Create Job' : 'Save'}
                                </Button>
                            </Fragment>
                        );
                    }}
                </Form>
            )}
        </Fragment>
    );
};

const Logo = styled.div`
    max-width: 20rem;
    max-height: 12rem;
    margin-bottom: 1rem;
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    box-sizing: border-box;

    img {
        object-fit: contain;
        max-width: 17rem;
        max-height: 9rem;
    }
`;

const Separator = styled.div`
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;

    > span {
        position: relative;
        display: inline-block;
    }

    > span:before,
    > span:after {
        content: '';
        position: absolute;
        top: 50%;
        width: 9999px;
        height: 1px;
        background: #eee;
    }

    > span:before {
        right: 100%;
        margin-right: 15px;
    }

    > span:after {
        left: 100%;
        margin-left: 15px;
    }
`;

const Url = styled.span`
    opacity: 0.5;
    margin-right: -0.5rem;
`;

const HeaderButtons = styled.div`
    button {
        margin-right: 1rem;
    }

    button: last-child {
        margin-right: 0;
    }
`;

const InlineField = styled.span`
    display: inline-block;
    margin-left: -1rem;
`;

export default ViewEditAdd(Job)({ domain: 'Job' });
