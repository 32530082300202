import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Scope, Text, useFormState } from 'informed';
import { doesFormObjectHaveError, getFormObjectError } from '../../helpers';

const PercentageFieldsGroup = ({ name: field, initialValue, options, onChange, disabled }) => {
    const formState = useFormState();

    const validate = (value, scopedInitialValue) => {
        if (!value) return undefined;
        if ((value || scopedInitialValue) && (isNaN(value) || (!isNaN(value) && (value < 0 || value > 100)))) return 'Enter a number between 0 and 100';
    };

    return (
        <>
            {options.map((option, index) => {
                const scopedInitialValue = initialValue && initialValue.find(o => o.year === option.name);
                return (
                    <div key={index}>
                        <Label htmlFor={`${field}[${index}].value`}>
                            <Scope scope={`${field}[${index}]`}>
                                <Year>{option.name}</Year>
                                <HiddenInput initialValue={option.name} field="year" />
                                <StyledInput
                                    id={`${field}[${index}].value`}
                                    disabled={disabled}
                                    validate={value => validate(value, scopedInitialValue)}
                                    validateOnChange
                                    validateOnBlur
                                    field="value"
                                    initialValue={(scopedInitialValue && scopedInitialValue.value) || null}
                                    onBlur={onChange}
                                />
                                <PercentageSign>%</PercentageSign>
                            </Scope>
                        </Label>
                        <ErrorResponse>
                            {doesFormObjectHaveError(formState, field, index) && getFormObjectError(formState, field, index).value ? (
                                <small style={{ color: 'red' }}>{getFormObjectError(formState, field, index).value}</small>
                            ) : null}
                        </ErrorResponse>
                    </div>
                );
            })}
        </>
    );
};

const Year = styled.div`
    margin-right: 30px;
`;

const PercentageSign = styled.span`
    margin-left: 5px;
`;

const Label = styled.label`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const HiddenInput = styled(Text)`
    position: absolute;
    left: -1rem;
    opacity: 0;
`;

const StyledInput = styled(Text)`
    font-family: 'Open Sans';
    width: 70px;
    padding: 0.6875rem 1rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    color: ${props => (props.disabled ? '#bcbcbc' : '#000000')};
    background-color: ${props => (props.disabled ? '#F1F1F1' : '#FFFFFF')};
    cursor: ${props => (props.disabled ? 'default' : 'auto')}
    height: ${props => `calc(1.375rem + ${1.125 * props.rows}rem)`};
    ${props => props.errors && props.errors.length && 'border-color: #000000'};

    &:focus {
        border-color: #ffc845;
        outline: none;
    }
`;

const ErrorResponse = styled.div`
    margin-bottom: 10px;
    margin-left: 66px;
`;

PercentageFieldsGroup.propTypes = {
    name: PropTypes.string.isRequired,
    maxItems: PropTypes.number,
    disabled: PropTypes.bool,
    initialValue: PropTypes.any,
    options: PropTypes.arrayOf(PropTypes.any),
    onChange: PropTypes.func
};

PercentageFieldsGroup.defaultProps = {
    maxItems: 0
};

export default PercentageFieldsGroup;
